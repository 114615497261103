import React from 'react';

import { } from './RespCard.css'
import {
    Row,
    Col,
    Label
} from 'reactstrap';

import imageTemplate from '../../assets/silhouetteParent.png'
import { isLink } from '../../utils/isLink'
import Strings from '../../strings/Strings'

const RespCard = (props) => {

    let image = imageTemplate
    if (isLink(props.guardian.photo)) {
        image = props.guardian.photo;
    }

    let parentship = Strings.detailStudent.parent;
    if(props.guardian.parentship !== 0){
        parentship = Strings.detailStudent.others;
    }
    let cardStyle = "";
    if(props.isMakeCall){
        cardStyle = "featuredGuardian";
        console.log("isEmergency="+props.isEmergency)
        if(parseInt(props.isEmergency,10) === 1){
            cardStyle = "featuredEmergencyGuardian";
        }
    }
    
    return (
        <Col xs={12} sm={12} md={12} className={"boxGuardian " + cardStyle}>
            <Row className="rowResp " >
                <Col md={5} className="cardRespInfo">
                    <img
                        src={image}
                        width="90%"
                        height="auto"
                        className="pr-2"
                        alt=""
                    />
                </Col>
                <Col md={7} className="cardRespInfo">
                    <Row>
                        <Col md={12}><Label className="lblInfoResp lblGuardianName">{props.guardian.name}</Label></Col>
                        <Col md={12}><Label className="lblInfoResp lblGuardianDoc">{props.guardian.doc}</Label></Col>
                        <Col md={12}><Label className="lblInfoResp lblGuardianParentship">{parentship}</Label></Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}


export default RespCard;