import React from 'react';

// import { } from './ShowTempAuthView.css'
import {
    Row,
    Col,
    Label
} from 'reactstrap';
// import Strings from '../../strings/Strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import flagPt from '../../assets/flags/pt-br.png'
import flagEn from '../../assets/flags/en.png'
import flagEs from '../../assets/flags/es.png'

import { } from './ChangeLanguageView.css'

const ChangeLanguageItem= (props) => {


    let classNameDiv = ""
    let classNameText = ""
    let icon = null
    if(props.isChecked){
        classNameDiv = "rowChecked"
        classNameText = "textChecked"
        icon = faCheckCircle;
    } else {
        icon = faCircle;
    }

    let separator = null
    if (!props.isFirst) {
        separator = (
            <Row className={"rowInfo text-center " + classNameDiv} >
                <Col  md={12} className="colHrSpaceResp colHrSpaceRespChangePass">
                    <hr className="hrSpaceResp" />
                </Col>
            </Row>
        )
    }

    let language = "Português";
    let image = flagPt;
    if(props.lang === "en"){
        language = "English";
        image = flagEn;
    } else if (props.lang === "es") {
        language = "Español";
        image = flagEs;
    }
    return (
        <div className={"divLanguageItem "+ classNameDiv} onClick={props.onChangeLanguage.bind(this,props.lang)}>
            {separator}
            <Row className={classNameDiv}  style={{height: '50px'}} >
                <Col xs={6} md={6} className={"ChangeLanguageText " + classNameText}>
                    <Label className="divLanguageItem">{language}</Label>
                </Col>
                <Col xs={6} md={6} className="changeLanguageFlag text-right">
                    <img
                        src={image}
                        width="auto"
                        height="30px"
                        className="cardInfoImg"
                        alt=""
                    />
                    <span className={"  iconCheckChangeLanguage " + classNameText}>
                        <FontAwesomeIcon icon={icon } />
                    </span>
                </Col>
            </Row>

        </div>
    )
}

export default ChangeLanguageItem;
