
import firebase from '../../firebaseConf'


class FireStoreQueue {
    initListen = (schoolProjectName, callback) => {

        const db = firebase.firestore();
        const docRef = db.collection("QueueFSF").doc(schoolProjectName);
        this.snapshot = docRef.onSnapshot((doc) => {
            console.log("Current data: ", doc.data());            
            // const info = { id:doc.id ,school: doc.data().school, updateDate: doc.data().updateDate};
            callback();
            
        }, function(error) {
            console.log("Error = " + error);
        });

    }

    stopListen = () => {
        this.snapshot();
    }

}


export default FireStoreQueue;