  // Your web app's Firebase configuration
  // import firebase from 'firebase';
  import firebase from '@firebase/app';
  import '@firebase/firestore'

  var firebaseConfig = {
    apiKey: "AIzaSyDj6lT9MwAWO7sTB-ExG7k-T-T_iaFp6dE",
    authDomain: "samiar-filho-sem-fila.firebaseapp.com",
    databaseURL: "https://samiar-filho-sem-fila.firebaseio.com",
    projectId: "samiar---filho-sem-fila",
    storageBucket: "samiar---filho-sem-fila.appspot.com",
    messagingSenderId: "1072502015324",
    appId: "1:1072502015324:web:d5cffb2337866111"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export default firebase;