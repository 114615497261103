class BaseURL {

    
    getMainURL() {

        // let ENDPOINT_Local = 'http://fsfapi.com.br/';
        let ENDPOINT_Local = "http://localhost:8200/";

        //Endpoints FSF
        let ENDPOINT_Testing = 'https://fsfapi-t.filhosemfila.com.br/';
        let ENDPOINT_Production = 'https://fsfapi.filhosemfila.com.br/';

        //Endpoints School Guardian
        //let ENDPOINT_Testing = 'https://fsfapi-t.filhosemfila.com.br/';
        //let ENDPOINT_Production = 'https://fsfapi.filhosemfila.com.br/';

        let url = window.location.host;
        if(url.includes("localhost")){
            return ENDPOINT_Local;
        } else if (url.includes("192.168.")) {
            return ENDPOINT_Testing;
        } else if (url.includes("fsfinsp-t") || url.includes("fsfinsptv-t") || url.includes("gate-t") || url.includes("panel-t")){
            return ENDPOINT_Testing;
        } else if (url.includes("qpalane-t") || url.includes("qpalanetv-t")  || url.includes("gate-t") || url.includes("panel-t")) {
            return ENDPOINT_Testing;            
        } else if (url.includes("fsfinsp") || url.includes("fsfinsptv") || url.includes("gate") || url.includes("panel")){
            return ENDPOINT_Production;
        } else if (url.includes("qpalane") || url.includes("qpalanetv") || url.includes("gate") || url.includes("panel")) {
            return ENDPOINT_Production;            
        }
    }

    isTVInspector() {
        let url = window.location.host;
        if (url.includes("localhost")) {
            return true;
        } else if (url.includes("fsfinsptv") || url.includes("panel")) {
            return true;
        } else if (url.includes("qpalanetv") || url.includes("panel")) {
            return true;
        }
        return false;
    }

    getAppVersion() {
        return "3.3.02";
    }
}

export default BaseURL;