import React, { useState, useEffect } from "react";
import {} from "./EmergencyAlert.css";

import {
  faTimes,  
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Strings from "../../strings/Strings";
import Spinner from '../../componentsUI/Layout/Spinner/Spinner';

const EmergencyAlert = ({ emergencyButtonSchool, onCloseEmergencyButton }) => {


  const [isLoading, setIsLoading] = useState(false)

  const closeButton = () => {
    setIsLoading(true);
    onCloseEmergencyButton();
  }

  useEffect(() => {
    setIsLoading(false);
  }, [emergencyButtonSchool]);

  let buttonJSX = null
  if(isLoading){
    buttonJSX = <Spinner colorSpinner="#fff" size="sm" />;
  } else {
    buttonJSX = (
      <>
        <div className="EmergencyButtonAlert_BottomBoxText">
          {Strings.emergencyButton.closePopup}
        </div>
        <div
          className="EmergencyButtonAlert_BottomBoxIcon"
          onClick={closeButton}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </>
    );
  }

  let emergencyInfo = emergencyButtonSchool[0];
  console.log(emergencyInfo.url_icon_white);
  return (
    <div className="EmergencyButtonAlert_MainContainer">
      <div
        className="EmergencyButtonAlert_BoxAlert"
        style={{ backgroundColor: emergencyInfo.bg_color }}
      >
        <div className="EmergencyButtonAlert_TopBox">
          <div className="EmergencyButtonAlert_TopBoxText">
            {emergencyInfo.name}
          </div>
          <div className="EmergencyButtonAlert_TopBoxIcon">
            <img
              src={emergencyInfo.url_icon_white}
              width="auto"
              height="50"
              className="cardInfoImg"
              alt=""
            />
          </div>
        </div>
        <div className="EmergencyButtonAlert_MiddleBox">
          <div className="EmergencyButtonAlert_MiddleBoxText">
            {emergencyInfo.message_to_school}
          </div>
        </div>
        <div className="EmergencyButtonAlert_BottomBox">{buttonJSX}</div>
      </div>
    </div>
  );
};

export default EmergencyAlert;