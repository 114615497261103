import React from 'react';
import {connect} from 'react-redux'

import ForceChangePasswordView  from './ForceChangePasswordView.js';
import ForceChangePasswordModel from './ForceChangePasswordModel.js';

import * as actions from '../../../store/actions/index';
import Strings from "../../../strings/Strings";

class ForceChangePasswordController extends React.Component {
    
    inputField = {
        PreviousPassword:1,
        NewPassword:2,
        ConfirmPassword:3
    }
    state = { 
        isLoading: false,
        previousPasswordMsgError: '',
        newPasswordMsgError: '',
        confirmPasswordMsgError: '',
        connectionMsg: '',
        isConnectionError: false
     };


     constructor () {
        super();
        this.forceChangePasswordModel = new ForceChangePasswordModel();
     }
    /**
     * On Valid Submit in Form
     */
    onValidSubmit = (event, values) => {
        if(this.state.previousPasswordMsgError !== '' || 
        this.state.newPasswordMsgError !== '' || 
        this.state.confirmPasswordMsgError !== ''){
            return;
        }


        if (values.previousPassword === '') {
          this.setState({
            previousPasswordMsgError:
              Strings.changePass.fillPreviousPass,
          });
          return;
        } 
        
        if (values.password === "") {
            this.setState({
              newPasswordMsgError: Strings.changePass.fillNewPass,
            });
            return;
        }
        
        if (values.previousPassword === values.password) {
          this.setState({
            newPasswordMsgError: Strings.changePass.passEqualPreviuosPass,
          });
          return;
        }

        if (values.confirmPasswordError === "") {
            this.setState({
              confirmPasswordMsgError: Strings.changePass.fillConfirmPass,
            });
            return;
        }


        if(values.password != values.confirmPassword){
            this.setState({
              confirmPasswordMsgError:
                Strings.changePass.confirmPassMustEqual,
            });
        }else{
            this.setState({
              isLoading: true,
              connectionMsg: "",
            });
            this.forceChangePasswordModel.changePassword(
              this.props.schoolName,
              this.props.token,
              values.previousPassword,
              values.password,
              this.callbackChangePassword
            );
        }
    }

    callbackChangePassword = (code, message) => {

        if(code === 1){
            this.setState({
              connectionMsg: Strings.changePass.successChanged,
              isConnectionError: false,
            });
            setTimeout(() => {
                this.setState({
                  isLoading: false,
                  connectionMsg: ''
                });
                if (parseInt(this.props.showModalForceChangePassword) === 1) {
                this.props.closeForceShowModal();
                } else {
                this.props.onCloseChangePassword();
                }
            }, 3000);
            
        } else {
            this.setState({
              isLoading: false,
              connectionMsg: message,
              isConnectionError: true,
            });
        }
    }


    onCheckInfo = (info, values) => {
        let isNewPassword = true;
        if( info === this.inputField.PreviousPassword){
            isNewPassword = false;
        }
        let message = "";
        let returnPassword = this.forceChangePasswordModel.checkPasswordInfo(
          values,
          isNewPassword,
          this.props.restrict_only_strong_password
        );
        if(returnPassword === -1){
            message = Strings.changePass.passMustHaveStrongPass;
        } else if (returnPassword === -2){
            message = Strings.changePass.passMustHave6Chars;
        }

        if (info === this.inputField.PreviousPassword) {
            this.setState({
                previousPasswordMsgError: message,
            });
        } else if (info === this.inputField.NewPassword) {
            this.setState({
                newPasswordMsgError: message,
            });
        } else {
            this.setState({
              confirmPasswordMsgError: message,
            });
        }
    }

    render(){
        return (
          <ForceChangePasswordView
            isOpenForceChange={this.props.showModalForceChangePassword}
            openModalChangePassword={this.props.openModalChangePassword}
            onValidSubmit={this.onValidSubmit}
            onCancel={this.props.onCloseChangePassword}
            onCheckInfo={this.onCheckInfo}
            inputField={this.inputField}
            isLoading={this.state.isLoading}
            previousPasswordMsgError={this.state.previousPasswordMsgError}
            newPasswordMsgError={this.state.newPasswordMsgError}
            confirmPasswordMsgError={this.state.confirmPasswordMsgError}
            connectionMsg={this.state.connectionMsg}
            isConnectionError={this.state.isConnectionError}
          />
        ); 
    }
}

const mapStateToProps = state => {
    return {
      schoolName: state.auth.schoolName,
      token: state.auth.token,
      restrict_only_strong_password: state.auth.restrict_only_strong_password,
      showModalForceChangePassword: state.auth.showModalForceChangePassword,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeForceShowModal: () =>
      dispatch(actions.closeForceShowModal()),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ForceChangePasswordController);