import React from 'react';

import { } from './ShowTempAuthView.css'
import {
    Row,
    Col
} from 'reactstrap';

import TempAuthItem from '../../components/TempAuth/TempAuthItem/TempAuthItem'
import { faPlus, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

const ShowTempAuthView = (props) => {

    /*if(props.emptyTempAuths == true){
        props.onEmptyAuthTemps();
    }*/

    let date = new Date();
    let month = (date.getMonth()+1);
    if(month < 10){
        month = "0"+month;
    }
    let day = date.getDate();
    if(day < 10){
        day = "0"+day;
    }

	let current_date = date.getFullYear()+"-"+month+"-"+day+" 00:00:00";

    let dateCurrent = new Date(current_date);
    let dateLastUpdate = new Date(props.lastUpdate);

    if(props.authTemps == null && dateLastUpdate.getTime() <= dateCurrent.getTime()){
        props.onEmptyAuthTemps();
    }

    let authTemps = null;
    if(props.authTemps){
        authTemps = props.authTemps.map((authTemp) => {
            let isLoading = false;
            if (props.cardAuthRemoving.indexOf(authTemp.id) !== -1){
                isLoading = true
            }
            // console.log(authTemp.id);
            return <TempAuthItem key={authTemp.id} authTemp={authTemp} 
                onShowDetailTempAuth={props.onShowDetailTempAuth.bind(this, authTemp)}
                onRemoveTempAuth={props.onRemoveTempAuth.bind(this, authTemp)}
                isLoading={isLoading} faPlus={faPlus} faArrowLeft={faArrowLeft} />
        })
    }
    
    return (
        <div>
            <Row className="" >
                <Col md={12} className={'noPadding'}>
                    {authTemps}
                </Col>
            </Row>
        </div>
    )
}


export default ShowTempAuthView;