import React from 'react';

import { } from './VehicleInfoDetailView.css'
import {
    Row,
    Col,
    Label,
    Spinner,
    Alert
} from 'reactstrap';

import Strings from '../../../strings/Strings'
import VehicleInfoCard from '../VehicleInfoCard/VehicleInfoCard'
import renderIf from 'render-if';
import ConnectionConstants from '../../../constants/ConnectionConstants'

const VehicleInfoDetailView = (props) => {

    let vehicles = [];

    vehicles = props.vehicles.map(vehicle => {
        let isSelected = false;
        if (props.showDetailStudent.vehicle_id === vehicle.id){
            isSelected = true;
        }
        return <VehicleInfoCard key={vehicle.id} 
                            vehicle={vehicle}
                            isSelected={isSelected}
                connectionStatusUpdate={props.connectionStatusUpdate}
                         updateVehicle={props.updateVehicle} />;
    });

    let strMessage = "";
    let alertColor = "";
    let alertMessage = "";
    if (props.connectionStatus === ConnectionConstants.FAIL){
        strMessage = Strings.vehicles.messageError + props.errorCode;
    } else if (props.connectionStatus === ConnectionConstants.SUCCESS && 
        vehicles.length === 0){
        strMessage = Strings.vehicles.noVehicles;
    } else if(props.connectionStatusUpdate === ConnectionConstants.FAIL){
        alertColor = "danger";
        alertMessage = Strings.vehicles.messageErrorUpdate + props.codeErrorUpdate;
    } else if(props.connectionStatusUpdate === ConnectionConstants.SUCCESS){
        alertMessage = Strings.vehicles.messageSuccessUpdate;
        alertColor = "success";
    }

    let finalName = props.showDetailStudent.name;
    if (props.showDetailStudent.name.length > 20){
        finalName = props.showDetailStudent.name.substring(0,20)+"...";
    }

    return (
        <>
            <Row className="rowInfo text-center permanentAuthBar" >
                <Col md={9} xs={9} >
                    <Label className="titleResp">{finalName}</Label>
                </Col>
                <Col md={3} xs={2} className="topTitleRespIcon" onClick={() => { props.showAddCar()}}>
                    <Label className="titleRespIcon"> + </Label>
                </Col>
            </Row>
            {renderIf(props.connectionStatus === ConnectionConstants.LOADING)(
                <Row>
                    <Col md={12} lg={12} className="ColSpinner" >
                        <center>
                            <Spinner
                                className="Spinner"
                                size="sm"
                                color="#00ff00"
                            > </Spinner>
                        </center>
                    </Col>
                </Row>
            )}
            {renderIf(strMessage !== "")(
                <Row>
                    <Col md={12} lg={12} className="ColSpinner" >
                        
                        <Label className="">{strMessage}</Label>
                    </Col>
                </Row>
            )}
            {renderIf(props.connectionStatusUpdate === ConnectionConstants.FAIL || 
                        props.connectionStatusUpdate === ConnectionConstants.SUCCESS)(
                <Alert
                    color={alertColor}
                    isOpen={props.isAlertOpen}>
                    {alertMessage}
                </Alert>
            )}
            <Row className="rowInfo text-center" >
                {vehicles}
            </Row>          
        </>
    )
}


export default VehicleInfoDetailView;