import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
    students: null,
};

const showFoundStudents = (state, action) => {
    console.log(action);
    let updateInfo = {};
    updateInfo['students'] = action.students;
    return updateObject(state, updateInfo);
}

const showNotFoundStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['students'] = null;
    return updateObject(state, updateInfo);
}

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SHOW_FOUND_STUDENTS: return showFoundStudents(state, action);
        case actionTypes.SHOW_NOT_FOUND_STUDENTS: return showNotFoundStudents(state, action);
        default:
            return state;
    }
};

export default reducer;