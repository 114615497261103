import React from 'react'
import { } from './QueueItemTV.css'
import { connect } from 'react-redux';

import {
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap';

import _ from 'loadsh';
import { isLink } from '../../utils/isLink'
import Strings from '../../strings/Strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class QueueItemTV extends React.Component {
    // const QueueItem = (props) => {
    state = { hasVehicle: false, barrier_id: -1 };

    constructor(props) {
        super(props);
        this.refUpdateDate = '';
    }    

    shouldComponentUpdate(nextProps, nextState) {
        console.log(" CHECK shouldComponentUpdate QueueItemTV");
        if (nextProps.student !== this.props.student ||
            (nextProps.student.vehicle !== "" && !this.state.hasVehicle) ||
            (nextProps.isRemoving !== this.props.isRemoving) ||
            (nextProps.student.barrier_id !== this.state.barrier_id) ||
            (nextProps.student.updateDate !== this.refUpdateDate)) {
            console.log("UPDAting " + nextProps.student.id + " TRUE");
            this.refUpdateDate = nextProps.student.updateDate;
            return true;
        }        
        console.log("UPDAting " + nextProps.student.id + " FALSE");
        return false;
    }

    componentDidMount = () => {
        console.log(" CHECK componentDidMount QueueItemTV");
        if (this.props.student.guardianCallID > 0) {

            if (this.props.student.vehicle !== "") {
                if (!this.state.hasVehicle) {
                    this.setState({ hasVehicle: true })
                }
            }

            if (this.props.student.barrier_id !== "") {
                this.setState({ barrier_id: this.props.student.barrier_id })
            }

            this.refUpdateDate = this.props.student.updateDate;
        }        
    }

    render() {
        console.log("Update Render QueueItemTV");

        //let hidden = false;
        let classNameHidden = "showCard";
        if (typeof this.props.student.hidden !== "undefined" && this.props.student.hidden === true) {
            //hidden = true;
            classNameHidden = "hiddenCard";
        }

        let image = this.props.image_default;
        if (isLink(this.props.student.image)) {
            image = this.props.student.image;
        }

        let hasCarPlateInfo = false;
        let guardianCallName = "";

        if (this.props.student.guardianCallID > 0) {

            this.props.student.guardians.some(guardian => {
                if (guardian.id === this.props.student.guardianCallID) {
                    guardianCallName = guardian.name;
                    return true;
                }
                return false;
            });

            if (guardianCallName === "") {
                if(this.props.student.informParentCallCMS !== "" && this.props.student.informParentCallCMS !== null){
                    guardianCallName = this.props.student.informParentCallCMS;
                }else{
                    guardianCallName = Strings.queue.calledByCMS;
                }
            }

            if (this.props.student.vehicle !== "") {
                hasCarPlateInfo = true;
            }
        }

        // let gradeInfo = [];
        let bodyInfo = [];

        let classNameRowCard = "rowCard ";
        // let VehicleInfo = null
        let cardIcon = <FontAwesomeIcon icon={this.props.faCarSide} />;


        let car_color = "#000000";

        if (typeof this.props.student.vehicle_color != "undefined") {
            car_color = this.props.student.vehicle_color;
        }

        let borderInCar = "";

        if (car_color === "#ffffff" || car_color === "#fff" || car_color.toLowerCase() === "white") {
            borderInCar = "borderInCar";
        }

        if (hasCarPlateInfo) {
            /*gradeInfo = (
                <Col xs={12} md={12} className="cardInfo ">
                    <div mode="multi"
                        className="lblGrade heightLblTopHasInfo bold"
                        max={13}> {this.props.student.grade} </div>
                </Col>
            );*/
            bodyInfo.push(<div mode="multi" key={this.props.student.grade.queueID + "-1"}
                className="lblGrade heightLblTopHasInfo bold gradeInfo"
                max={13}> {this.props.student.grade} </div>);

            if (parseInt(this.props.showCallerName, 10) === 1) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "2"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faUser} className=" marginRightInternalIconUser widthIcon" />
                    <font className="guardianInfo"> {guardianCallName} </font></div>);
            } else {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "3"} max={14}
                    className="lblGrade heightResp"></div>);
            }

            classNameRowCard = "rowCardMoreInfo ";
            bodyInfo.push(<div mode="multi" key={this.props.student.id + "4"}
                className="lblGrade heightResp"><FontAwesomeIcon icon={this.props.faCar} style={{ "color": car_color }} 
                    className={"marginRightInternalIconUser widthIcon " + borderInCar} />
                    <font className="plateInfo">[{this.props.student.plate}]</font> {this.props.student.vehicle}</div>);

            let hasInfo = false;
            if (parseInt(this.props.student.callType, 10) === 2 && this.props.student.inspectorQrCode !== null) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "11"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faQrcode} className="marginRightInternalIcons widthIcon" />
                    {this.props.student.inspectorQrCode.name} </div>);
                hasInfo = true;
            } else {
                if (parseInt(this.props.student.gate_id, 10) > 0) {
                    bodyInfo.push(<div mode="multi" key={this.props.student.id + "5"} max={14}
                        className="lblGrade heightResp">
                        <FontAwesomeIcon icon={this.props.faSchool} className="marginRightInternalIcons widthIcon" /> 
                            {this.props.student.gate_name} </div>);
                    hasInfo = true;
                }
            }


            if (this.props.student.prism_info !== null && this.props.student.prism_info !== undefined &&
                 this.props.student.prism_info !== "") {
                bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "5"}
                        max={14} className="lblGrade prismInfo ">
                        <FontAwesomeIcon
                            icon={this.props.faInfoCircle}
                            className="marginRightInternalIcons widthIcon"
                        />{this.props.student.prism_info}
                    </div>
                );
                hasInfo = true;
            } 

            if (!hasInfo) {
                bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "5"}
                      max={14} className="lblGrade heightResp"></div>
                );                
            }               

        } else {

            bodyInfo.push(<div mode="multi" key={this.props.student.id + "7"}
                className={"lblGrade heightLblTopHasInfo bold gradeInfo"}
                max={13}> {this.props.student.grade} </div>);

            if (parseInt(this.props.showCallerName, 10) === 1) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "8"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faUser} className="marginRightInternalIconUser widthIcon" /><font class="guardianInfo"> {guardianCallName} </font></div>);
            } else {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "9"} max={14}
                    className="lblGrade heightResp"></div>);
            }

            bodyInfo.push(<div mode="multi" key={this.props.student.id + "10"}
                className="lblGrade heightResp"><FontAwesomeIcon icon={this.props.faCar} className="marginRightInternalIconUser widthIcon" /> {Strings.queue.notInformed}</div>);

            let hasInfo = false;
            if (parseInt(this.props.student.callType, 10) === 2 && this.props.student.inspectorQrCode !== null) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "11"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faQrcode} className="marginRightInternalIcons widthIcon" /> 
                        {this.props.student.inspectorQrCode.name} </div>);
                hasInfo = true;
            } else {
                if (parseInt(this.props.student.gate_id, 10) > 0) {
                    bodyInfo.push(<div mode="multi" key={this.props.student.id + "11"} max={14}
                        className="lblGrade heightResp">
                        <FontAwesomeIcon icon={this.props.faSchool} className="marginRightInternalIcons widthIcon" /> {this.props.student.gate_name} </div>);
                    hasInfo = true;
                }
            }

            if (this.props.student.prism_info !== null && this.props.student.prism_info !== undefined &&
                 this.props.student.prism_info !== "") {
                bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "5"}
                        max={14} className="lblGrade prismInfo">
                        <FontAwesomeIcon
                            icon={this.props.faInfoCircle}
                            className="marginRightInternalIcons widthIcon"
                        />{this.props.student.prism_info}
                    </div>
                );
                hasInfo = true;
            } 

            if (!hasInfo) {
                bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "5"}
                      max={14} className="lblGrade heightResp"></div>
                );                
            }                     
        }

        let checklistcontrol = 0;
        let checkpointInfo = null;
        if (!_.isNil(this.props.student) && !_.isNil(this.props.student.checkpoints) && this.props.student.checkpoints.length > 0){
            checkpointInfo = <div mode="multi" key={this.props.student.id + "6"} max={14}
            style={{"backgroundColor":this.props.student.checkpoints[this.props.student.checkpoints.length-1].checkpoint_area_color}} className='checkpointBox'>
                <font className={this.props.student.checkpoints[this.props.student.checkpoints.length-1].checkpoint_area_color != '#FFFFFF' ? 'checkpointInfoColor' : 'checkpointInfoWhite' } > {this.props.student.checkpoints[this.props.student.checkpoints.length-1].checkpoint_area_name} </font></div>;

            checklistcontrol = this.props.student.checkpoints[this.props.student.checkpoints.length-1].inspector_id;
        }else{
            // checkpointInfo = <div mode="multi" key={this.props.student.id + "6"} max={14}
            // className="lblGrade heightResp checkpointBox"></div>;
            checkpointInfo = <div mode="multi" key={this.props.student.id + "6"} max={14}
            className='checkpointBox'></div>;
        }

        if (parseInt(this.props.student.is_driving, 10) === 0) {
            cardIcon = <FontAwesomeIcon icon={this.props.faWalking} style={{ "fontSize": 30, }} />;
        }

        let boxStudentStyle = "boxStudent";
        // let spinnerColor = "#45b649";
        let bgColor = "#ff0000";
        let borderColor = "#ffffff";
        if (parseInt(this.props.student.emergency, 10) === 1) {
            boxStudentStyle = "boxStudentEmergency";
            // spinnerColor = "#ff0000";
            borderColor = "inherit";
            cardIcon = <FontAwesomeIcon icon={this.props.faExclamationTriangle} />;
        } else if (parseInt(this.props.student.isInsideSchool, 10) === 1) {
            boxStudentStyle = "boxStudentIsInsideSchool";
            // spinnerColor = "#325182";

            //Is Inside School By QrCode Reader
            if (parseInt(this.props.student.callType, 10) === 2) {
                bgColor = this.props.student.card_color_qr_code_call;
                borderColor = this.props.student.card_border_color_qr_code_call;

                let additionalDrivingIcon = null;
                if (parseInt(this.props.student.is_driving, 10) === 1) {
                    additionalDrivingIcon = <Col lg={12} style={{ "marginTop": 0 }}><FontAwesomeIcon icon={this.props.faCarSide} /></Col>;
                } else if (parseInt(this.props.student.is_driving, 10) === 0) {
                    additionalDrivingIcon = <Col lg={12} style={{ "marginTop": 0 }}><FontAwesomeIcon icon={this.props.faWalking} /></Col>;
                }
                cardIcon = <Row>
                    <Col lg={12} ><FontAwesomeIcon icon={this.props.faQrcode} /></Col>
                    {additionalDrivingIcon}
                </Row>;                
            } else {
                bgColor = this.props.student.barrier_card_color;
                borderColor = this.props.student.barrier_card_color;
                cardIcon = <Row>
                    <Col lg={12}><FontAwesomeIcon icon={this.props.faSignalStream} /></Col>
                    <Col lg={12} style={{ "marginTop": -10 }}><FontAwesomeIcon icon={this.props.faCarSide} /></Col>
                </Row>;
            }

            if (bgColor == "") {
                bgColor = "#122A4F";
            }
            if (borderColor == "") {
                borderColor = "#122A4F";
            }
        } else if (parseInt(this.props.student.callType, 10) === 1) {
            //Call By CMS
            boxStudentStyle = "boxStudentIsInsideSchool";
            // spinnerColor = "#325182";
            borderColor = this.props.student.card_border_color_central_web;
            bgColor = this.props.student.card_color_central_web;

            if (bgColor === "") {
                bgColor = "#122A4F";
            }

            if (borderColor === "#122A4F") {
                borderColor = "#122A4F";
            }
            if (parseInt(this.props.student.board_school_bus, 10) === 1) {
                bgColor = this.props.student.card_color_school_bus_call;
                borderColor = this.props.student.card_border_school_bus_call;
                cardIcon = (
                  <Row>
                    <Col lg={12}>
                      <FontAwesomeIcon icon={this.props.faBus} />
                    </Col>
                  </Row>
                );        
            }
        } else {
            boxStudentStyle = "boxStudentIsInsideSchool";
            // spinnerColor = "#325182";
            borderColor = "#ffffff";
                   
            if (parseInt(this.props.student.is_driving, 10) === 1) {
                bgColor = this.props.student.card_color_driving;

                if (bgColor == "") {
                    bgColor = "#122A4F";
                }
                //borderColor = this.props.card_border_color_driving;
            } else {
                bgColor = this.props.student.card_color_walking;

                if (bgColor == "") {
                    bgColor = "#122A4F";
                }
                //borderColor = this.props.card_border_color_walking;                
            }
        }

        if (parseInt(this.props.showOnlyNames, 10) === 1) {
            return (
                <Col xl={3} lg={4} md={6} sm={12} xs={12} className={'cardBody ' + classNameHidden}>
                    <Card className={'border-2  align-center cardQueueItemTV ' + boxStudentStyle} style={{ borderColor: borderColor }}>
                        <CardBody className={'cardBodyItemTV'}>
                            <Row>
                                <Col xl={2} lg={2} md={2} sm={2} xs={2} 
                                    className={"cardIndication cardQueueItemTVInternalMaxHeight noPadding vcenter"} 
                                    style={{ backgroundColor: bgColor }}>
                                    {cardIcon}
                                </Col>
                                <Col xl={10} lg={10} md={10} sm={10} xs={10} className={"noPadding"}>
                                    <Row className={classNameRowCard}>
                                        <Col xs={12} md={12} sm={12} className={"cardInfo"}>
                                            <div mode="multi"
                                                className="lblTopStudent studentInfo" max={14}>{this.props.student.name}</div>
                                            <Col xs={12} md={12} sm={12} className="cardInfo">
                                                {bodyInfo}
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                {checkpointInfo}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            );
        }else{
            return (
                <Col xl={3} lg={4} md={6} sm={12} xs={12} className={'cardBody ' + classNameHidden}>
                    <Card className={'border-2  align-center cardQueueItemTV ' + boxStudentStyle} style={{ borderColor: borderColor }}>
                        <CardBody className={'cardBodyItemTV'}>
                            <Row>
                                <Col xl={2} lg={2} md={2} sm={2} xs={2} 
                                    className={"cardIndication cardQueueItemTVInternalMaxHeight noPadding vcenter"} 
                                    style={{ backgroundColor: bgColor }}>
                                    {cardIcon}
                                </Col>
                                <Col xl={10} lg={10} md={10} sm={10} xs={10} className={"noPadding"}>
                                    <Row className={classNameRowCard}>
                                        <Col xs={4} md={4} sm={4} className="cardInfo cardQueueItemTVImgContainer">
                                            <img
                                                src={image}
                                                width="auto"
                                                height="auto"
                                                className="cardInfoImg cardQueueItemTVInternalMaxHeight"
                                                alt="" />
                                        </Col>
                                        <Col xs={8} md={8} sm={8} className={"cardInfo"}>
                                            <div mode="multi"
                                                className="lblTopStudent studentInfo" max={14}>{this.props.student.name}</div>
                                            <Col xs={12} md={12} sm={12} className="cardInfo">
                                                {bodyInfo}
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                        {checkpointInfo}
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        showCallerName: state.auth.showCallerName,
    };
};

export default connect(mapStateToProps)(QueueItemTV);