import React from 'react';

// import { } from './ShowTempAuthView.css'
import {
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    FormGroup,
} from 'reactstrap';
import Strings from '../../../strings/Strings'

import { AvForm} from 'availity-reactstrap-validation';

const AgreeTermsView = (props) => {
    let showNotCheckedError = "";
    if(props.showNotCheckedError != ""){
        showNotCheckedError = <div style={{color:"red", fontSize:"12px", marginBottom:"10px", marginTop:"-10px"}}>{Strings.agreeTerms.checkAgreeTermsError}</div>;
    }else if(props.error != ""){
        showNotCheckedError = <div style={{color:"red", fontSize:"12px", marginBottom:"10px", marginTop:"-10px"}}>{props.error}</div>;
    }

    let isOpen = parseInt(props.showAgreeTerms);

    let agreeTermsLang = Strings.language.agree_terms;
    let iframeURL = 'https://filesp.schoolguardian.app/privacy/privacy_'+agreeTermsLang+'.html';

    
    return (
      <Modal isOpen={isOpen === 1}>
        <ModalHeader>{Strings.agreeTerms.title}</ModalHeader>
        <ModalBody className="modalBodyChangeLanguage">
          <AvForm className="hidden" onValidSubmit={props.onValidSubmit}>
            <iframe
              src={iframeURL}
              style={{
                width: "100%",
                minHeight: "300px",
                marginTop: "2%",
                overflowY: "auto",
              }}
              title="document"
            ></iframe>
            <input
              type="checkbox"
              name="agreeTerms"
              id="agreeTerms"
              style={{
                marginTop: "15px",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            />{" "}
            <span style={{ marginTop: "12px" }}>
              {Strings.agreeTerms.agreeTerms}
            </span>
            <FormGroup style={{ textAlign: "center" }}>
              <Button color="info" className="buttonChangePassword">
                {Strings.agreeTerms.btnConfirmAgreeTerms}
              </Button>
            </FormGroup>
            {showNotCheckedError}
          </AvForm>
        </ModalBody>
      </Modal>
    );
}

export default AgreeTermsView;
