
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';
import DeviceID from "../../utils/DeviceID";
import Strings from '../../strings/Strings';
import ManageLanguage from '../../utils/ManageLanguage'
import Connection from '../../utils/Connection'

/**
 * This object manage the redux flow of informatio
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (schoolName, token, userId, waitingAreaID, showOnlyNames, 
                    autoRool, socketPort, userName, requireLocation, loading, isAuthenticated, showCallerName,isSocketAllowed,
                    card_color_walking, card_border_color_walking, card_color_driving,card_border_color_driving,
                    show_only_students_with_covid, allowInformCovid, isSearchBarOpen, 
                    forceChangePassword, showModalForceChangePassword, showAgreeTerms, typeInspector,
                    allow_checkpoint, vehicle_color, plate_mask, allow_add_car_inspector, 
                    allow_remove_students_instantly,restrict_only_strong_password) => {
    return {
      type: actionTypes.AUTH_SUCCESS,
      schoolName: schoolName,
      idToken: token,
      userId: userId,
      waitingAreaID: waitingAreaID,
      showOnlyNames: showOnlyNames,
      autoRool: autoRool,
      socketPort: socketPort,
      userName: userName,
      requireLocation: requireLocation,
      loading: loading,
      isAuthenticated: isAuthenticated,
      showCallerName: showCallerName,
      isSocketAllowed: isSocketAllowed,
      card_color_walking: card_color_walking,
      card_border_color_walking: card_border_color_walking,
      card_color_driving: card_color_driving,
      card_border_color_driving: card_border_color_driving,
      show_only_students_with_covid: show_only_students_with_covid,
      allowInformCovid: allowInformCovid,
      isSearchBarOpen: isSearchBarOpen,
      forceChangePassword: forceChangePassword,
      showModalForceChangePassword: showModalForceChangePassword,
      showAgreeTerms: showAgreeTerms,
      typeInspector: typeInspector,
      allow_checkpoint: allow_checkpoint,
      vehicle_color: vehicle_color,
      plate_mask: plate_mask,
      allow_add_car_inspector: allow_add_car_inspector,
      allow_remove_students_instantly: allow_remove_students_instantly,
      restrict_only_strong_password: restrict_only_strong_password,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const checkAuth = (error) => {
    return {
        type: actionTypes.CHECK_AUTH
    };
};

export const auth = (email, password, schoolName) => {
    return dispatch => {
        dispatch(authStart());

        let isInspectorTV = new BaseURL().isTVInspector();
        let deviceID = new DeviceID().getDeviceID();
        var form = new FormData();

        console.log("Device ID = " + deviceID);
        let appVersion = new BaseURL().getAppVersion();
        form.append("appVersion", appVersion);
        form.append("gcm", "-13");
        form.append("login", email);
        form.append("password", password);
        form.append("deviceType", isInspectorTV?"1":"0");
        form.append("deviceID", deviceID);

        makeLogin(schoolName, form, dispatch);
        
    };    
};
export const authToken = (token, schoolName) => {
    return dispatch => {
        dispatch(authStart());

        let isInspectorTV = new BaseURL().isTVInspector();
        let deviceID = new DeviceID().getDeviceID();
        var form = new FormData();

        console.log("Device ID = " + deviceID);
        let appVersion = new BaseURL().getAppVersion();
        form.append("appVersion", appVersion);
        form.append("gcm", "-13");
        form.append("token", token);
        form.append("deviceType", isInspectorTV?"1":"0");
        form.append("schoolGuardianEmergencyAlertapp", 0);
        form.append("deviceID", deviceID);

        makeLogin(schoolName, form, dispatch);
        
    };    
};

const makeLogin = (schoolName, form, dispatch) => {
  let redirectError = false;
  let connection = new Connection();
  connection.doConnection(
    new BaseURL().getMainURL() + schoolName + "/v1/inspector/login",
    {
      method: "POST",
      body: form,
      redirect: "manual",
    },
    (results) => {
      console.log(results);
      if (results.type === "opaqueredirect") {
        let message = Strings.messageErrors.incorrectSchool;
        redirectError = true;
        dispatch(authFail(message));
      } else {
        return results.json();
      }
    },
    (data) => {
      let message = "";
      if (data.infoReturn.returnID === "-1") {
        message = Strings.login.userNotFound;
      } else if (data.infoReturn.returnID === "2") {
        let logged_user_id = data.info.logged_user_id;
        let sent_via_sms = data.info.sent_via_sms === 1;
        dispatch(setShowMFAScreen(true, logged_user_id, sent_via_sms));
        return;
      } else if (data.infoReturn.returnID === "-2") {
        message = Strings.login.newVersionAvailable;
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      } else if (data.infoReturn.returnID === "-4") {
        message = Strings.login.user_without_mfa_info;
      } else if (data.infoReturn.returnID === "-5") {
        message = Strings.login.userBlockedByStatus;
      }else if (data.infoReturn.returnID !== "1") {
        message = Strings.formatString(
          Strings.messageErrors.unknownError,
          data.infoReturn.returnID
        );
      }

      if (message === "") {
        mountLoginReturn(schoolName, data, dispatch);
      } else {
        dispatch(authFail(message));
      }
    },
    (err, status) => {
      console.log("Error", err);
      console.log("Error", status);
      if (!redirectError) {
        dispatch(authFail(Strings.messageErrors.connectionError));
      }
    }
  );
};

export const authMFA = (user, code, logged_user_id, sent_via_sms, schoolName) => {
    return dispatch => {
        dispatch(authStart());

        let isInspectorTV = new BaseURL().isTVInspector();
        let deviceID = new DeviceID().getDeviceID();
        var form = new FormData();

        console.log("Device ID = " + deviceID);
        let appVersion = new BaseURL().getAppVersion();
        form.append("appVersion", appVersion);
        form.append("gcm", "-13");
        form.append("deviceType", isInspectorTV?"1":"0");
        form.append("deviceID", deviceID);
        form.append("loginByCode", 1);

        form.append("smsCode", code);
        form.append("logged_inspector_id", logged_user_id);
        form.append("sent_via_sms", sent_via_sms);        
        

        let connection = new Connection();
        connection.doConnection(new BaseURL().getMainURL() + schoolName + '/v1/inspector/login',
            {
                method: 'POST',
                body: form
            },
            results => { /* console.log(results);*/ return results.json(); },
            data => {

                let message = "";
                if (data.infoReturn.returnID === "-1") {
                    message = Strings.login.login_mfa_code_return_error;
                } else if (data.infoReturn.returnID === "-2") {
                    message = Strings.login.login_mfa_code_expired;      
                } else if (data.infoReturn.returnID !== "1") {
                    message = Strings.formatString(Strings.messageErrors.unknownError,
                        data.infoReturn.returnID);
                }

                if (message === "") {
                  mountLoginReturn(schoolName, data, dispatch);
                } else {
                  dispatch(authFail(message));
                }                
            },
            err => {
                console.log("Error", err);
                dispatch(authFail(Strings.messageErrors.connectionError));
            }
        );
    };
    
};

const mountLoginReturn = (schoolName, data, dispatch) => {
  localStorage.setItem("schoolName", schoolName);
  localStorage.setItem("userName", data.info.inspector.login);
  localStorage.setItem("token", data.info.inspector.token);
  localStorage.setItem("userId", data.info.inspector.id);
  localStorage.setItem("waitingAreaID", data.info.inspector.waitingAreaID);
  localStorage.setItem("showOnlyNames", data.info.inspector.showOnlyNames);
  localStorage.setItem("autoRool", data.info.inspector.autoRool);
  localStorage.setItem("socketPort", data.info.inspector.socketPort);
  localStorage.setItem("isSocketAllowed", data.info.inspector.isSocketAllowed);
  localStorage.setItem("showCallerName", data.info.inspector.showCallerName);
  localStorage.setItem(
    "card_color_walking",
    data.info.inspector.card_color_walking
  );
  localStorage.setItem(
    "card_border_color_walking",
    data.info.inspector.card_border_color_walking
  );
  localStorage.setItem(
    "card_color_driving",
    data.info.inspector.card_color_driving
  );
  localStorage.setItem(
    "card_border_color_driving",
    data.info.inspector.card_border_color_driving
  );
  localStorage.setItem(
    "show_only_students_with_covid",
    data.info.inspector.show_only_students_with_covid
  );
  localStorage.setItem(
    "allowInformCovid",
    data.info.inspector.allowInformCovid
  );
  localStorage.setItem("isSearchBarOpen", 0);
  localStorage.setItem(
    "forceChangePassword",
    data.info.inspector.force_change_password
  );
  localStorage.setItem("typeInspector", data.info.inspector.typeInspector);
  localStorage.setItem(
    "allow_checkpoint",
    data.info.inspector.allow_checkpoint
  );
  localStorage.setItem(
    "vehicle_color",
    JSON.stringify(data.info.inspector.vehicle_color)
  );
  localStorage.setItem("plate_mask", data.info.inspector.plate_mask);
  localStorage.setItem(
    "allow_add_car_inspector",
    data.info.inspector.allow_add_car_inspector
  );
  localStorage.setItem(
    "allow_remove_students_instantly",
    data.info.inspector.allow_remove_students_instantly
  );
  localStorage.setItem(
    "restrict_only_strong_password",
    data.info.inspector.restrict_only_strong_password
  );

  let isInspectorTV = new BaseURL().isTVInspector();
  let requireLocation = false;

  let getURL = new BaseURL().getMainURL();
  //If it is InspectorTV, dont check Location
  if (
    isInspectorTV ||
    getURL.includes("localhost") ||
    getURL.includes("fsfapi.com.br")
  ) {
    localStorage.setItem("requireLocation", 0);
    requireLocation = false;
  } else {
    localStorage.setItem(
      "requireLocation",
      data.info.inspector.requireLocation
    );
    requireLocation = parseInt(data.info.inspector.requireLocation, 10) === 1;
  }

  let isAuthenticated = true;
  let loading = false;
  if (requireLocation) {
    isAuthenticated = false;
    loading = true;
    requireLocation = true;
  }

  let showModalForceChangePassword = -1;

  localStorage.setItem("showAgreeTerms", 0);
  let showAgreeTerms = 0;

  if (parseInt(data.info.inspector.agree_terms, 10) === 0) {
    localStorage.setItem("showAgreeTerms", 1);
    showAgreeTerms = 1;
  }

  if (parseInt(showAgreeTerms, 10) === 1) {
    showModalForceChangePassword = 0;
    if (parseInt(data.info.inspector.force_change_password, 10) === 1) {
      localStorage.setItem("showModalForceChangePassword", 1);
    } else {
      localStorage.setItem("showModalForceChangePassword", 0);
    }
  } else {
    if (parseInt(data.info.inspector.force_change_password, 10) === 1) {
      localStorage.setItem("showModalForceChangePassword", 1);
      showModalForceChangePassword = 1;
    } else {
      showModalForceChangePassword = 0;
      localStorage.setItem("showModalForceChangePassword", 0);
    }
  }

  dispatch(
    authSuccess(
      schoolName,
      data.info.inspector.token,
      data.info.inspector.id,
      data.info.inspector.waitingAreaID,
      data.info.inspector.showOnlyNames,
      data.info.inspector.autoRool,
      data.info.inspector.socketPort,
      data.info.inspector.login,
      requireLocation,
      loading,
      isAuthenticated,
      data.info.inspector.showCallerName,
      data.info.inspector.isSocketAllowed,
      data.info.inspector.card_color_walking,
      data.info.inspector.card_border_color_walking,
      data.info.inspector.card_color_driving,
      data.info.inspector.card_border_color_driving,
      data.info.inspector.show_only_students_with_covid,
      data.info.inspector.allowInformCovid,
      0,
      data.info.inspector.force_change_password,
      showModalForceChangePassword,
      showAgreeTerms,
      data.info.inspector.typeInspector,
      data.info.inspector.allow_checkpoint,
      data.info.inspector.vehicle_color,
      data.info.inspector.plate_mask,
      data.info.inspector.allow_add_car_inspector,
      data.info.inspector.allow_remove_students_instantly,
      data.info.inspector.restrict_only_strong_password
    )
  );
};

export const resendMFACode = (user, logged_user_id, schoolName) => {
  return (dispatch) => {
    dispatch(authStart());

    let isInspectorTV = new BaseURL().isTVInspector();
    let deviceID = new DeviceID().getDeviceID();
    var form = new FormData();

    console.log("Device ID = " + deviceID);
    let appVersion = new BaseURL().getAppVersion();
    form.append("appVersion", appVersion);
    form.append("gcm", "-13");
    form.append("deviceType", isInspectorTV ? "1" : "0");
    form.append("deviceID", deviceID);

    form.append("user", user);
    form.append("logged_inspector_id", logged_user_id);

    let connection = new Connection();
    connection.doConnection(
      new BaseURL().getMainURL() + schoolName + "/v1/inspector/resendMFACode",
      {
        method: "POST",
        body: form,
      },
      (results) => {
        /* console.log(results);*/ return results.json();
      },
      (data) => {
        let message = "";
        if (data.infoReturn.returnID === "1") {
          message = Strings.login.login_mfa_resend_code_successful;
        } else if (data.infoReturn.returnID === "-3") {
          message = Strings.login.mfa_error_send_new_code;
          message = message.replace("{!!time!!}", data.info.time_remain_new_code);

        } else {
          message = Strings.formatString(
            Strings.messageErrors.unknownError,
            data.infoReturn.returnID
          );
        }
        dispatch(authFail(message));
      },
      (err) => {
        console.log("Error", err);
        dispatch(authFail(Strings.messageErrors.connectionError));
      }
    );
  };
};
export const logout = () => {    
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('waitingAreaID');
    localStorage.removeItem('showOnlyNames');
    localStorage.removeItem('autoRool');
    localStorage.removeItem('socketPort');
    localStorage.removeItem('requireLocation');
    localStorage.removeItem('showCallerName');
    localStorage.removeItem('isSearchBarOpen');
    localStorage.removeItem('students');
    localStorage.removeItem('show_only_students_with_covid');
    localStorage.removeItem('allowInformCovid');
    localStorage.removeItem('forceChangePassword');
    localStorage.removeItem('showModalForceChangePassword');
    localStorage.removeItem('showAgreeTerms');
    localStorage.removeItem('typeInspector');
    localStorage.removeItem('allow_checkpoint');
    localStorage.removeItem('vehicle_color');
    localStorage.removeItem('plate_mask');
    localStorage.removeItem('allow_add_car_inspector');
    
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            // dispatch(logout());
            const language = localStorage.getItem('language');
            if (language === null || language === ""){
                dispatch(getLanguage());
            } else {
                dispatch(checkAuth());
            }            
        } else {
            const schoolName = localStorage.getItem('schoolName');
            const userName = localStorage.getItem('userName');
            const userId = localStorage.getItem('userId');
            const waitingAreaID = localStorage.getItem('waitingAreaID');
            const showOnlyNames = localStorage.getItem('showOnlyNames');
            const autoRool = localStorage.getItem('autoRool');
            const socketPort = localStorage.getItem('socketPort');
            const requireLocationStorage = localStorage.getItem('requireLocation');
            const showCallerName = localStorage.getItem('showCallerName');
            const isSocketAllowed = localStorage.getItem('isSocketAllowed');
            const isSearchBarOpen = localStorage.getItem('isSearchBarOpen');
            const card_color_walking = localStorage.getItem('card_color_walking');
            const card_border_color_walking = localStorage.getItem('card_border_color_walking');
            const card_color_driving = localStorage.getItem('card_color_driving');
            const card_border_color_driving = localStorage.getItem('card_border_color_driving');
            const show_only_students_with_covid = localStorage.getItem('show_only_students_with_covid');
            const allowInformCovid = localStorage.getItem('allowInformCovid');
            const forceChangePassword = localStorage.getItem('forceChangePassword');
            const showModalForceChangePassword = localStorage.getItem('showModalForceChangePassword');
            const showAgreeTerms = localStorage.getItem('showAgreeTerms');
            const typeInspector = localStorage.getItem('typeInspector');
            const allow_checkpoint = localStorage.getItem('allow_checkpoint');
            const vehicle_color = JSON.parse(localStorage.getItem('vehicle_color'));
            const plate_mask = localStorage.getItem('plate_mask');
            const allow_add_car_inspector = localStorage.getItem('allow_add_car_inspector');
            const allow_remove_students_instantly = localStorage.getItem('allow_remove_students_instantly');
            const restrict_only_strong_password = localStorage.getItem('restrict_only_strong_password');

            let isAuthenticated = true;
            let loading = false;
            let requireLocation = false;
            if (parseInt(requireLocationStorage,10) === 1) {
                isAuthenticated = false;
                loading = true;
                requireLocation = true;
            }
            dispatch(authSuccess(schoolName, token, userId, waitingAreaID, showOnlyNames,
                                 autoRool, socketPort, userName, requireLocation, loading, isAuthenticated,
                                 showCallerName,isSocketAllowed,card_color_walking,card_border_color_walking,
                                 card_color_driving, card_border_color_driving, show_only_students_with_covid, 
                                 allowInformCovid ,isSearchBarOpen, forceChangePassword, showModalForceChangePassword, 
                                showAgreeTerms, typeInspector, allow_checkpoint, vehicle_color, plate_mask, 
                                allow_add_car_inspector, allow_remove_students_instantly, restrict_only_strong_password));
        }        
    };
};

export const setSchoolName = (schoolName) => {
    return {
        type: actionTypes.SET_SCHOOL_NAME,
        schoolName: schoolName
    };
};


export const checkNearStart = () => {
    return {
        type: actionTypes.CHECK_NEAR_SCHOOL_START,
    };
};

export const checkNearSuccess = () => {
    return {
        type: actionTypes.CHECK_NEAR_SCHOOL_SUCCESS,
    };
};

export const checkNearFail = (error) => {
    return {
        type: actionTypes.CHECK_NEAR_SCHOOL_FAIL,
        error: error
    };
};

export const checkNearSchool = (latitude, longitude, schoolName) => {
    return dispatch => {

        var form = new FormData();
        form.append("latitude", latitude);
        form.append("longitude", longitude);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/checkNearWaitingArea', {
            method: 'POST',
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {

                if (data.infoReturn.returnID === "0") {
                    dispatch(checkNearSuccess());
                } else if (data.infoReturn.returnID === "-1") {
                    let message = Strings.formatString(Strings.getLocation.notNear,
                        latitude,longitude);
                    dispatch(checkNearFail(message));
                } else {
                    let message = Strings.formatString(Strings.messageErrors.unknownError,
                        data.infoReturn.returnID);
                    dispatch(checkNearFail(message));
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(authFail(Strings.messageErrors.connectionError));
            });
    };

};

export const getLanguage = () => {
    return dispatch => {

        var form = new FormData();
        var url = window.location.pathname;
        url = url.replace("/","");
        let urlArray = url.split("/");
        let schoolName = urlArray[0];

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/user/getLanguage', {
            method: 'POST',
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {
                const manageLanguage = new ManageLanguage();
                manageLanguage.changeLanguage(data.infoReturn.language);
                dispatch(checkAuth());
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(checkAuth());
            });
    };

};

export const forgetPassword = (schoolName, email) => {
    return dispatch => {
        dispatch(authStart());

        console.log(schoolName);
        console.log(email);

        var form = new FormData();
        form.append("email", email);

        let connection = new Connection();
        connection.doConnection(new BaseURL().getMainURL() + schoolName + '/v1/inspector/forgetPassword',
            {
                method: 'POST',
                body: form
            },
            results => { /* console.log(results);*/ return results.json(); },
            data => {

                let message = "";
                if (data.infoReturn.returnID === "-98") {
                    message = Strings.changePass.invalidUser;
                } else if (data.infoReturn.returnID === "-1") {
                        message = Strings.changePass.userNotFound;                
                } 
                
                if (message === ""){
                    dispatch(forgetPasswordSuccess());
                } else {
                    dispatch(forgetPasswordError(message));
                }
            },
            err => {
                console.log("Error", err);
                dispatch(forgetPasswordError(Strings.messageErrors.connectionError));
            }
        );
    };
    
};

export const forgetPasswordSuccess = () => {
    return {
        type: actionTypes.FORGET_PASSWORD_SUCCESS
    };
};

export const forgetPasswordError = (error) => {
    return {
        type: actionTypes.FORGET_PASSWORD_ERROR,
        error: error
    };
};


export const agreeTerms = (schoolName, token) => {
    return dispatch => {
        dispatch(authStart());

        let connection = new Connection();
        connection.doConnection(new BaseURL().getMainURL() + schoolName + '/v1/inspector/agreeTerms',
            {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }),
            },
            results => { /* console.log(results);*/ return results.json(); },
            data => {

                let message = "";
                if (data.infoReturn.returnID === "-97") {
                    dispatch(agreeTermsSuccess(0));
                } else if (data.infoReturn.returnID !== "1") {
                    message = Strings.formatString(Strings.messageErrors.unknownError,
                        data.infoReturn.returnID);
                }
                
                if (message === ""){
                    localStorage.setItem("showAgreeTerms", 0);

                    let showModalForceChangePassword = 0;
                    if (parseInt(localStorage.getItem("showModalForceChangePassword"),10) === 1){
                        showModalForceChangePassword = 1;
                    }
                    dispatch(agreeTermsSuccess(showModalForceChangePassword));
                } else {
                    dispatch(agreeTermsError(message));
                }
            },
            err => {
                console.log("Error", err);
                dispatch(agreeTermsError(Strings.messageErrors.connectionError));
            }
        );
    };
    
};

export const agreeTermsSuccess = (showModalForceChangePassword) => {
    return {
        type: actionTypes.AGREE_TERMS_SUCCESS,
        showModalForceChangePassword: showModalForceChangePassword        
    };
};

export const agreeTermsError = (error) => {
    return {
        type: actionTypes.AGREE_TERMS_ERROR,
        error: error
    };
};


export const closeForceShowModal = () => {    
  return {
    type: actionTypes.FORCE_CHANGE_PASSWORD_DONE,
  };
};


export const setShowMFAScreen = (showMFAScreen, logged_user_id, sent_via_sms) => {
  return {
    type: actionTypes.SHOW_MFA_SCREEN,
    showMFAScreen: showMFAScreen,
    logged_user_id: logged_user_id,
    sent_via_sms: sent_via_sms,
  };
};

