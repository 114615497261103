
import { v4 as uuidv4 } from "uuid";

class DeviceID {

  getDeviceID() {
    console.log("Loading");
    let uuid =  localStorage.getItem("DeviceUUID");
    console.log("First UUID " + uuid);
    if(uuid === null || uuid === ""){
      uuid = uuidv4();
      console.log("Second UUID " + uuid);
      localStorage.setItem("DeviceUUID",uuid);
    }
    return uuid;
  }
}

export default DeviceID;
