import React from 'react';

import { } from './VehicleInfoCard.css'
import {
    Row,
    Col,
    Label,
    Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import ConnectionConstants from '../../../constants/ConnectionConstants';

const VehicleInfoCard = (props) => {

    let imageCheck = null;
    if (props.connectionStatusUpdate !== ConnectionConstants.LOADING){
        imageCheck = <FontAwesomeIcon onClick={() => { props.updateVehicle(props.vehicle.id)}} icon={faCircle} size="lg" />
        if (props.isSelected) {
            imageCheck = <FontAwesomeIcon icon={faCheckCircle} size="lg" />
        }
    } else {
        imageCheck = (
            <Spinner
                className="Spinner"
                size="sm"
                color="#00ff00"
            > </Spinner>
        );
    }

    let borderInCar = "";
    let car_color = props.vehicle.color.code;

    if (car_color === "#FFFFFF" || car_color === "#FFF" || car_color === "#ffffff" || car_color === "#fff" || 
        car_color.toLowerCase() === "white") {
        borderInCar = "borderInCar";
    }


    return (
        <Col xs={12} sm={12} md={12} className={"boxGuardian "}>
            <Row className="rowResp " >
                <Col xs={3} className="cardRespInfo">
                    <center>
                        <FontAwesomeIcon icon={faCar} size="3x" color={props.vehicle.color.code}
                            className={"marginRightInternalIconUser widthIcon " + borderInCar} />
                    </center>                    
                </Col>
                <Col xs={7} className="cardRespInfo">
                    <Row>
                        <Col xs={12}>
                            <Label className="lblInfoResp lblGuardianName">{props.vehicle.plate}</Label>
                        </Col>
                        <Col xs={12}>
                            <Label className="lblInfoResp lblGuardianDoc">{props.vehicle.vehicles.model}</Label>
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} className="cardRespInfo">
                    {imageCheck}
                </Col>
            </Row>
        </Col>
    )
}


export default VehicleInfoCard;