
import Strings from "../../../strings/Strings";
import Connection from "../../../utils/Connection";
import BaseURL from "../../../utils/BaseURL";

class ForceChangePasswordModel{

    changePassword(schoolName, token, previousPassword, newPassword, callback){
        var form = new FormData();
        form.append("previousPassword", previousPassword);
        form.append("newPassword", newPassword);

        let connection = new Connection();
        connection.doConnection(
        new BaseURL().getMainURL() + schoolName + "/v1/inspector/changePassword",
        {
            method: "POST",
            headers: new Headers({
            Accept: "application/json",
            Authorization: "Bearer " + token,
            }),
            body: form,
        },
        (results) => {
            /* console.log(results);*/ return results.json();
        },
        (data) => {
            let message = "";
            if (data.infoReturn.returnID === "-1") {
            message = Strings.login.errorPreviousPassword;
            } else if (data.infoReturn.returnID === "-2") {
            message = Strings.login.error6characters;
            } else if (data.infoReturn.returnID !== "1") {
            message = Strings.formatString(
                Strings.messageErrors.unknownError,
                data.infoReturn.returnID
            );
            }

            if (message === "") {
                localStorage.setItem("forceChangePassword", 0);
                localStorage.setItem("showModalForceChangePassword", 0);
                callback(1, message);
            } else {
                callback(parseInt(data.infoReturn.returnID), message);
            }
        },
        (err) => {
            console.log("Error", err);
            callback(-99, Strings.messageErrors.connectionError);
        }
        );
    }

    checkPasswordInfo = (password, isNewPassword, restrict_only_strong_password) => {
        if(isNewPassword && restrict_only_strong_password === 1){            
            let regex = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\\-__+.]){1,}).{8,}$/;
            if (!regex.test(password)) {
              return -1;
            }
        }

        if(password.length < 6){
            return -2;
        }
        
        return 1;
    }
}

export default ForceChangePasswordModel;