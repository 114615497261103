import React from 'react';

import { } from './CheckPointDetailView.css'
import {
    Row,
    Col,
    Label
} from 'reactstrap';

import CheckpointCard from '../CheckpointCard/CheckpointCard'
import Strings from '../../../strings/Strings'
import StudentDetailInfoMessage from '../../StudentInfo/StudentInfoDetailMessage/StudentInfoDetailMessage'

const CheckPointDetailView = (props) => {

    let checkpointCard = [];
    if (props.student){
        checkpointCard = props.student.checkpoints.map(checkpoint => {
            return <CheckpointCard key={checkpoint.id} checkpoint={checkpoint} />;
        });        
    }

    return (
        <div>
            <StudentDetailInfoMessage
                student={props.student}
                showSendMessageBox={props.showSendMessageBox}
                onSendNewMessage={props.onSendNewMessage}
                onSendMessageChangeValue={props.onSendMessageChangeValue}
                onShowSendMessageBox={props.onShowSendMessageBox}
                sendMessageText={props.sendMessageText}
                sendErrorMessage={props.sendErrorMessage}
                isSending={props.isSending}
                codeReturn={props.codeReturn}

             />
            <Row className="rowInfo text-center permanentAuthBar" >
                <Col md={12} >
                    <Label className="titleResp">{Strings.checkpoint.checkpoints}</Label>
                </Col>
            </Row>
            <Row className="rowInfo text-center" >
                {checkpointCard}   
            </Row>  
        
        </div>
    )
}


export default CheckPointDetailView;