import React from 'react';

import {} from './Spinner.css';

const spinner = (props) => {
    let color = "#1c375e";
    if(props.colorSpinner !== ""){
        color = props.colorSpinner;
    }

    let style = {
    color: color,
    };

    if (props.size === "sm"){
        style.width = "0.8em";
        style.height = "0.8em";
        style.fontSize = 4;
    }
    
    let className = "loader " + props.className;
    return (
        <div className={className} style={style}>Loading...</div>
    );
}
    

export default spinner;