
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';

/**
 * This object manage the redux flow of information
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */
export const updateSuccessCalledStudent = (student) => {
    return {
        type: actionTypes.UPDATE_SUCCESS_CALLED_STUDENT,
        student: student,
    };
};
export const updateErrorCalledStudent = () => {
    return {
        type: actionTypes.UPDATE_ERROR_CALLED_STUDENT,
    };
};
export const updateSuccessCalledStudents = () => {
    return {
        type: actionTypes.UPDATE_SUCCESS_CALLED_STUDENTS,
    };
};
export const updateErrorCalledStudents = () => {
    return {
        type: actionTypes.UPDATE_ERROR_CALLED_STUDENTS,
    };
};
export const cleanCalledStudents = () => {
    return {
        type: actionTypes.CLEAN_CALLED_STUDENTS,
    };
};

export const callStudent = (schoolName, token, student, guardian) => {

    console.log(schoolName);
    console.log(token);
    console.log(student);

    return dispatch => {

        var form = new FormData();
        form.append("studentsIDs", student.id);
        form.append("guardianID", guardian.id);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/callStudent', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {
                if (data.infoReturn.returnID == 0) {

                    //alert("Chamado com sucesso!");
                    dispatch(updateSuccessCalledStudent(student));
                } else {
                    alert("Erro ao efetuar o chamado");
                    dispatch(updateErrorCalledStudent());
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(updateErrorCalledStudent());
            });

    };
};

export const callAllStudents = (schoolName, token, guardian) => {

    console.log(schoolName);
    console.log(token);

    let studentsIds = "";
    let insertedStudents = [];
    let students = [];

    if(guardian.studentList){
        console.log(guardian.studentList);

        for (const [key, student] of Object.entries(guardian.studentList)) {
            console.log(student);
            
            if(!insertedStudents.includes(student.id)){
                insertedStudents.push(student.id);
                students.push(student);
                
                if(studentsIds != ""){
                    studentsIds += ",";
                }

                studentsIds += student.id
            }else{
                console.log('Student already inserted: '+student.id);
            }
        }
    }

    return dispatch => {

        var form = new FormData();
        form.append("studentsIDs", studentsIds);
        form.append("guardianID", guardian.id);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/callStudent', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {
                if (data.infoReturn.returnID == 0) {

                    //alert("Chamado com sucesso!");
                    dispatch(updateSuccessCalledStudents());
                } else {
                    //alert("Erro ao efetuar o chamado");
                    dispatch(updateErrorCalledStudents());
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(updateErrorCalledStudents());
            });

    };
};
