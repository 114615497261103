import React from 'react';

// import { } from './ShowTempAuthView.css'
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import Strings from '../../strings/Strings'

const ConfirmAction = (props) => {

    return (
        <Modal
            isOpen={props.showModal}>
            <ModalBody>
                {props.title}
                  </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={props.onConfirm} >
                    {Strings.modalInfo.btYes}
                    </Button>{' '}
                <Button color="info" onClick={props.onClose}>
                    {Strings.modalInfo.btNo}
                    </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmAction;
