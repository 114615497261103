import { Base64 } from 'js-base64';

class LoginModel {
    getTokenInfo = (token) => {
        console.log("Token",token);
        const info =  Base64.decode(token);
        const splitted = info.split("/");
        console.log("Splitted" + splitted.length);
        if(splitted.length === 2){
            return splitted;
        }
        return ['',''];
    }

}


export default LoginModel;