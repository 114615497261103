import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import ShowCallStudentsView from './ShowCallStudentsView.js'
//import CallStudentDetailItem from '../../components/CallStudentDetailItem/CallStudentDetailItem'
import ConfirmAction from '../../components/ConfirmAction/ConfirmAction'
import Aux from '../../hoc/ReactAux/ReactAux'
import Strings from '../../strings/Strings'

class ShowCallStudentsController extends React.Component {

    state = {
        showDetail: false,
        authTempDetail: null,
        showModal: false,
        removeAuth: null
    }

    onHandleClickMoreInfoButton = (event, guardian) => {
        event.preventDefault();
        event.stopPropagation();

        //Remove Student from Queue
        this.props.changeDetailGuardian(guardian);

        document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
        
        //Force Dispatch Resize to recalculate Size in Queue Item
        setTimeout(()=> {
            window.dispatchEvent(new Event('resize'));
        }, 500);
    }

    render() {
        let renderComp = (<ShowCallStudentsView guardians={this.props.guardians}
                onHandleClickMoreInfoButton={this.onHandleClickMoreInfoButton}
                showLoading={this.props.showLoading}
                classNameCallStudent={this.props.classNameCallStudent}
                isCallAllStudents={this.props.isCallAllStudents}
                notFoundGuardians={this.props.notFoundGuardians}
             /> )
        
        return (
            <Aux>
                { renderComp }       
            </Aux>
        )        
    }

};

const mapStateToProps = state => {
    return {
        schoolName: state.auth.schoolName,
        token: state.auth.token,
        guardians: state.searchCallStudent.guardians,
        cardAuthRemoving: state.queue.cardAuthRemoving, 
        showLoading: state.searchCallStudent.showLoading,
        classNameCallStudent: state.callStudent.classNameCallStudent,        
        isCallAllStudents: state.callStudent.isCallAllStudents,
        notFoundGuardians: state.searchCallStudent.notFoundGuardians,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeDetailGuardian: (guardian) => dispatch(actions.changeDetailGuardian(guardian)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ShowCallStudentsController);
