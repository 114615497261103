import React from 'react';
import { connect } from 'react-redux';

import componentQueries from 'react-component-queries';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// layouts
import { Header, Sidebar, Content } from './componentsUI/Layout';

import GAListener from './componentsUI/GAListener';

import './styles/reduction.css';
import * as actions from './store/actions/index';

import Aux from './hoc/ReactAux/ReactAux'
import BaseURL from './utils/BaseURL'
import Strings from './strings/Strings'
import ManageLanguage from './utils/ManageLanguage'

//containers
import LoginController from './containers/Login/LoginController';
import QueueController from './containers/Queue/QueueController';
import RedirectFSFPage from './containers/RedirectFSFPage'
import RedirectMainPage from "./containers/RedirectMainPage";

class App extends React.Component {
  
  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  static isSearchbarOpen() {
    return document
      .querySelector('.cr-searchbar')
      .classList.contains('cr-searchbar--open');
  }


  componentDidMount() {

    //Set Initial Language
    const manageLanguage = new ManageLanguage();
    manageLanguage.setInitialLanguage();

    if (new BaseURL().isTVInspector()) {
      document.title = Strings.titleInspectorTV;
    } else {
      document.title = Strings.titleInspector;
    }    

    this.props.onTryAutoSignup();
  }


  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (App.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    if (this.props.isAuthenticated) {
      switch (breakpoint) {
        case 'xs':
        case 'sm':
        case 'md':
          return this.openSidebar('close');

        case 'lg':
        case 'xl':
        default:
          return this.openSidebar('open');
      }
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }

    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }

  render() {

    let routes = null;
    let bgInfo = null;
    let styleBg = null;
    if (this.props.isAuthenticated) {
      bgInfo = "cr-app bg-light mainBg";
      routes = (
        <Aux>
          <Sidebar />
          <Content fluid onClick={this.handleContentClick}>
            <Header />
            <Switch>
              <Route
                path="/:schoolName"
                exact
                component={(props) => <QueueController {...props} />}
              />
              <Route
                path="/:schoolName/:token/"
                exact
                component={(props) => <RedirectMainPage {...props} />}
              />
              <Route
                path="/:schoolName/:signOnType/:token/"
                exact
                component={(props) => <RedirectMainPage {...props} />}
              />
              <Route
                path="/"
                exact
                component={(props) => <RedirectMainPage {...props} />}
              />
              <Redirect to="/" />
            </Switch>
          </Content>
        </Aux>
      );
    } else {
      bgInfo = "cr-app bg-light mainLoginBg";
      const random = parseInt(Math.floor(Math.random() * Math.floor(2)),10)+1;
      styleBg = {
        backgroundImage: 'url(/img/bg/bg' + random +'.jpg)'
      };
      routes = (
        <Content fluid >
          <Switch>
            <Route path="/:schoolName/:signOnType/:token/" exact component={props => <LoginController {...props} />} />
            <Route path="/:schoolName/:token/" exact component={props => <LoginController {...props} />} />
            <Route path="/:schoolName" component={props => <LoginController {...props} />}/>            
            <Route path="/" exact component={props => <RedirectFSFPage {...props} />}/>
            <Redirect to="/" />
          </Switch>
        </Content>
      );
    }

    return (
      <BrowserRouter>
        <GAListener>
          <main className={bgInfo} style={styleBg}>
            
            {routes}
          </main>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isSearchBarOpen: state.auth.isSearchBarOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};
export default componentQueries(query)(connect(mapStateToProps, mapDispatchToProps)(App));
