import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import ShowGuardianCallStudentsView from './ShowGuardianCallStudentsView.js'
//import CallStudentDetailItem from '../../components/CallStudentDetailItem/CallStudentDetailItem'
import ConfirmAction from '../../components/ConfirmAction/ConfirmAction'
import Aux from '../../hoc/ReactAux/ReactAux'
import Strings from '../../strings/Strings'

class ShowGuardianCallStudentsController extends React.Component {

    state = {
        showDetail: false,
        authTempDetail: null,
        showModal: false,
        removeAuth: null
    }

    onHandleCallStudentButton = (event, student) => {
        event.preventDefault();
        event.stopPropagation();

        //Remove Student from Queue
        this.props.callStudent(this.props.schoolName,
            this.props.token, student, this.props.guardian);

        document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
        
        //Force Dispatch Resize to recalculate Size in Queue Item
        setTimeout(()=> {
            window.dispatchEvent(new Event('resize'));
        }, 500);
    }

    onHandleCallAllStudentsButton = () => {
        //Remove Student from Queue
        this.props.callAllStudents(this.props.schoolName,
            this.props.token, this.props.guardian);

        document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
        
        //Force Dispatch Resize to recalculate Size in Queue Item
        setTimeout(()=> {
            window.dispatchEvent(new Event('resize'));
        }, 500);
    }

    render() {
        let renderComp = (<ShowGuardianCallStudentsView guardian={this.props.guardian}
                onHandleCallStudentButton={this.onHandleCallStudentButton}
                onHandleCallAllStudentsButton={this.onHandleCallAllStudentsButton}
                isLoadingCallAllStudents={this.props.isLoadingCallAllStudents}
                classNameCallStudent={this.props.classNameCallStudent}
                lastCalledStudentID={this.props.lastCalledStudentID}
                isCallAllStudents={this.props.isCallAllStudents}
                covidFormLevelToWarning={this.props.covidFormLevelToWarning}
                allowCallRestriction={this.props.allowCallRestriction}
                allowCovidForm={this.props.allowCovidForm}
                allowCovidFormWarning={this.props.allowCovidFormWarning}
                allowCallRestrictionIfNotReportInDay={this.props.allowCallRestrictionIfNotReportInDay}
                allowStudentsRotation={this.props.allowStudentsRotation}
             /> )

        return (
            <Aux>
                { renderComp }       
            </Aux>
        )        
    }

};

const mapStateToProps = state => {
    return {
        schoolName: state.auth.schoolName,
        token: state.auth.token,
        cardAuthRemoving: state.queue.cardAuthRemoving,
        guardian: state.screenDetailChange.showDetailGuardian,
        isLoadingCallAllStudents: state.callStudent.isLoadingCallAllStudents,
        classNameCallStudent: state.callStudent.classNameCallStudent,
        lastCalledStudentID: state.callStudent.lastCalledStudentID,
        isCallAllStudents: state.callStudent.isCallAllStudents,
        covidFormLevelToWarning: state.searchCallStudent.covidFormLevelToWarning,
        allowCallRestriction: state.searchCallStudent.allowCallRestriction,
        allowCovidForm: state.searchCallStudent.allowCovidForm,
        allowCovidFormWarning: state.searchCallStudent.allowCovidFormWarning,
        allowCallRestrictionIfNotReportInDay: state.searchCallStudent.allowCallRestrictionIfNotReportInDay,
        allowStudentsRotation: state.searchCallStudent.allowStudentsRotation,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        callStudent: (schoolName, token, student, guardian) => dispatch(actions.callStudent(schoolName, token, student, guardian)),
        callAllStudents: (schoolName, token, guardian) => dispatch(actions.callAllStudents(schoolName, token, guardian)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ShowGuardianCallStudentsController);
