
import * as actionTypes from './actionTypes';

/**
 * This object manage the redux flow of information
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */

export const changeDetailStudent = (student) => {
    return {
        type: actionTypes.CHANGE_DETAIL_STUDENT,
        student: student,
    };
};

export const changeDetailGuardian = (guardian) => {
    return {
        type: actionTypes.CHANGE_GUARDIAN_CALL_STUDENT,
        guardian: guardian,
    };
};

export const changeDetailCheckPoint = (student) => {
    return {
        type: actionTypes.CHANGE_DETAIL_CHECKPOINT,
        student: student,
    };
};

export const changeShowTempAuth = () => {
    return {
        type: actionTypes.CHANGE_SHOW_TEMP_AUTH,
    };
};

export const changeShowSearchStudent = () => {
    return {
        type: actionTypes.CHANGE_SHOW_SEARCH_STUDENT,
    };
};

export const changeDetailVehicles = (student) => {
    return {
        type: actionTypes.CHANGE_DETAIL_VEHICLES,
        student: student,
    };
};

export const changeVehicleDetailUser = (vehicleParentID) => {
    return {
        type: actionTypes.CHANGE_VEHICLE_DETAIL_USER,
        vehicleParentID: vehicleParentID,
    };
};

export const changeShowCallStudent = () => {
    return {
        type: actionTypes.CHANGE_SHOW_CALL_STUDENT,
    };
};
