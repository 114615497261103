import React from 'react'
import { connect } from 'react-redux';

import {} from './QueueItem.css'


import {
    Row,
    Col,
    Card,
    CardBody,
    Button
} from 'reactstrap';

import _ from 'loadsh';
import Spinner from '../../componentsUI/Layout/Spinner/Spinner'
import {isLink} from '../../utils/isLink'
import Strings from '../../strings/Strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconExtraClass from '../../assets/icons/icone_pontosSaidaExtras.png'

class QueueItem extends React.Component {
// const QueueItem = (props) => {

    constructor(props) {
        super(props);
        this.refUpdateDate = '';
    }
    
    state = { hasVehicle: false, barrier_id: -1, updateDate: '', loadingCheckpoint: false, lastCheckPointID: -1};


    shouldComponentUpdate(nextProps, nextState) {
        // console.log(" CHECK shouldComponentUpdate QueueItem");
        // console.log(" CHECK Different Student = " + nextProps.student.name + 
        //     " Check = " + nextProps.student.updateDate + " != " + this.refUpdateDate +
        //     " Check = " + nextProps.student.barrier_id + " != " + this.state.barrier_id+
        //     " Check = " + nextProps.student.vehicle + " != " + this.state.hasVehicle
        //     );
        if (nextProps.student !== this.props.student || 
            (nextProps.student.vehicle !== "" && !this.state.hasVehicle) ||
            (nextProps.isRemoving !== this.props.isRemoving) || 
            (nextProps.student.barrier_id !== this.state.barrier_id)  ||
            (nextProps.student.updateDate !== this.refUpdateDate) ||
            (nextProps.student.checkpoint !== this.props.checkpoint)) {
            // console.log("UPDAting " + nextProps.student.id + " TRUE");
            this.refUpdateDate = nextProps.student.updateDate;
            return true;
        }

        if (nextState.loadingCheckpoint != this.state.loadingCheckpoint){
            return true;
        }
        return false;
    }

    componentDidMount = () => {
        if (this.props.student.guardianCallID > 0){

            let changeState = false;
            let hasVehicle = false;
            let barrier_id = -1;
            if (this.props.student.vehicle !== "" && !this.state.hasVehicle ){                
                changeState = true;
                hasVehicle = true;
            }

            if (this.props.student.barrier_id !== ""){                
                changeState = true;
                barrier_id = this.props.student.barrier_id        
            }   

            this.refUpdateDate = this.props.student.updateDate;
            if(changeState){
                this.setState({ 
                    hasVehicle: hasVehicle,
                    barrier_id: barrier_id,
                });
            }
        }
    }

    componentDidUpdate = () => {

    }

    onHandleClickCheckPointButton = (student) => {
        this.props.onHandleClickCheckPointButton(student);
        let lastCheckPointID = -1;

        if (this.props.student.checkpoints != null && this.props.student.checkpoints.length > 0){
            lastCheckPointID = this.props.student.checkpoints[this.props.student.checkpoints.length - 1].id;
        }
        this.setState({
            loadingCheckpoint: true,
            lastCheckPointID: lastCheckPointID
        })
    }

    render() {
        //let hidden = false;
        let classNameHidden = "showCard";
        if(typeof this.props.student.hidden !== "undefined" && this.props.student.hidden === true){
            //hidden = true;
            classNameHidden = "hiddenCard";
        }

        let classNameCovid = "noCovid";
        if((typeof this.props.student.hasCovid !== "undefined" && this.props.student.hasCovid === 1)){
            //hidden = true;
            classNameCovid = "yesCovid";
        }

        // console.log("student: "+this.props.student.hiddenCovid);

        let classNameHiddenCovid = "showCovid";
        if(typeof this.props.student.hiddenCovid !== "undefined" && this.props.student.hiddenCovid === true && localStorage.getItem("isCovidFiltered") == 1){
            //hidden = true;
            classNameHiddenCovid = "hiddenCovid";
        }

        let image = this.props.image_default;
        if (isLink(this.props.student.image)){
            image = this.props.student.image;    
        }

        let isElevaInfo = false;
        if (window.location.href.includes("Rubens") || window.location.href.includes("eleva")) {                 
            isElevaInfo = true;
        }   

        let hasCarPlateInfo = false;
        let guardianCallName = "";
        let studentCalledByCMS = false;

        if (this.props.student.guardianCallID > 0){

            this.props.student.guardians.some(guardian => {
                if (guardian.id === this.props.student.guardianCallID){
                    guardianCallName = guardian.name;
                    return true;
                }
                return false;
            });

            if (guardianCallName === "" ){
                if(this.props.student.informParentCallCMS !== "" && this.props.student.informParentCallCMS !== null){
                    guardianCallName = Strings.queue.CMS+" - "+this.props.student.informParentCallCMS;
                }else{
                    guardianCallName = Strings.queue.calledByCMS;
                    studentCalledByCMS = true;
                }
            }

            if (this.props.student.vehicle !== ""){
                hasCarPlateInfo = true;                
            }
        }

        // let gradeInfo = [];
        let bodyInfo = [];

        let classNameRowCard = "rowCard ";
        // let VehicleInfo = null

        let cardIcon = <FontAwesomeIcon icon={this.props.faCarSide} />;
        
        let car_color = "#000000";
        
        if(typeof this.props.student.vehicle_color != "undefined"){
            car_color = this.props.student.vehicle_color;
        }

        let borderInCar = "";

        if(car_color === "#FFFFFF" || car_color === "#FFF" || car_color === "#ffffff" || car_color === "#fff" || car_color.toLowerCase() === "white"){
            borderInCar = "borderInCar";
        }
                            
        if (hasCarPlateInfo) {
            /*gradeInfo = (
                <Col xs={12} md={12} className="cardInfo ">
                    <div mode="multi"
                        className="lblGrade heightLblTopHasInfo bold"
                        max={13}> {this.props.student.grade} </div>
                </Col>
            );*/
            bodyInfo.push(<div mode="multi" key={this.props.student.id + "0"}
                className="lblGrade heightLblTopHasInfo bold gradeInfo"
                max={13}> {this.props.student.grade} </div>);

            if (parseInt(this.props.showCallerName,10) === 1){
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "2"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faUser} className=" marginRightInternalIconUser widthIcon" />
                    <font className="guardianInfo"> {guardianCallName} </font></div>); 
            }else{
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "2"} max={14}
                    className="lblGrade heightResp"></div>); 
            }

            classNameRowCard = "rowCardMoreInfo ";

            
            if (parseInt(this.props.allow_add_car_inspector, 10) === 1 && !studentCalledByCMS || isElevaInfo){
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "4"} 
                    onClick={this.props.onHandleClickDetailVehicleButton}
                    className="lblGrade heightResp pointer"><FontAwesomeIcon icon={this.props.faCar} style={{ "color": car_color }}
                        className={"marginRightInternalIconUser widthIcon " + borderInCar} />
                        <span className="linkText">
                    <font className="plateInfo">[{this.props.student.plate}]</font> {this.props.student.vehicle}</span> &nbsp;
                    <FontAwesomeIcon icon={this.props.faPen} /></div>);
            } else {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "4"}
                    className="lblGrade heightResp"><FontAwesomeIcon icon={this.props.faCar} style={{ "color": car_color }}
                        className={"marginRightInternalIconUser widthIcon " + borderInCar} />
                    <font className="plateInfo">[{this.props.student.plate}]</font> {this.props.student.vehicle}
                    </div>);
            }


            let hasInfo = false;
            if (parseInt(this.props.student.callType, 10) === 2 && this.props.student.inspectorQrCode !== null) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "5"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faQrcode} className="marginRightInternalIcons widthIcon" />
                         {this.props.student.inspectorQrCode.name} </div>);
                hasInfo = true;
            } else {
                if (parseInt(this.props.student.gate_id, 10) > 0) {
                    bodyInfo.push(<div mode="multi" key={this.props.student.id + "5"} max={14}
                        className="lblGrade heightResp">
                        <FontAwesomeIcon icon={this.props.faSchool} className="marginRightInternalIcons widthIcon" /> {this.props.student.gate_name} </div>);
                    hasInfo = true;
                }
            }
              
            if (this.props.student.prism_info !== null && this.props.student.prism_info !== undefined &&
                 this.props.student.prism_info !== "") {
                bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "6"}
                        max={14} className="lblGrade prismInfo">
                        <FontAwesomeIcon
                            icon={this.props.faInfoCircle}
                            className="marginRightInternalIcons widthIcon"
                        />{this.props.student.prism_info}
                    </div>
                );
                hasInfo = true;
            } 
            if (!hasInfo) {
                  bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "7"}
                      max={14} className="lblGrade heightResp"></div>
                  );                
            }           
        } else {

            bodyInfo.push(<div mode="multi" key={this.props.student.id + "1"}
                className={"lblGrade heightLblTopHasInfo bold gradeInfo"}
                max={13}> {this.props.student.grade} </div>); 

            if (parseInt(this.props.showCallerName,10) === 1) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "2"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faUser} className="marginRightInternalIconUser widthIcon"/><font className="guardianInfo"> {guardianCallName} </font></div>); 
            }else{
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "2"} max={14}
                    className="lblGrade heightResp"></div>); 
            }

            if (parseInt(this.props.allow_add_car_inspector, 10) === 1 && !studentCalledByCMS || isElevaInfo) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "4"}
                    onClick={this.props.onHandleClickDetailVehicleButton}
                    className="lblGrade heightResp pointer">
                    <FontAwesomeIcon icon={this.props.faCar} className="marginRightInternalIconUser widthIcon" />
                    <span className="linkText">{Strings.queue.notInformed}</span>&nbsp;
                    <FontAwesomeIcon icon={this.props.faPen} /></div>);
            } else {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "4"}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faCar} className="marginRightInternalIconUser widthIcon" />
                    {Strings.queue.notInformed}</div>);
            }


            let hasInfo = false;
            if (parseInt(this.props.student.callType, 10) === 2 && this.props.student.inspectorQrCode !== null) {
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "5"} max={14}
                    className="lblGrade heightResp">
                    <FontAwesomeIcon icon={this.props.faQrcode} className="marginRightInternalIcons widthIcon" />
                                        {this.props.student.inspectorQrCode.name} </div>);
                hasInfo = true;
            } else if (this.props.allow_checkpoint == 1 && this.props.student.checkpoints != null && this.props.student.checkpoints.length > 0) {            
                bodyInfo.push(<div mode="multi" key={this.props.student.id + "5"} max={14}
                        className="lblGrade heightResp">
                        <FontAwesomeIcon icon={this.props.faClipboardCheck} className="marginRightInternalIcons widthIcon" />
                        {this.props.student.checkpoints[this.props.student.checkpoints.length - 1].inspector_user_name} </div>);
                hasInfo = true;
            } else {
                if (parseInt(this.props.student.gate_id, 10) > 0) {
                    bodyInfo.push(<div mode="multi" key={this.props.student.id + "5"} max={14}
                        className="lblGrade heightResp">
                        <FontAwesomeIcon icon={this.props.faSchool} className="marginRightInternalIcons widthIcon" /> {this.props.student.gate_name} </div>);
                    hasInfo = true;
                }
            }

            if (this.props.student.prism_info !== null && this.props.student.prism_info !== undefined &&
                 this.props.student.prism_info !== "") {
                bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "6"}
                        max={14} className="lblGrade prismInfo">
                        <FontAwesomeIcon
                            icon={this.props.faInfoCircle}
                            className="marginRightInternalIcons widthIcon"
                        />{this.props.student.prism_info}
                    </div>
                );
                hasInfo = true;
            } 

            if (!hasInfo) {
                bodyInfo.push(
                    <div mode="multi" key={this.props.student.id + "7"}
                      max={14} className="lblGrade heightResp"></div>
                );                
            }            
        }

        let checklistcontrol = 0;
        let checkpointInfo = null;
        if (!_.isNil(this.props.student) && !_.isNil(this.props.student.checkpoints) && this.props.student.checkpoints.length > 0){
            checkpointInfo = <div mode="multi" key={this.props.student.id + "6"} max={14}
            style={{"backgroundColor":this.props.student.checkpoints[this.props.student.checkpoints.length-1].checkpoint_area_color}} className='checkpointBox'>
                <font className={this.props.student.checkpoints[this.props.student.checkpoints.length-1].checkpoint_area_color != '#FFFFFF' ? 'checkpointInfoColor' : 'checkpointInfoWhite' } > {this.props.student.checkpoints[this.props.student.checkpoints.length-1].checkpoint_area_name} </font></div>;

            checklistcontrol = this.props.student.checkpoints[this.props.student.checkpoints.length-1].inspector_id;
        }else{
            // checkpointInfo = <div mode="multi" key={this.props.student.id + "6"} max={14}
            // className="lblGrade heightResp checkpointBox"></div>;
            checkpointInfo = <div mode="multi" key={this.props.student.id + "6"} max={14}
            className='checkpointBox'></div>;
        }

        if (parseInt(this.props.student.is_driving,10) === 0) {
            cardIcon = <FontAwesomeIcon icon={this.props.faWalking} style={{"fontSize":30,}}/>;
        }        

        let boxStudentStyle = "boxStudent";
        let spinnerColor = "#45b649";
        let spinnerCheckpoint = "#0082C8"
        let bgColor = "#ff0000";
        let borderColor = "#ffffff";
        let covidIcon = "";
        if(classNameCovid == "yesCovid"){
            boxStudentStyle = "boxStudentCovid";
            spinnerColor = "#8a0808";
            borderColor = "inherit";
            bgColor="#8a0808";
            covidIcon = <FontAwesomeIcon icon={this.props.faVirus} style={{marginBottom:70, marginRight:-20}}/>;
        }else if (parseInt(this.props.student.emergency,10) === 1) {
            boxStudentStyle = "boxStudentEmergency";
            spinnerColor = "#ff0000";
            borderColor = "inherit";
            cardIcon = <FontAwesomeIcon icon={this.props.faExclamationTriangle}/>;
        } else if (parseInt(this.props.student.isInsideSchool,10)===1){
            boxStudentStyle = "boxStudentIsInsideSchool";
            spinnerColor = "#325182";

            //Is Inside School By QrCode Reader
            if (parseInt(this.props.student.callType, 10) === 2) {
                bgColor = this.props.student.card_color_qr_code_call;
                borderColor = this.props.student.card_border_color_qr_code_call;

                let additionalDrivingIcon = null;
                if (parseInt(this.props.student.is_driving, 10) === 1) {       
                    additionalDrivingIcon = <Col lg={12} style={{ "marginTop": 0 }}><FontAwesomeIcon icon={this.props.faCarSide} /></Col>;
                } else if (parseInt(this.props.student.is_driving, 10) === 0) {
                    additionalDrivingIcon = <Col lg={12} style={{ "marginTop": 0 }}><FontAwesomeIcon icon={this.props.faWalking} /></Col>;
                }
                cardIcon = <Row>
                    <Col lg={12} ><FontAwesomeIcon icon={this.props.faQrcode } /></Col>
                    {additionalDrivingIcon}
                </Row>;
                
            } else {
                bgColor = this.props.student.barrier_card_color;
                borderColor = this.props.student.barrier_card_color;
                cardIcon = <Row>
                    <Col lg={12}><FontAwesomeIcon icon={this.props.faSignalStream} /></Col>
                    <Col lg={12} style={{ "marginTop": -10 }}><FontAwesomeIcon icon={this.props.faCarSide} /></Col>
                </Row>;
            }

            if (bgColor == "") {
                bgColor = "#122A4F";
            }
            if(borderColor == ""){
                borderColor = "#122A4F";
            }

        } else if (parseInt(this.props.student.callType, 10) === 1){
            //Call By CMS
            boxStudentStyle = "boxStudentIsInsideSchool";
            // spinnerColor = "#325182";
            borderColor = this.props.student.card_border_color_central_web;
            bgColor = this.props.student.card_color_central_web;

            if (bgColor === "") {
                bgColor = "#122A4F";                
            }

            if (borderColor === "#122A4F"){
                borderColor = "#122A4F";
            }

            if (parseInt(this.props.student.board_school_bus, 10) === 1) {
                bgColor = this.props.student.card_color_school_bus_call;
                borderColor = this.props.student.card_border_school_bus_call;
                cardIcon = (
                  <Row>
                    <Col lg={12}>
                      <FontAwesomeIcon icon={this.props.faBus} />
                    </Col>
                  </Row>
                );        
            } else if (parseInt(this.props.student.called_by_extra_class,10) === 1) {
                bgColor = this.props.student.card_color_extra_class_call;
                borderColor = this.props.student.card_border_extra_class_call;
                cardIcon = (
                  <Row>
                    <Col lg={12}>
                      <img
                        src={IconExtraClass}
                        width="35"
                        height="auto"
                        className="cardInfoImg"
                        alt=""
                      />
                    </Col>
                  </Row>
                );                 
            }
        } else {
            boxStudentStyle = "boxStudentIsInsideSchool";
            spinnerColor = "#325182";
            borderColor = "#ffffff";
        
            if (parseInt(this.props.student.is_driving, 10) === 1) {
              bgColor = this.props.student.card_color_driving;

              if (bgColor == "") {
                bgColor = "#122A4F";
              }
              //borderColor = this.props.card_border_color_driving;
            } else {
              bgColor = this.props.student.card_color_walking;

              if (bgColor == "") {
                bgColor = "#122A4F";
              }
              //borderColor = this.props.card_border_color_walking;
            }
        }

        let buttonOk = null;
        /*if (parseInt(this.props.student.emergency, 10) === 1) {
            buttonOk = <Button className="btnOK" color="danger" outline
                        onClick={this.props.onHandleClickOKButton} >OK</Button>;
        } else {*/
            buttonOk = <Button className="btnOK" color="default"
        onClick={this.props.onHandleClickOKButton} ><FontAwesomeIcon icon={this.props.faCheck}/></Button>;
        //}

        let buttonCheckpoint = null;
            buttonCheckpoint = <Button className="btnCP" color="default"
                onClick={() => this.onHandleClickCheckPointButton(this.props.student)} ><FontAwesomeIcon icon={this.props.faBullseyePointer}/></Button>;

        let buttonChecklist = null;
            buttonChecklist = <Button className="btnCL" color="default"
                onClick={this.props.onHandleClickCheckListButton} ><FontAwesomeIcon icon={this.props.faClipboardCheck}/></Button>;

        //console.log("QueueItem", state);
        if(checklistcontrol == this.props.userID){//state.auth.userId){
             buttonCheckpoint = buttonChecklist;
        }

        if (this.props.isRemoving){
            buttonOk = <Spinner className="loaderButtonCard" color={spinnerColor} />;
        }

        if (this.state.loadingCheckpoint){
            buttonCheckpoint = <Spinner className="loaderButtonCard" color={spinnerCheckpoint} />;
            let lastCheckPointID = -1;

            if (this.props.student.checkpoints != null && this.props.student.checkpoints.length > 0) {
                lastCheckPointID = this.props.student.checkpoints[this.props.student.checkpoints.length - 1].id;
            }

            if (lastCheckPointID != this.state.lastCheckPointID) {
                setTimeout(() => {
                    this.setState({
                        loadingCheckpoint: false,
                        lastCheckPointID: -1
                    })
                }, 500);
            }
        }
                
        // console.log("UPDATE " + this.props.student.id);
        // console.log("cardIcon ", cardIcon);
        if(this.props.allow_checkpoint == 1){
            if(this.props.typeInspector == 0){
                // console.log("typeInspector " + this.props.typeInspector);
                return (
                    <Col xl={3} lg={4} md={6} sm={12} xs={12} className={'cardBody ' + classNameHidden + ' ' + classNameCovid + ' ' + classNameHiddenCovid}>
                        <Card className={'border-2  align-center cardQueueItem checkpointcardQueueItem ' + boxStudentStyle} style={{ borderColor: borderColor}}>
                            <CardBody className={'cardBodyItem'}>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"noPadding"}>
                                    
                                        <Row className={classNameRowCard + " minHeightCardInfo "} sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                            <Col xl={2} lg={2} md={2} sm={2} xs={2} className={"cardIndication cardQueueItemInternalMaxHeight noPadding vcenter"} style={{backgroundColor: bgColor}}>
                                                {covidIcon}
                                                {cardIcon}
                                            </Col>
                                            <Col xl={10} xs={10} md={10} sm={10} className={"infoBox displaycheckpoint noPadding"}>
                                                <Row sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <Col xl={4} xs={4} md={4} sm={4} className="cardInfo cardQueueItemImgContainer">
                                                        <img
                                                            src={image}
                                                            width="auto"
                                                            height="auto"
                                                            className="cardInfoImg"
                                                            alt="" />
                                                    </Col>
                                                    <Col xl={8} xs={8} md={8} sm={8} className={"cardInfo"}>
                                                        <div mode="multi" 
                                                            className="lblTopStudent studentInfo" max={14}>{this.props.student.name}</div>
                                                        <Col xs={12} md={12} sm={12} className="cardInfo">                            
                                                            {bodyInfo}
                                                        </Col>
                                                    </Col>
                                                </Row>
                                                <Row sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                                    {checkpointInfo}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="rowCardButton" sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                            <Col xs={5} md={5} sm={5} className="cardInfoButton cardInfoButtonCheckpoint">
                                                <Button className="btnPlus" 
                                                    color="default"
                                                    onClick={this.props.onHandleClickMoreInfoButton}><FontAwesomeIcon icon={this.props.faPlus}/></Button>
                                            </Col>
                                            <Col xs={7} md={7} sm={7} className="cardInfoButton cardInfoButtonCheckpoint">
                                                {buttonCheckpoint}
                                                {/* {buttonOk} */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                );
            }else{
                // console.log("typeInspector " + this.props.typeInspector);
                return (
                    <Col xl={3} lg={4} md={6} sm={12} xs={12} className={'cardBody ' + classNameHidden + ' ' + classNameCovid + ' ' + classNameHiddenCovid}>
                        <Card className={'border-2  align-center cardQueueItem checkpointcardQueueItem ' + boxStudentStyle} style={{ borderColor: borderColor}}>
                            <CardBody className={'cardBodyItem'}>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"noPadding"}>
                                    
                                        <Row className={classNameRowCard + " minHeightCardInfo "} sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                            <Col xl={2} lg={2} md={2} sm={2} xs={2} className={"cardIndication cardQueueItemInternalMaxHeight noPadding vcenter"} style={{backgroundColor: bgColor}}>
                                                {covidIcon}
                                                {cardIcon}
                                            </Col>
                                            <Col xl={10} xs={10} md={10} sm={10} className={"infoBox displaycheckpoint noPadding"}>
                                                <Row sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <Col xl={4} xs={4} md={4} sm={4} className="cardInfo cardQueueItemImgContainer">
                                                        <img
                                                            src={image}
                                                            width="auto"
                                                            height="auto"
                                                            className="cardInfoImg"
                                                            alt="" />
                                                    </Col>
                                                    <Col xl={8} xs={8} md={8} sm={8} className={"cardInfo"}>
                                                        <div mode="multi" 
                                                            className="lblTopStudent studentInfo" max={14}>{this.props.student.name}</div>
                                                        <Col xs={12} md={12} sm={12} className="cardInfo">                            
                                                            {bodyInfo}
                                                        </Col>
                                                    </Col>
                                                </Row>
                                                <Row sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                                    {checkpointInfo}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="rowCardButton" sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                            <Col xs={3} md={3} sm={3} className="cardInfoButton cardInfoButtonCheckpoint">
                                                <Button className="btnPlus" 
                                                    color="default"
                                                    onClick={this.props.onHandleClickMoreInfoButton}><FontAwesomeIcon icon={this.props.faPlus}/></Button>
                                            </Col>
                                            <Col xs={3} md={3} sm={3} className="cardInfoButton cardInfoButtonCheckpoint">
                                                {buttonChecklist}
                                            </Col>
                                            <Col xs={6} md={6} sm={6} className="cardInfoButton cardInfoButtonCheckpoint">
                                                {buttonOk}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                );
            }
        }else{
            return (
                <Col xl={3} lg={4} md={6} sm={12} xs={12} className={'cardBody ' + classNameHidden + ' ' + classNameCovid + ' ' + classNameHiddenCovid}>
                    <Card className={'border-2  align-center cardQueueItem ' + boxStudentStyle} style={{ borderColor: borderColor}}>
                        <CardBody className={'cardBodyItem'}>
                            <Row>
                                <Col xl={2} lg={2} md={2} sm={2} xs={2} className={"cardIndication cardQueueItemInternalMaxHeight noPadding vcenter"} style={{backgroundColor: bgColor}}>
                                    {covidIcon}
                                    {cardIcon}
                                </Col>
                                <Col xl={10} lg={10} md={10} sm={10} xs={10} className={"noPadding"}>
                                    <Row className={classNameRowCard  + " minHeightCardInfo "} sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Col xs={4} md={4} sm={4} className="cardInfo cardQueueItemImgContainer">
                                            <img
                                                src={image}
                                                width="auto"
                                                height="auto"
                                                className="cardInfoImg"
                                                alt="" />
                                        </Col>
                                        <Col xs={8} md={8} sm={8} className={"cardInfo"}>
                                            <div mode="multi" 
                                                className="lblTopStudent studentInfo" max={14}>{this.props.student.name}</div>
                                            <Col xs={12} md={12} sm={12} className="cardInfo">                            
                                                {bodyInfo}
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="rowCardButton" sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Col xs={6} md={6} sm={6} className="cardInfoButton">
                                            <Button className="btnPlus" 
                                                color="default"
                                                onClick={this.props.onHandleClickMoreInfoButton}><FontAwesomeIcon icon={this.props.faPlus}/></Button>
                                        </Col>
                                        <Col xs={6} md={6} sm={6} className="cardInfoButton">
                                            {buttonOk}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        showCallerName: state.auth.showCallerName,
        userID: state.auth.userId,
    };
};

export default connect(mapStateToProps)(QueueItem);
// export default QueueItem;