import React from 'react'
import { } from './CallStudentItem.css'

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from '../../componentsUI/Layout/Spinner/Spinner'
import studentImageSilhouette from '../../assets/silhouetteStudent.png'
import { isLink } from '../../utils/isLink'
import Strings from '../../strings/Strings'

class AuthItem extends React.Component {
//const AuthItem = (props) => {

    constructor(props) {
        super(props);

        let image = studentImageSilhouette;
        if (isLink(this.props.student.photo)) {
            image = this.props.student.photo;
        }

        let cardContainer = (
            <Row >
                <Col xs={4} md={4} sm={4} className="cardInfo">
                    <img
                        src={image}
                        width="auto"
                        height="auto"
                        className="cardInfoImg"
                        alt="" />
                </Col>
                <Col xs={8} md={8} sm={8} className={"cardInfo"}>
                    <div mode="multi" 
                        className="lblTopStudent studentInfoTempAuth" max={14}>{this.props.student.name}</div>
                    <Col xs={12} md={12} sm={12} className="cardInfo gradeInfoTempAuth">                            
                        {this.props.student.grade}
                    </Col>
                </Col>
            </Row>
        );

        this.state = { isCallAllStudents: this.props.isCallAllStudents, studentID: this.props.student.id, lastCalledStudentID: this.props.lastCalledStudentID, classNameCallStudent: "", loadingCallStudent: false, image: image, cardContainer: cardContainer, buttonBullhorn: this.props.faBullhorn, buttonPlus: this.props.faPlus, showDetails: false};
    }

    onHandleCallStudentButton = (event, student) => {
        if(this.props.allowCovidForm == 1){
            if(this.props.allowCovidFormWarning == 1 && student.actualCovidInfo.covid_level >= this.props.covidFormLevelToWarning){
                alert(Strings.alerts.covidLevelError);
            }else if(this.props.allowCovidFormWarning == 1 && student.actualCovidInfo == null){
                alert(Strings.alerts.notCovidInfo);
            }else{
                this.props.onHandleCallStudentButton(event, student);

                this.setState({
                    loadingCallStudent: true,
                    lastCalledStudentID: -1,
                    classNameCallStudent: "",
                });
            }
        }else{
            this.props.onHandleCallStudentButton(event, student);

            this.setState({
                loadingCallStudent: true,
                lastCalledStudentID: -1,
                classNameCallStudent: "",
            });
        }
    }

    render() {
        let classNameHidden = "showCard";
        let classNameCallStudent = "";
        let buttonCallStudent = "";
        let spinnerColor = "#ffffff";
        let callAfterCovidCheck = true;

        if(this.props.allowCovidForm == 1 && this.state.classNameCallStudent != "onCallStudent" && this.state.classNameCallStudent != "blockCallStudent"){

            if(this.props.allowCallRestriction == 1 && (this.props.student.actualCovidInfo.covid_level >= this.props.covidFormLevelToWarning || this.props.student.studentRestrictClassToday == 1)){
                this.setState({
                    classNameCallStudent: "blockCallStudent",
                    lastCalledStudentID: -1,
                    loadingCallStudent: false,
                });
                callAfterCovidCheck = false;
            }

            let dateCurrent = null;
            let dateLastFill = null;

            if(this.props.student.actualCovidInfo != null){
                let date = new Date();
                let month = (date.getMonth()+1);
                if(month < 10){
                    month = "0"+month;
                }
                let day = date.getDate();
                if(day < 10){
                    day = "0"+day;
                }

                let current_date = date.getFullYear()+"-"+month+"-"+day+" 00:00:00";
                dateCurrent = new Date(current_date);
                dateLastFill = new Date(this.props.student.actualCovidInfo.last_fill.date);    
            }

            if(this.props.allowCallRestrictionIfNotReportInDay == 0 && (this.props.student.actualCovidInfo == null || dateLastFill <= dateCurrent)){
                this.setState({
                    classNameCallStudent: "blockCallStudent",
                    lastCalledStudentID: -1,
                    loadingCallStudent: false,
                });
                callAfterCovidCheck = false;
            }

            if(this.props.allowStudentsRotation == 1 && this.props.student.studentRestrictClassToday == 2){
                this.setState({
                    classNameCallStudent: "blockCallStudent",
                    lastCalledStudentID: -1,
                    loadingCallStudent: false,
                });
                callAfterCovidCheck = false;
            }            
        }
        
        if(callAfterCovidCheck){
            if(this.state.classNameCallStudent != "blockCallStudent" && this.state.classNameCallStudent != "onCallStudent"){
                if(this.props.student.idFila != null && this.state.classNameCallStudent != "onCallStudent"){
                    this.setState({
                        classNameCallStudent: "onCallStudent",
                    });
                }

                if(this.props.isCallAllStudents == "1" && this.props.classNameCallStudent == "onCallStudent" && this.props.classNameCallStudent != this.state.classNameCallStudent){
                    this.setState({
                        classNameCallStudent: "onCallStudent",
                    });
                }else if(this.props.isCallAllStudents == "1" && this.props.classNameCallStudent != "onCallStudent" && this.props.classNameCallStudent != this.state.classNameCallStudent){
                    this.setState({
                        classNameCallStudent: "",
                    });
                }else if(this.props.isCallAllStudents != "1"){
                    if(this.props.lastCalledStudentID == this.state.studentID && this.state.loadingCallStudent){
                        this.setState({
                            lastCalledStudentID: this.props.lastCalledStudentID,
                        });
                    }   

                    if(this.state.studentID == this.state.lastCalledStudentID && this.state.loadingCallStudent){
                        this.setState({
                            classNameCallStudent: "onCallStudent",
                            lastCalledStudentID: -1,
                            loadingCallStudent: false,
                        });
                    }
                }
            }
        }
        
        if(this.state.loadingCallStudent && this.state.classNameCallStudent != "onCallStudent" && this.state.classNameCallStudent != "blockCallStudent"){
            buttonCallStudent = <Button className={"btnPlus " + this.state.classNameCallStudent}
                                color="default"
                                onClick={(e) => { this.onHandleCallStudentButton(e, this.props.student) }}><Spinner className="loaderButtonCard" color={spinnerColor} /></Button>;

        }else{
            buttonCallStudent = <Button className={"btnPlus " + this.state.classNameCallStudent}
                                color="default"
                                onClick={(e) => { this.onHandleCallStudentButton(e, this.props.student) }}><FontAwesomeIcon icon={this.state.buttonBullhorn}/></Button>;
        }

        return (
            <div>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={'cardBody ' + classNameHidden}>
                    <Card className={'border-2  align-center boxStudent'}>
                        <CardBody className="card-body-internal">
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"noPadding"}>
                                    {this.state.cardContainer}
                                    <Row className='rowCardButton' xs={2}>
                                        <Col xl={12} md={12} sm={12} xs={12} className='cardInfoButton'>
                                            { buttonCallStudent }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        );
    }
}

export default AuthItem;