import React from "react";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import QueueView from "./QueueView";
import FireStoreQueue from "../../components/FireStoreQueue/FireStoreQueue";
import BaseURL from "../../utils/BaseURL";
import { DateTime } from "luxon";
let TimeToConnect = 10;
let MaxWaitTimeToConnect = 30;
const WaitToRemoveStudent = 15;

class QueueController extends React.Component {
  state = {
    controlFindQueue: null,
    controlRemoveStudens: null,
    firstUpdate: true,
    showAgreeTerms: false,
  };

  constructor() {
    super();
    this.lastUpdateStatusConnection = 1;
    this.lastConnectionControl = DateTime.now().minus({ day: 1 });

    this.removeStudents = [];

    const queryString = window.location.href;    
    if (
      queryString.includes("amazingschool") ||
      queryString.includes("vendas")
    ){
          TimeToConnect = 3;
          MaxWaitTimeToConnect = 3;
      console.log("queryString");
      console.log(queryString);          
    }

  }

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    if (this.removeStudents.length > 0) {
      e.preventDefault();
      e.returnValue = "";      
    }
    
  };
  /**
   * Did when component was mount
   */
  componentDidMount = () => {
    if (this.props.showAgreeTerms == 1) {
      this.setState({
        showAgreeTerms: true,
      });
    }

    let controlFindQueue = null;
    this.isConnecting = false;
    this.tryToConnect = false;
    this.fireStoreQueue = null;

    console.log("isSocketAllowed = " + this.props.isSocketAllowed);
    if (parseInt(this.props.isSocketAllowed, 10) === 1) {
      //Get Queue Info at each 3 minutes
      controlFindQueue = setInterval(this.getQueue, 180000);

      this.fireStoreQueue = new FireStoreQueue();
      this.fireStoreQueue.initListen(
        this.props.match.params.schoolName,
        this.getQueue
      );
    } else {
      //Get Queue Info at each 5 seconds
      controlFindQueue = setInterval(this.getQueue, 5000);
    }

    let controlRemoveStudens = null;
    if (!this.props.allow_remove_students_instantly) {
      setInterval(this.controlRemoveStudent, WaitToRemoveStudent * 1000);
    }

    this.setState({
      controlFindQueue: controlFindQueue,
      controlRemoveStudens: controlRemoveStudens,
    });

    //Start to get Queue now
    this.getQueue();

    window.addEventListener("online", this.updateIndicator);
    window.addEventListener("offline", this.updateIndicator);
    window.addEventListener("beforeunload", this.onUnload);
    this.updateIndicator();
  };

  componentDidUpdate = () => {
    if (this.props.lastRemovedID !== -1 && this.state.firstUpdate) {
      this.setState({ firstUpdate: false });
      //Force Dispatch Resize to recalculate Size in Queue Item
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 500);
    }

    if (this.props.userTokenExpire !== "") {
      if (this.state.controlFindQueue !== null) {
        clearInterval(this.state.controlFindQueue);
      }
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.students !== this.props.students ||
      nextProps.cardStudentRemoving !== this.props.cardStudentRemoving ||
      nextProps.emergencyButtonSchool !== this.props.emergencyButtonSchool
    ) {
      return true;
    }

    if (nextProps.userTokenExpire !== "") {
      clearInterval(this.state.controlFindQueue);
    }
    return false;
  }

  updateIndicator = () => {
    if (parseInt(this.props.isSocketAllowed, 10) === 1) {
      if (navigator.onLine) {
        console.log("IS Online");
        if (this.lastUpdateStatusConnection === 0) {
          this.fireStoreQueue = new FireStoreQueue();
          this.fireStoreQueue.initListen(
            this.props.match.params.schoolName,
            this.getQueue
          );
          this.props.setOnline();
        }
        this.lastUpdateStatusConnection = 1;
      } else {
        console.log("IS Offline");
        this.lastUpdateStatusConnection = 0;
        this.fireStoreQueue.stopListen();
        this.props.setOffline();
      }
    }
  };
  componentWillUnmount = () => {
    //Cleaning Interval
    if (this.state.controlFindQueue !== null) {
      clearInterval(this.state.controlFindQueue);
    }

    if (this.state.controlRemoveStudens !== null) {
      clearInterval(this.state.controlRemoveStudens);
    }

    if (this.fireStoreQueue !== null) {
      this.fireStoreQueue.stopListen();
    }

    window.removeEventListener("beforeunload", this.onUnload);
  };

  sendGetQueue = () => {
    this.isConnecting = true;
    this.tryToConnect = false;
    this.lastConnectionControl = DateTime.now();
    this.props.onGetQueue(
      this.props.match.params.schoolName,
      this.props.token,
      this.props.lastUpdate,
      this.props.lastEmailID,
      this.props.lastRemovedID,
      this.props.studentIDsLastRead,
      this.props.isSocketAllowed,
      this.returnGetQueue
    );
  };
  getQueue = () => {
    let now = DateTime.now();
    let diff = now.diff(this.lastConnectionControl, "seconds");
    let diffInSeconds = diff.toObject().seconds;

    // console.log(now.toRFC2822());
    // console.log(this.lastConnectionControl.toRFC2822());
    // console.log(diffInSeconds);
    if(this.isConnecting){
      if(diffInSeconds > MaxWaitTimeToConnect){
        this.sendGetQueue();
      }
      return;
    }

    if (diffInSeconds < TimeToConnect) {
      if (!this.tryToConnect) {
        // console.log("Trying to Connect");
        this.tryToConnect = true;
        setTimeout(() => {
          let now = DateTime.now();
          // console.log(now.toRFC2822());
          // console.log(this.lastConnectionControl.toRFC2822());
          // console.log("Connect Timeout");
          this.sendGetQueue();
        }, TimeToConnect * 1000);
      }
    } else {
      // console.log("Connect Directly");
      this.sendGetQueue();
    }
  };

  controlRemoveStudent = () => {
    if (this.removeStudents.length > 0) {
      let auxRemoveStudents = [...this.removeStudents];
      this.removeStudents = [];

      this.props.setSignalRemoveStudent(0);

      this.props.removeServerStudentsQueue(
        this.props.match.params.schoolName,
        this.props.token,
        auxRemoveStudents
      );
    }
  };

  returnGetQueue = () => {
    this.isConnecting = false;
    // if (this.tryToConnect) {
    //   this.tryToConnect = false;
    //   this.getQueue();
    // }
  };

  onHandleClickOKButton = (student) => {
    //Remove Student from Queue
    if (!this.props.allow_remove_students_instantly) {
      if (this.removeStudents.length == 0) {
        this.props.setSignalRemoveStudent(1);
      }      
      this.removeStudents.push(student);
    }

    this.props.removeStudentQueue(
      this.props.match.params.schoolName,
      this.props.token,
      student,
      this.props.allow_remove_students_instantly
    );
  };

  onHandleClickCheckPointButton = (student) => {
    this.props.studentCheckpoint(
      this.props.match.params.schoolName,
      this.props.token,
      student
    );

    console.log(
      "this.props.studentCheckpointID: ",
      this.props.studentCheckpointID
    );
  };

  onHandleClickCheckListButton = (event, student) => {
    //Remove Student from Queue
    //alert("Checklist")

    event.preventDefault();
    event.stopPropagation();

    //Remove Student from Queue
    this.props.changeDetailCheckPoint(student);

    document.querySelector(".cr-sidebar").classList.add("cr-sidebar--open");

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  };

  onHandleClickMoreInfoButton = (event, student) => {
    event.preventDefault();
    event.stopPropagation();

    //Remove Student from Queue
    this.props.changeDetailStudent(student);

    document.querySelector(".cr-sidebar").classList.add("cr-sidebar--open");

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  };

  onHandleClickDetailVehicleButton = (event, student) => {
    event.preventDefault();
    event.stopPropagation();

    //Remove Student from Queue
    this.props.changeDetailVehicles(student);

    document.querySelector(".cr-sidebar").classList.add("cr-sidebar--open");

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  };


  onAgreeTerms = () => {
    console.log("here");
    this.setState({
      showAgreeTerms: false,
    });
  };

  onCloseEmergencyButton = () => {
    var form = new FormData();
    let emergencyInfo = this.props.emergencyButtonSchool[0];
    form.append("emergencyButtonID", emergencyInfo.id);

    fetch(
      new BaseURL().getMainURL() +
        this.props.match.params.schoolName +
        "/v1/emergency/readEmergencyButton",
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " + this.props.token,
        }),
        body: form,
      }
    )
      .then((results) => {
        /* console.log(results);*/ return results.json();
      })
      .then((data) => {
        console.log(data.infoReturn.returnID);
        this.props.removeEmergencyButton(this.props.emergencyButtonSchool[0]);
      })
      .catch((err) => {
        console.log("Error", err);
        this.props.removeEmergencyButton(this.props.emergencyButtonSchool[0]);
      });
  };

  render() {
    return (
      <QueueView
        id="teste"
        students={this.props.students}
        onHandleClickOKButton={this.onHandleClickOKButton}
        onHandleClickMoreInfoButton={this.onHandleClickMoreInfoButton}
        onHandleClickCheckPointButton={this.onHandleClickCheckPointButton}
        onHandleClickCheckListButton={this.onHandleClickCheckListButton}
        cardStudentRemoving={this.props.cardStudentRemoving}
        hasNewStudent={this.props.hasNewStudent}
        showOnlyNames={this.props.showOnlyNames}
        autoRool={this.props.autoRool}
        card_color_walking={this.props.card_color_walking}
        card_border_color_walking={this.props.card_border_color_walking}
        card_color_driving={this.props.card_color_driving}
        card_border_color_driving={this.props.card_border_color_driving}
        onChangePassword={this.onChangePassword}
        showAgreeTerms={this.props.showAgreeTerms}
        onAgreeTerms={this.onAgreeTerms}
        typeInspector={this.props.typeInspector}
        allow_checkpoint={this.props.allow_checkpoint}
        onHandleClickDetailVehicleButton={this.onHandleClickDetailVehicleButton}
        allow_add_car_inspector={this.props.allow_add_car_inspector}
        emergencyButtonSchool={this.props.emergencyButtonSchool}
        onCloseEmergencyButton={this.onCloseEmergencyButton}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    students: state.queue.studentsFiltered,
    lastUpdate: state.queue.lastUpdate,
    lastEmailID: state.queue.lastEmailID,
    lastRemovedID: state.queue.lastRemovedID,
    cardStudentRemoving: state.queue.cardStudentRemoving,
    hasNewStudent: state.queue.hasNewStudent,
    isAuthenticated: state.auth.token !== null,
    showOnlyNames: state.auth.showOnlyNames,
    autoRool: state.auth.autoRool,
    userTokenExpire: state.queue.userTokenExpire,
    studentIDsLastRead: state.queue.studentIDsLastRead,
    emergencyButtonSchool: state.queue.emergencyButtonSchool,
    isSocketAllowed: state.auth.isSocketAllowed,
    card_color_walking: state.auth.card_color_walking,
    card_border_color_walking: state.auth.card_border_color_walking,
    card_color_driving: state.auth.card_color_driving,
    card_border_color_driving: state.auth.card_border_color_driving,
    showAgreeTerms: state.auth.showAgreeTerms,
    typeInspector: state.auth.typeInspector,
    allow_checkpoint: state.auth.allow_checkpoint,
    allow_add_car_inspector: state.auth.allow_add_car_inspector,
    studentCheckpointID: state.checkpoint.studentCheckpoint,
    checkpoint_date: state.checkpoint.checkpoint_date,
    allow_remove_students_instantly:
      state.auth.allow_remove_students_instantly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetQueue: (
      schoolName,
      token,
      lastUpdate,
      lastEmailID,
      lastRemovedID,
      lastStudentRead,
      isSocketAllowed,
      returnGetQueueFunction
    ) =>
      dispatch(
        actions.getQueue(
          schoolName,
          token,
          lastUpdate,
          lastEmailID,
          lastRemovedID,
          lastStudentRead,
          isSocketAllowed,
          returnGetQueueFunction
        )
      ),
    removeStudentQueue: (schoolName, token, student, allow_remove_students_instantly) =>
      dispatch(actions.removeStudentQueue(schoolName, token, student, allow_remove_students_instantly)),
    removeServerStudentsQueue: (schoolName, token, arrayRemoveStudens) =>
      dispatch(
        actions.removeServerStudentsQueue(schoolName, token, arrayRemoveStudens)
      ),
    studentCheckpoint: (schoolName, token, student) =>
      dispatch(actions.studentCheckpoint(schoolName, token, student)),
    changeDetailStudent: (student) =>
      dispatch(actions.changeDetailStudent(student)),
    changeDetailCheckPoint: (student) =>
      dispatch(actions.changeDetailCheckPoint(student)),
    changeDetailVehicles: (student) =>
      dispatch(actions.changeDetailVehicles(student)),
    removeEmergencyButton: (emergencyButton) =>
      dispatch(actions.removeEmergencyButton(emergencyButton)),
    setOffline: () => dispatch(actions.setOffline()),
    setOnline: () => dispatch(actions.setOnline()),
    setSignalRemoveStudent: (value) => dispatch(actions.setSignalRemoveStudent(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QueueController);
