import LocalizedStrings from 'react-localization';

import enLang from '../assets/locales/en/translation.json'
import ptLang from '../assets/locales/pt/translation.json'
import esLang from '../assets/locales/es/translation.json'

const Strings = new LocalizedStrings({
  en: enLang,
  pt_br: ptLang,
  pt: ptLang,
  es: esLang,
});

export default Strings;
