import React from 'react';
class RedirectFSFPage extends React.Component {

    componentDidMount() {
        window.location = 'https://www.schoolguardian.app';
    }
    render() {

        return (
            <p></p>
         );
     }
}

export default RedirectFSFPage;
