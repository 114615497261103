
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';
import DeviceID from "../../utils/DeviceID";
import Strings from "../../strings/Strings";
import { DateTime } from "luxon";

/**
 * This object manage the redux flow of informatio
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */


export const getQueueStart = () => {
    return {
        type: actionTypes.GET_QUEUE_START,
    };
};

export const getQueueSuccess = (dataInfo) => {
    return {
        type: actionTypes.GET_QUEUE_SUCCESS,
        info: dataInfo,
    };
};

export const getQueueFail = (errorCode) => {
    return {
        type: actionTypes.GET_QUEUE_FAIL,
        errorCode: errorCode
    };
};

export const setConnectionOnline = (errorCode) => {
    return {
        type: actionTypes.SET_ONLINE,
        errorCode: errorCode
    };
};

export const removeStudentStart = (student) => {
    return {
        type: actionTypes.REMOVE_STUDENT_START,
        student: student,
    };
};
export const removeStudentSuccess = (student, allow_remove_students_instantly) => {
  return {
    type: actionTypes.REMOVE_STUDENT_SUCCESS,
    student: student,
    allow_remove_students_instantly: allow_remove_students_instantly,
  };
};
export const removeWaitingStudentSuccess = (students) => {
  return {
    type: actionTypes.REMOVE_WAITING_STUDENT_SUCCESS,
    students: students,
  };
};
export const removeStudentFail = (student) => {
    return {
        type: actionTypes.REMOVE_STUDENT_FAIL,
        student: student,
    };
};

export const changeShowTempAuth = () => {
    return {
        type: actionTypes.CHANGE_SHOW_TEMP_AUTH,
    };
};

export const emptyAuthTemps = () => {
    return {
        type: actionTypes.EMPTY_AUTH_TEMPS,
    };
};

export const removeAuthStart = (tempAuth) => {
    return {
        type: actionTypes.REMOVE_AUTH_START,
        tempAuth: tempAuth,
    };
};
export const removeAuthSuccess = (tempAuth) => {
    return {
        type: actionTypes.REMOVE_AUTH_SUCCESS,
        tempAuth: tempAuth,
    };
};
export const removeAuthFail = (tempAuth) => {
    return {
        type: actionTypes.REMOVE_AUTH_FAIL,
        tempAuth: tempAuth,
    };
};

export const cleanAuthNoRead = (tempAuth) => {
    return {
        type: actionTypes.CLEAN_AUTH_NO_READ,
        tempAuth: tempAuth,
    };
};

export const cleanStudentsAuthLogout = (dataInfo) => {
    return {
        type: actionTypes.CLEAN_STUDENTS_AUTH_LOGOUT,
        info: dataInfo,
    };
};

export const filterQueue = (search) => {
    return {
        type: actionTypes.FILTER_QUEUE,
        search: search,
    };
};


export const getQueue = (schoolName, token, lastUpdate, lastEmailID, lastRemovedID, lastStudentRead, isSocketAllowed, returnGetQueueFunction) => {
    return dispatch => {
        dispatch(getQueueStart());

        let deviceID = new DeviceID().getDeviceID();
        var form = new FormData();
        form.append("lastUpdate", lastUpdate);
        form.append("lastEmailID", lastEmailID);
        form.append("lastIDRemoved", lastRemovedID);
        form.append("idsLastStudentRead", lastStudentRead.join());
        form.append("isSocketAllowed", isSocketAllowed);
        form.append("deviceID", deviceID);
        form.append("now", DateTime.now());

        console.log("Device ID = " + deviceID);

        let isInspectorTV = new BaseURL().isTVInspector();
        form.append("deviceType", isInspectorTV?"1":"0");

        let appVersion = new BaseURL().getAppVersion();
        form.append("appVersion", appVersion);
        form.append("gcm", "-13");
        let lastConnection = localStorage.getItem('lastConnection');
        if(lastConnection !== ""){
            let lastConnectionDt = new Date(lastConnection);
            if(lastConnectionDt){
                lastConnection = lastConnectionDt.getFullYear()+"-"+lastConnectionDt.getMonth()+"-"+lastConnectionDt.getDate();
            }
        } else {
            lastConnection = "";
        }
        form.append("lastConnection", lastConnection);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/getQueue', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body : form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {

                if (data.infoReturn.returnID === "0") {
                    let appVersion = new BaseURL().getAppVersion();
                    if(data.info.appVersion !== appVersion && 
                        localStorage.getItem('appVersion') !== data.info.appVersion){
                        localStorage.setItem('appVersion', data.info.appVersion);
                        alert(Strings.login.expiredAppVersion);
                        window.location.reload(true);
                    }
                    localStorage.setItem('lastConnection', new Date());
                    dispatch(getQueueSuccess(data.info));
                } else if (data.infoReturn.returnID === "-2") {
                    let appVersion = new BaseURL().getAppVersion();
                    if(data.info.appVersion !== appVersion && 
                        localStorage.getItem('appVersion') !== data.info.appVersion){
                        localStorage.setItem('appVersion', data.info.appVersion);
                        alert(Strings.login.expiredAppVersion);
                        window.location.reload(true);
                    }
                    dispatch(getQueueFail(data.infoReturn.returnID));                    
                } else {                    
                    dispatch(getQueueFail(data.infoReturn.returnID));
                    // dispatch(getQueueFail());
                }
                returnGetQueueFunction();
            })
            .catch(err => {
                console.log("Error", err);
                returnGetQueueFunction();
                dispatch(getQueueFail(-99));
            });
    };

};

export const removeStudentQueue = (schoolName, token, student, allow_remove_students_instantly) => {
    return dispatch => {

        dispatch(removeStudentStart(student));
        if (!allow_remove_students_instantly){
            dispatch(
                removeStudentSuccess(student, false)
            );
        } else {
            var form = new FormData();
            form.append("queueID", student.queueID);
            form.append("studentID", student.id);

            fetch(
            new BaseURL().getMainURL() + schoolName + "/v1/inspector/removeStudentQueue",
            {
                method: "POST",
                headers: new Headers({
                Accept: "application/json",
                Authorization: "Bearer " + token,
                }),
                body: form,
            }
            )
            .then((results) => {
                /* console.log(results);*/ return results.json();
            })
            .then((data) => {
                if (data.infoReturn.returnID === "0") {
                    dispatch(removeStudentSuccess(student, true));
                } else {
                dispatch(removeStudentFail(student));
                }
            })
            .catch((err) => {
                console.log("Error", err);
                dispatch(removeStudentFail(student));
            });
        }                  
    };
};

export const removeServerStudentsQueue = (schoolName, token, arrayRemoveStudens) => {
  return (dispatch) => {

    let arrayStudents = [];
    let arrayQueueIDs = [];

    arrayRemoveStudens.forEach(student => {
        arrayQueueIDs.push(student.queueID);
        arrayStudents.push(student.id);
    });

    var form = new FormData();
    form.append("queueIDs", arrayQueueIDs.join());
    form.append("studentIDs", arrayStudents.join());

    fetch(
      new BaseURL().getMainURL() +
        schoolName +
        "/v1/inspector/removeSelectedStudentsQueue",
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " + token,
        }),
        body: form,
      }
    )
      .then((results) => {
        /* console.log(results);*/ return results.json();
      })
      .then((data) => {
        // if (data.infoReturn.returnID === "0") {
        //   dispatch(removeStudentSuccess(student));
        // } else {
        //   dispatch(removeStudentFail(student));
        // }
        dispatch(removeWaitingStudentSuccess(arrayQueueIDs));
        console.log("Remove student success = " + data.infoReturn.returnID);
      })
      .catch((err) => {
        console.log("Error", err);
        // dispatch(removeStudentFail(student));
      });
  };
};

export const removeAuthQueue = (schoolName, token, tempAuth) => {
    return dispatch => {

        dispatch(removeAuthStart(tempAuth));

        var form = new FormData();
        form.append("emailID", tempAuth.id);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/deleteEmail', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {

                if (data.infoReturn.returnID === "0") {
                    dispatch(removeAuthSuccess(tempAuth));
                } else {
                    dispatch(removeAuthFail(tempAuth));
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(removeAuthFail(tempAuth));
            });
    };
};

export const setOffline = () => {
    return dispatch => {

        dispatch(getQueueFail(-99));
    };
};

export const setOnline = () => {
    return dispatch => {

        dispatch(setConnectionOnline(0));
    };
};

export const removeEmergencyButton = (emergencyButton) => {
  return {
    type: actionTypes.REMOVE_EMERGENCY_BUTTON,
    emergencyButton: emergencyButton,
  };
};

export const setSignalRemoveStudent = (value) => {
  return {
    type: actionTypes.CHANGE_SIGNAL_REMOVE_STUDENT,
    signalRemoveStudent: value,
  };
};