import React from 'react';
import {connect} from 'react-redux'

import ChangeLanguageView  from './ChangeLanguageView.js'
import Strings from '../../strings/Strings'
import ManageLanguage from '../../utils/ManageLanguage'

class ChangeLanguageController extends React.Component {

    onChangeLanguage = (language) =>{
        const manageLanguage = new ManageLanguage();
        manageLanguage.changeLanguage(language);

        this.props.onChooseLanguage();
        window.location.href = "/"+this.props.schoolName+"/";
    }

    render(){
        let currentLanguage = Strings.getLanguage();
        return <ChangeLanguageView isOpen={this.props.isOpen}
                     onChangeLanguage={this.onChangeLanguage} 
                     currentLanguage={currentLanguage}/> 
    }
}

const mapStateToProps = state => {
    return {
        schoolName: state.auth.schoolName,        
    };
};

export default connect(mapStateToProps)(ChangeLanguageController);