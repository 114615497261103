
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';
import { changeVehicleDetailUser } from './screenDetailChange'

/**
 * This object manage the redux flow of informatio
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */
export const getVehicleStart = () => {
    return {
        type: actionTypes.GET_VEHICLE_START,
    };
};

export const getVehicleFail = (errorCode) => {
    return {
        type: actionTypes.GET_VEHICLE_FAIL,
        errorCode: errorCode
    };
};

export const getVehicleSuccess = (vehicles) => {
    return {
        type: actionTypes.GET_VEHICLE_SUCCESS,
        vehicles: vehicles
    };
};

export const updateVehicleStart = () => {
    return {
        type: actionTypes.UPDATE_VEHICLE_START,
    };
};

export const updateVehicleFail = (errorCode) => {
    return {
        type: actionTypes.UPDATE_VEHICLE_FAIL,
        errorCode: errorCode
    };
};

export const updateVehicleSuccess = () => {
    return {
        type: actionTypes.UPDATE_VEHICLE_SUCCESS,
    };
};
export const addNewVehicleStart = () => {
    return {
        type: actionTypes.ADD_NEW_VEHICLE_START,
    };
};

export const addNewVehicleFail = (errorCode) => {
    return {
        type: actionTypes.ADD_NEW_VEHICLE_FAIL,
        errorCode: errorCode
    };
};

export const addNewVehicleSuccess = () => {
    return {
        type: actionTypes.ADD_NEW_VEHICLE_SUCCESS,
    };
};


export const getVehicle = (schoolName, token, queueID) => {
    return dispatch => {
        console.log("Getting Vehicle");
        dispatch(getVehicleStart());


        var form = new FormData();
        form.append("queueID", queueID);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/searchCarsByQueueItem', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body : form
        })
            .then(results => { console.log(results); return results.json(); })
            .then(data => {
                if (data.infoReturn.returnID === "0") {
                    dispatch(getVehicleSuccess(data.info.vehicles));
                } else {                    
                    dispatch(getVehicleFail(data.infoReturn.returnID));
                }
            })
            .catch(err => {
                // console.log("Error", err);
                dispatch(getVehicleFail(-33));
            });
    };
};


export const updateVehicle = (schoolName, token, queueID, vehicleParentID) => {
    return dispatch => {
        console.log("Updating Vehicle");
        dispatch(updateVehicleStart());


        var form = new FormData();
        form.append("queueID", queueID);
        form.append("vehicleParentID", vehicleParentID);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/changeCarInQueue', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { console.log(results); return results.json(); })
            .then(data => {
                if (data.infoReturn.returnID === "1") {
                    console.log("Updating Vehicle SUCCESS");

                    dispatch(changeVehicleDetailUser(vehicleParentID));
                    dispatch(updateVehicleSuccess());
                } else {
                    console.log("Updating Vehicle FAIL");
                    dispatch(updateVehicleFail(data.infoReturn.returnID));
                }
            })
            .catch(err => {
                // console.log("Error", err);
                dispatch(updateVehicleFail(-33));
            });
    };
};


export const addNewVehicle = (schoolName, token, queueID, car_model, car_plate, car_color,all_guardians) => {
    return dispatch => {
        console.log("Updating Vehicle");
        dispatch(addNewVehicleStart());


        var form = new FormData();
        form.append("queueID", queueID);
        form.append("car", car_model);
        form.append("plate", car_plate);
        form.append("color", car_color);
        form.append("addInAllGuardians", all_guardians);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/addCarByQueueItem', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
        .then(results => { console.log(results); return results.json(); })
        .then(data => {
            if (data.infoReturn.returnID === "1") {
                console.log("Updating Vehicle SUCCESS");

                // dispatch(changeVehicleDetailUser(vehicleParentID));
                dispatch(addNewVehicleSuccess());
            } else {
                console.log("Updating Vehicle FAIL" + data.infoReturn.returnID);
                dispatch(addNewVehicleFail(data.infoReturn.returnID));
            }
        })
        .catch(err => {
            // console.log("Error", err);
            dispatch(addNewVehicleFail(-33));
        });
    };
};