import React from 'react'
import { } from './TempAuthItem.css'
import Strings from '../../../strings/Strings'
import BaseURL from '../../../utils/BaseURL'

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

class AuthItem extends React.Component {
//const AuthItem = (props) => {

    constructor(props) {
        super(props);

        let image = "/static/media/silhouetteStudent.d636d251.png";
        if(this.props.authTemp.image !== "" && this.props.authTemp.image !== "semimagem"){
            image = this.props.authTemp.image;
        }
        let cardContainer = (
            <Row >
                <Col xs={4} md={4} sm={4} className="cardInfo">
                    <img
                        src={image}
                        width="auto"
                        height="auto"
                        className="cardInfoImg"
                        alt="" />
                </Col>
                <Col xs={8} md={8} sm={8} className={"cardInfo"}>
                    <Col xs={12} md={12} sm={12} className="cardTitle noPaddingRight noPaddingLeft">
                        {Strings.showTempAuth.title}
                    </Col>
                    <div mode="multi" 
                        className="lblTopStudent studentInfoTempAuth" max={14}>{this.props.authTemp.name}</div>
                    <Col xs={12} md={12} sm={12} className="cardInfo gradeInfoTempAuth">                            
                        {this.props.authTemp.grade}
                    </Col>
                    <Col xs={12} md={12} sm={12} className="cardInfo fromTempAuth">                            
                        <span>{Strings.showTempAuth.from}: </span><font className="guardianInfoTempAuth">{this.props.authTemp.sender}</font>
                    </Col>
                </Col>
            </Row>
        );

        this.state = { image: image, cardContainer: cardContainer, buttonPlus: this.props.faPlus, showDetails: false};
    }

    render() {
        let classNameHidden = "showCard";

        const onShowDetailTempAuth = () => {
            let changeCardContainer = (<div><Row><Col xs={12} md={12} sm={12} className="cardTitle">
                    {Strings.showTempAuth.title}
                </Col></Row>
                <Row className="authTempContainer">
                    <Col md={12} sm={12} xs={12} className="authTempMessage">{this.props.authTemp.message}</Col>
                </Row></div>);

            if(this.state.showDetails == false){
                this.setState({showDetails: true});
                this.setState({buttonPlus: this.props.faArrowLeft});
            }else{
                this.setState({showDetails: false});
                changeCardContainer = (
                    <Row >
                        <Col xs={4} md={4} sm={4} className="cardInfo">
                            <img
                                src={this.state.image}
                                width="auto"
                                height="auto"
                                className="cardInfoImg"
                                alt="" />
                        </Col>
                        <Col xs={8} md={8} sm={8} className={"cardInfo"}>
                            <Col xs={12} md={12} sm={12} className="cardTitle noPaddingRight noPaddingLeft">
                                {Strings.showTempAuth.title}
                            </Col>
                            <div mode="multi" 
                                className="lblTopStudent studentInfoTempAuth" max={14}>{this.props.authTemp.name}</div>
                            <Col xs={12} md={12} sm={12} className="cardInfo gradeInfoTempAuth">                            
                                {this.props.authTemp.grade}
                            </Col>
                            <Col xs={12} md={12} sm={12} className="cardInfo fromTempAuth">                            
                                <span>{Strings.showTempAuth.from}: </span><font className="guardianInfoTempAuth">{this.props.authTemp.sender}</font>
                            </Col>
                        </Col>
                    </Row>
                );
                this.setState({buttonPlus: this.props.faPlus});
            }

            this.setState({cardContainer: changeCardContainer});
        }
        let isInspetorTV = new BaseURL().isTVInspector();
        let buttons = null;
        if (!isInspetorTV){
            buttons = <Row className="rowCardButton" xs={2}>
                <Col xs={6} md={6} sm={6} className="cardInfoButton">
                    <Button className="btnPlus"
                        color="default"
                        onClick={onShowDetailTempAuth}><FontAwesomeIcon icon={this.state.buttonPlus} /></Button>
                </Col>
                <Col xs={6} md={6} sm={6} className="cardInfoButton">
                    <Button color="default" className="btnRemove"
                        onClick={this.props.onRemoveTempAuth}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Col>
            </Row>
        }
        
        return (
            <div>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={'cardBody ' + classNameHidden}>
                    <Card className={'border-2  align-center boxTempAuth'}>
                        <CardBody className={'noPadding'}>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"noPadding"}>
                                    {this.state.cardContainer}
                                    {buttons}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        );
    }
}

export default AuthItem;