import React from 'react'
import { } from './CallStudentGuardianItem.css'

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Strings from '../../strings/Strings'
import guardianImageSilhouette from '../../assets/silhouetteParent.png'
import { isLink } from '../../utils/isLink'
class AuthItem extends React.Component {
//const AuthItem = (props) => {

    constructor(props) {
        super(props);
        
        let image = guardianImageSilhouette;
        if (isLink(this.props.guardian.photo)) {
            image = this.props.guardian.photo;
        }

        let parentship = Strings.parentship.others;
        if(this.props.guardian.parentship == 0){
            parentship = Strings.parentship.main;
        }

        let hiddenButton = "";
        if(this.props.hiddenButton){
            hiddenButton = "hiddenButton";
        }

        let cardContainer = (
            <Row >
                <Col xs={4} md={4} sm={4} className="cardInfo">
                    <img
                        src={image}
                        width="auto"
                        height="auto"
                        className="cardInfoImg"
                        alt="" />
                </Col>
                <Col xs={8} md={8} sm={8} className={"cardInfo"}>
                    <div mode="multi" 
                        className="lblTopStudent studentInfoTempAuth" max={14}>{this.props.guardian.name}</div>
                    <Col xs={12} md={12} sm={12} className="cardInfo gradeInfoTempAuth">                            
                        {this.props.guardian.doc}
                    </Col>
                    <Col xs={12} md={12} sm={12} className="cardInfo gradeInfoTempAuth">                            
                        {parentship}
                    </Col>
                </Col>
            </Row>
        );

        this.state = { image: image, cardContainer: cardContainer, buttonPlus: this.props.faPlus, showDetails: false, hiddenButton: hiddenButton};
    }

    render() {
        let classNameHidden = "showCard";
        let hiddenButton = this.state.hiddenButton;

        return (
            <div>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={'cardBody ' + classNameHidden}>
                    <Card className={'border-2  align-center boxStudent'}>
                        <CardBody className="card-body-internal">
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"noPadding"}>
                                    {this.state.cardContainer}
                                    <Row className={'rowCardButton '+ hiddenButton} xs={2}>
                                        <Col xl={12} md={12} sm={12} xs={12} className="cardInfoButton">
                                            <Button className="btnPlus" 
                                                color="default"
                                                onClick={this.props.onHandleClickMoreInfoButton}><FontAwesomeIcon icon={this.state.buttonPlus}/></Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        );
    }
}

export default AuthItem;