import React from 'react'
import { } from './SearchStudentDetailItem.css'
import Strings from '../../strings/Strings'

import {
    Row,
    Col,
    Label,
    Button
} from 'reactstrap';



const SearchStudentDetailItem = (props) => {
    return (
        <div>
            <Row  >
                <Col md={12} className="authDetailItem itemText">
                    <Label className=""><span className="itemTextBold"
                        >{Strings.showTempAuth.from}: </span>{props.authTemp.sender}</Label>
                    <Label className=""><span className="itemTextBold"
                        >{Strings.showTempAuth.subject}: </span>{props.authTemp.title}</Label>
                    <hr className="hrSpaceResp" />
                    <p className="textEmail" dangerouslySetInnerHTML={{ __html: props.authTemp.message }} />   
                    <hr className="hrSpaceResp" />
                    <div className="text-right">
                        <Button color="danger" className="buttonAuthDetailItem"
                            onClick={props.onCloseDetailTemp}>{Strings.showTempAuth.close}</Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default SearchStudentDetailItem;