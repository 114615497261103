
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';

/**
 * This object manage the redux flow of information
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */
export const showFoundGuardiansCallStudents = (guardians, covidFormLevelToWarning, allowCallRestriction, allowCovidForm, allowCovidFormWarning, allowCallRestrictionIfNotReportInDay, allowStudentsRotation) => {
    console.log(guardians);
    return {
        type: actionTypes.SHOW_FOUND_GUARDIANS_CALL_STUDENTS,
        guardians: guardians,
        covidFormLevelToWarning: covidFormLevelToWarning,
        allowCallRestriction: allowCallRestriction, 
        allowCovidForm: allowCovidForm, 
        allowCovidFormWarning: allowCovidFormWarning,
        allowCallRestrictionIfNotReportInDay: allowCallRestrictionIfNotReportInDay, 
        allowStudentsRotation: allowStudentsRotation 
    };
};
export const showNotFoundGuardiansCallStudents = (guardians) => {
    return {
        type: actionTypes.SHOW_NOT_FOUND_GUARDIANS_CALL_STUDENTS,
        showLoading: false
    };
};

export const emptyCallStudent = (schoolName, token, string) => {

    let showLoading = false;
    if(string.length > 0){
        showLoading = true;
    }

    return {
        type: actionTypes.EMPTY_GUARDIANS_CALL_STUDENTS,
        showLoading: showLoading
    };
};

export const emptySearchStudent = () => {
    return {
        type: actionTypes.EMPTY_SEARCH_STUDENTS,
    };
};

export const setCleanSearch = () => {
    return {
        type: actionTypes.CLEAN_SEARCH_STUDENT_INPUT,
    };
};


export const searchCallStudent = (schoolName, token, string) => {

    console.log(schoolName);
    console.log(token);
    console.log(string);

    return dispatch => {

        var form = new FormData();
        form.append("query", string);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/searchStudentsAndGuardians', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {
                if (data.infoReturn.returnID == 1) {

                    /*data.info.students.forEach(student => {
                        student.guardians.map((guardian) => {
                            guardians.concat(guardian);
                        });
                    });*/

                    let guardians = new Array();

                    console.log(data.info.students);

                    for (const [key, value] of Object.entries(data.info.students)) {
                        for (const [keyStudent, valueStudent] of Object.entries(value.guardians)) {
                            console.log(valueStudent)
                            guardians.push(valueStudent);
                        }
                    }

                    dispatch(showFoundGuardiansCallStudents(guardians, data.info.covidFormLevelToWarning, data.info.allowCallRestriction, data.info.allowCovidForm, data.info.allowCovidFormWarning, data.info.allowCallRestrictionIfNotReportInDay, data.info.allowStudentsRotation));
                } else {
                    dispatch(showNotFoundGuardiansCallStudents());
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(showNotFoundGuardiansCallStudents());
            });

    };
};
