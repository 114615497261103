import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';
import Strings from '../../strings/Strings'

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
    studentToSend: null,
    isSending: false,
    messageReturn: '',
    codeReturn: 0
};

const sendMessageStart = (state, action) => {

    let updateInfo = {};
    updateInfo['studentToSend'] = action.student;
    updateInfo['isSending'] = true;
    updateInfo['messageReturn'] = '';
    updateInfo['codeReturn'] = 0;
    return updateObject(state, updateInfo);
}

const sendMessageSuccess = (state, action) => {

    let updateInfo = {};
    updateInfo['studentToSend'] = null;
    updateInfo['isSending'] = false;
    updateInfo['messageReturn'] = Strings.sendMessage.messageSuccess;
    updateInfo['codeReturn'] = 1;
    return updateObject(state, updateInfo);
}

const sendMessageFail = (state, action) => {

    let updateInfo = {};
    updateInfo['studentToSend'] = null;
    updateInfo['isSending'] = false;
    updateInfo['messageReturn'] = Strings.sendMessage.messageError;
    updateInfo['codeReturn'] = 2;

    return updateObject(state, updateInfo);
}

const sendMessageClean = (state, action) => {

    let updateInfo = {};    
    updateInfo['isSending'] = false;
    updateInfo['messageReturn'] = '';
    updateInfo['codeReturn'] = 0;

    return updateObject(state, updateInfo);
}

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SEND_MESSAGE_START: return sendMessageStart(state, action);
        case actionTypes.SEND_MESSAGE_SUCCESS: return sendMessageSuccess(state, action);
        case actionTypes.SEND_MESSAGE_FAIL: return sendMessageFail(state, action);
        case actionTypes.SEND_MESSAGE_CLEAN: return sendMessageClean(state,action);
        default:
            return state;
    }
};

export default reducer;