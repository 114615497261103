import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import ShowSearchStudentsView from './ShowSearchStudentsView.js'
import SearchStudentDetailItem from '../../components/SearchStudentDetailItem/SearchStudentDetailItem'
import ConfirmAction from '../../components/ConfirmAction/ConfirmAction'
import Aux from '../../hoc/ReactAux/ReactAux'
import Strings from '../../strings/Strings'

class ShowSearchStudentsController extends React.Component {

    state = {
        showDetail: false,
        authTempDetail: null,
        showModal: false,
        removeAuth: null
    }

    handleSidebarControlButton = event => {
        event.preventDefault();
        event.stopPropagation();

        document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    };

    onShowDetailTempAuth = (authTemp) => {
        this.setState({
            showDetail: true,
            authTempDetail: authTemp
        })
    };

    onCloseDetailTemp = () => {
        this.setState({
            showDetail: false,
            authTempDetail: null
        })
    }

    onRemoveTempAuth = (authTemp) => {
        this.setState({
            showModal: true,
            removeAuth:authTemp
        })        
    }

    onConfirmAction = () => {
        this.props.removeAuthQueue(this.props.schoolName,
            this.props.token, this.state.removeAuth);

        this.setState({
            showModal: false,
            removeAuth: null
        });
    }

    onCloseAction = () => {
        this.setState({
            showModal: false,
            removeAuth: null
        })
    }

    onHandleClickMoreInfoButton = (event, student) => {
        event.preventDefault();
        event.stopPropagation();

        //Remove Student from Queue
        this.props.changeDetailStudent(student);

        document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
        
        //Force Dispatch Resize to recalculate Size in Queue Item
        setTimeout(()=> {
            window.dispatchEvent(new Event('resize'));
        }, 500);
        
    }

    render() {
        let renderComp = (<ShowSearchStudentsView students={this.props.students}
                onHandleSidebarControlButton={this.handleSidebarControlButton}
                onShowDetailTempAuth={this.onShowDetailTempAuth}
                onRemoveTempAuth={this.onRemoveTempAuth}
                cardAuthRemoving={this.props.cardAuthRemoving}
                onHandleClickMoreInfoButton={this.onHandleClickMoreInfoButton}
             /> )
        if(this.state.showDetail){
            renderComp = <SearchStudentDetailItem 
                            authTemp={this.state.authTempDetail}
                            onCloseDetailTemp={this.onCloseDetailTemp} />;
        }
        return (
            <Aux>
                <ConfirmAction showModal={this.state.showModal}
                    title={Strings.showTempAuth.sureRemoveItem}
                    onConfirm={this.onConfirmAction}
                    onClose={this.onCloseAction}/>
            { renderComp }       
            </Aux>
        )        
    }

};

const mapStateToProps = state => {
    return {
        schoolName: state.auth.schoolName,
        token: state.auth.token,
        students: state.searchStudent.students,
        cardAuthRemoving: state.queue.cardAuthRemoving,        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeAuthQueue: (schoolName, token, tempAuth) => dispatch(actions.removeAuthQueue(schoolName, token, tempAuth)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ShowSearchStudentsController);
