import React from 'react';

import { } from './ShowSearchStudentsView.css'
import {
    Row,
    Col
} from 'reactstrap';

import SearchStudentItem from '../../components/SearchStudentItem/SearchStudentItem'
import { faPlus, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

const ShowSearchStudentsView = (props) => {

    if(props.students){
        props.students.map((student) => {
            let isLoading = false;
            if (props.cardAuthRemoving.indexOf(student.id) !== -1){
                isLoading = true
            }
            return <SearchStudentItem key={student.id} student={student} 
                onShowDetailTempAuth={props.onShowDetailTempAuth.bind(this, student)}
                onRemoveTempAuth={props.onRemoveTempAuth.bind(this, student)}
                isLoading={isLoading} faPlus={faPlus} faArrowLeft={faArrowLeft}
                onHandleClickMoreInfoButton={(e) => { props.onHandleClickMoreInfoButton(e, student) }} />
        })
    }
    
    return (
        <div>
            <Row className="" >
                <Col md={12}>
                    
                </Col>
            </Row>
        </div>
    )
}


export default ShowSearchStudentsView;