import React from 'react';
import { connect } from 'react-redux';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

class UserTokenExpireNotification extends React.Component {

    componentDidUpdate= () => {

        const message = this.props.userTokenExpire.split("{|}");
        if(message[0] !== ''){
            setTimeout(() => {
                this.notificationSystem.addNotification({
                    message: message[0],
                    level: 'error',
                    autoDismiss: 0,
                    position: 'bc'
                });
            }, 500);
        }                
    }
    render () {

        return (
            <NotificationSystem
                dismissible={false}
                ref={notificationSystem =>
                    (this.notificationSystem = notificationSystem)
                }
                style={NOTIFICATION_SYSTEM_STYLE}
            />
        )
    }

}


const mapStateToProps = state => {
    return {
        userTokenExpire: state.queue.userTokenExpire,
    };
};

export default connect(mapStateToProps)(UserTokenExpireNotification);