export const CHECK_AUTH = 'CHECK_AUTH';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_SCHOOL_NAME = 'SET_SCHOOL_NAME';
export const SHOW_MFA_SCREEN = "SHOW_MFA_SCREEN";

export const CHECK_NEAR_SCHOOL_START = 'CHECK_NEAR_SCHOOL_START';
export const CHECK_NEAR_SCHOOL_SUCCESS = 'CHECK_NEAR_SCHOOL_SUCCESS';
export const CHECK_NEAR_SCHOOL_FAIL = 'CHECK_NEAR_SCHOOL_FAIL';

export const FORCE_CHANGE_PASSWORD_DONE = "FORCE_CHANGE_PASSWORD_DONE";

export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';

export const AGREE_TERMS_SUCCESS = 'AGREE_TERMS_SUCCESS';
export const AGREE_TERMS_ERROR = 'AGREE_TERMS_ERROR';

export const GET_QUEUE = 'GET_QUEUE';
export const GET_QUEUE_START = 'GET_QUEUE_START';
export const GET_QUEUE_SUCCESS = 'GET_QUEUE_SUCCESS';
export const GET_QUEUE_FAIL = 'GET_QUEUE_FAIL';
export const SET_ONLINE = 'SET_ONLINE';

export const REMOVE_STUDENT_START = 'REMOVE_STUDENT_START';
export const REMOVE_STUDENT_SUCCESS = 'REMOVE_STUDENT_SUCCESS';
export const REMOVE_WAITING_STUDENT_SUCCESS = "REMOVE_WAITING_STUDENT_SUCCESS";
export const REMOVE_STUDENT_FAIL = 'REMOVE_STUDENT_FAIL';

export const CHANGE_DETAIL_STUDENT = 'CHANGE_DETAIL_STUDENT';
export const CHANGE_DETAIL_CHECKPOINT = 'CHANGE_DETAIL_CHECKPOINT';
export const CHANGE_SHOW_TEMP_AUTH = 'CHANGE_SHOW_EMAIL';
export const CHANGE_SHOW_SEARCH_STUDENT= 'CHANGE_SHOW_SEARCH_STUDENT';
export const CHANGE_SHOW_CALL_STUDENT= 'CHANGE_SHOW_CALL_STUDENT';
export const CHANGE_DETAIL_VEHICLES = 'CHANGE_DETAIL_VEHICLES';
export const CHANGE_VEHICLE_DETAIL_USER = 'CHANGE_VEHICLE_DETAIL_USER';
export const CHANGE_GUARDIAN_CALL_STUDENT = 'CHANGE_GUARDIAN_CALL_STUDENT';

export const REMOVE_AUTH_START = 'REMOVE_AUTH_START';
export const REMOVE_AUTH_SUCCESS = 'REMOVE_AUTH_SUCCESS';
export const REMOVE_AUTH_FAIL = 'REMOVE_AUTH_FAIL';

export const SEND_MESSAGE_START = 'SEND_MESSAGE_START';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL';
export const SEND_MESSAGE_CLEAN = 'SEND_MESSAGE_CLEAN';

export const EMPTY_AUTH_TEMPS = "EMPTY_AUTH_TEMPS";
export const CLEAN_AUTH_NO_READ = 'CLEAN_AUTH_NO_READ';
export const CLEAN_STUDENTS_AUTH_LOGOUT = 'CLEAN_STUDENTS_AUTH_LOGOUT';

export const SHOW_FOUND_STUDENTS = 'SHOW_FOUND_STUDENTS';
export const SHOW_NOT_FOUND_STUDENTS = 'SHOW_NOT_FOUND_STUDENTS';
export const FILTER_QUEUE = 'FILTER_QUEUE';

export const GET_VEHICLE_START = 'GET_VEHICLE_START';
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';
export const GET_VEHICLE_FAIL = 'GET_VEHICLE_FAIL';
export const UPDATE_VEHICLE_START = 'UPDATE_VEHICLE_START';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAIL = 'UPDATE_VEHICLE_FAIL';
export const ADD_NEW_VEHICLE_START = 'ADD_NEW_VEHICLE_START';
export const ADD_NEW_VEHICLE_SUCCESS = 'ADD_NEW_VEHICLE_SUCCESS';
export const ADD_NEW_VEHICLE_FAIL = 'ADD_NEW_VEHICLE_FAIL';

export const CHECKPOINT_SUCCESS = 'CHECKPOINT_SUCCESS';
export const CHECKPOINT_FAIL = 'CHECKPOINT_FAIL';

export const REMOVE_EMERGENCY_BUTTON = "REMOVE_EMERGENCY_BUTTON";

export const SHOW_FOUND_GUARDIANS_CALL_STUDENTS = 'SHOW_FOUND_GUARDIANS_CALL_STUDENTS';
export const SHOW_NOT_FOUND_GUARDIANS_CALL_STUDENTS = 'SHOW_NOT_FOUND_GUARDIANS_CALL_STUDENTS';
export const EMPTY_GUARDIANS_CALL_STUDENTS = 'EMPTY_GUARDIANS_CALL_STUDENTS';
export const EMPTY_SEARCH_STUDENTS = 'EMPTY_SEARCH_STUDENTS';
export const CLEAN_SEARCH_STUDENT_INPUT = 'CLEAN_SEARCH_STUDENT_INPUT';
export const UPDATE_SUCCESS_CALLED_STUDENTS = 'UPDATE_SUCCESS_CALLED_STUDENTS';
export const UPDATE_ERROR_CALLED_STUDENTS = 'UPDATE_ERROR_CALLED_STUDENTS';
export const UPDATE_SUCCESS_CALLED_STUDENT = 'UPDATE_SUCCESS_CALLED_STUDENT';
export const UPDATE_ERROR_CALLED_STUDENT = 'UPDATE_ERROR_CALLED_STUDENT';
export const CLEAN_CALLED_STUDENTS = 'CLEAN_CALLED_STUDENTS';
export const CHANGE_SIGNAL_REMOVE_STUDENT = "CHANGE_SIGNAL_REMOVE_STUDENT";