import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../store/actions/index';

import { Form } from 'reactstrap';
import { MdSearch } from 'react-icons/lib/md';
import BaseURL from '../utils/BaseURL'
import Strings from '../strings/Strings'

class SearchCallStudent extends Component {
  state = {
    query: '',
    cleanSearch: false,
  }

  handleInputChange = () => {
    this.setState({
      query: this.search.value
    })

    // if (new BaseURL().isTVInspector()) {
    //   let cardsTitle = document.querySelectorAll(".studentTV");
    //   let cardsGrade = document.querySelectorAll(".lblGradeTV");
    //   let countCards = 0;

    //   if(cardsTitle.length > 0){
    //     //cardsTitle.forEach((cardTitle) => {
    //     [...cardsTitle].forEach((cardTitle) => {
    //       let grade = cardsGrade[countCards].innerHTML;
    //       if(cardTitle.innerHTML.toLowerCase().indexOf(this.search.value.toLowerCase()) === -1
    //         && grade.toLowerCase().indexOf(this.search.value.toLowerCase()) === -1){
    //         let card = cardTitle.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
    //         let classCard = card.getAttribute("class");
    //         classCard = classCard.replace("showCard", "hiddenCard");

    //         card.setAttribute("class", classCard);
    //       }else{
    //         let card = cardTitle.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
    //         let classCard = card.getAttribute("class");
    //         classCard = classCard.replace("hiddenCard", "showCard");

    //         card.setAttribute("class", classCard);
    //       }

    //       countCards++;
    //     });
    //   }
    // }else{
      let searchValue = this.search.value;
      if(searchValue.length >= 3){
        this.props.searchCallStudent(this.props.schoolName, this.props.token, searchValue);
      }else{
        this.props.emptyCallStudent(this.props.schoolName, this.props.token, searchValue);
      }
    // }
  }
 
  render() {
    if(this.search){
      if(this.props.cleanSearch == true && this.search.value != ""){
        this.search.value = "";
        this.props.setCleanSearch();
      }
    }else{
      if(this.props.cleanSearch == true){
        this.props.setCleanSearch();
      }
    }

    return (
      <Form inline className="cr-search-form" onSubmit={e => e.preventDefault()} style={{"width":"100%"}}>
      <MdSearch
        size="20"
        className="cr-search-form__icon-search text-secondary"
      />
      <input
        type="search"
        className="cr-search-form__input round-input"
        placeholder={Strings.search.searchStudentOrGuardian}
        ref={input => this.search = input}
        onChange={this.handleInputChange}
        style={{"width":"100%"}}
      />  
    </Form>
    )
  }
}

const mapStateToProps = state => {
  return {
      schoolName: state.auth.schoolName,
      token: state.auth.token,
      cleanSearch: state.searchCallStudent.cleanSearch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchCallStudent: (schoolName, token, string) => dispatch(actions.searchCallStudent(schoolName, token, string)),
    emptyCallStudent: (schoolName, token, string) => dispatch(actions.emptyCallStudent(schoolName, token, string)),
    setCleanSearch: () => dispatch(actions.setCleanSearch()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCallStudent)