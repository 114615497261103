import React, { Component } from 'react'

import { Form } from 'reactstrap';
import { MdSearch } from 'react-icons/lib/md';
import Strings from '../strings/Strings';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

class TextSearch extends Component {
  // state = {
  //   query: '',
  // }

  handleInputChange = (e) => {

    this.props.filterQueue(e.target.value);    
    /*
    this.setState({
      query: this.search.value
    })

      let cardsTitle = document.querySelectorAll(".studentInfo");
      let cardsGrade = document.querySelectorAll(".gradeInfo");
      let cardsGuardian = document.querySelectorAll(".guardianInfo");
      let cardsPlate = document.querySelectorAll(".plateInfo");

      let cardsGradeText = [];
      let cardsGuardianText = [];
      let cardsPlateText = [];

      if(cardsGrade.length > 0){
        [...cardsGrade].forEach(function (cardGrade) {
          console.log(cardGrade.innerHTML);
          if(cardGrade.innerHTML.indexOf("<svg") === -1){
            cardsGradeText.push(cardGrade);
          }
        });
      }

      if(cardsGuardian.length > 0){
        [...cardsGuardian].forEach(function (cardGuardian) {
          console.log(cardGuardian.innerHTML);
          if(cardGuardian.innerHTML.indexOf("<svg") === -1){
            cardsGuardianText.push(cardGuardian);
          }
        });
      }

      if(cardsPlate.length > 0){
        [...cardsPlate].forEach(function (cardPlate) {
          console.log(cardPlate.innerHTML);
          if(cardPlate.innerHTML.indexOf("<svg") === -1){
            cardsPlateText.push(cardPlate);
          }
        });
      }

      let countCards = 0;

      if(cardsTitle.length > 0){
        //cardsTitle.forEach((cardTitle) => {
        [...cardsTitle].forEach((cardTitle) => {
          let grade = cardsGradeText[countCards].innerHTML;
          let guardian = "";
          if(typeof cardsGuardianText[countCards] != "undefined"){
            guardian = cardsGuardianText[countCards].innerHTML;
          }
          let plate = "";
          if(typeof cardsPlateText[countCards] != "undefined"){
            plate = cardsPlateText[countCards].innerHTML;
          }
          console.log(grade);
          console.log("cardTitle = "+cardTitle.innerHTML.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

          let normalizeCardTitle = cardTitle.innerHTML.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          let normalizeGrade = grade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          let normalizeGuardian = guardian.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          let normalizePlate = plate.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          let normalizeSearch = this.search.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

          if(normalizeCardTitle.indexOf(normalizeSearch) === -1
            && normalizeGrade.indexOf(normalizeSearch) === -1
            && normalizeGuardian.indexOf(normalizeSearch) === -1
            && normalizePlate.indexOf(normalizeSearch) === -1){
            let card = cardTitle.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
            console.log(card);
            let classCard = card.getAttribute("class");
            classCard = classCard.replace("showCard", "hiddenCard");

            card.setAttribute("class", classCard);
          }else{
            let card = cardTitle.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
            let classCard = card.getAttribute("class");
            classCard = classCard.replace("hiddenCard", "showCard");

            card.setAttribute("class", classCard);
          }

          countCards++;
        });
      }
      */
  }
 
  render() {
    return (
      <Form inline className="cr-search-form" onSubmit={e => e.preventDefault()} style={{"width":"100%"}}>
      <MdSearch
        size="20"
        className="cr-search-form__icon-search text-secondary"
      />
      <input
        type="search"
        className="cr-search-form__input round-input"
        placeholder={Strings.placeholder.search}
        ref={input => this.search = input}
        onChange={this.handleInputChange}
        style={{"width":"100%"}}
      />
    </Form>
    )
  }
}

const mapStateToProps = state => {
  return {    
    studentTextSearch: state.queue.studentTextSearch,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    filterQueue: (text) =>
      dispatch(actions.filterQueue(text))
  };
};  

export default connect(mapStateToProps, mapDispatchToProps)(TextSearch);