import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
    // students: null,
    checkpoint_date: "",
    studentCheckpoint: 0,
};

const updateCheckpointCard = (state, action) => {
    return updateObject(state, {checkpoint: action.datainfo.info.checkpoint,
                                checkpoint_date: action.datainfo.info.checkpoint.checkpoint_date,
                                studentCheckpoint: action.datainfo.info.checkpoint.student_id});
}

const notUpdateCheckpointCard = (state, action) => {
    let updateInfo = {};
    updateInfo['checkpoint'] = null;
    //return updateObject(state, updateInfo);
    return updateObject(state, {checkpoint: null,
                                checkpointID: null});
}

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CHECKPOINT_SUCCESS: return updateCheckpointCard(state, action);
        case actionTypes.CHECKPOINT_FAIL: return notUpdateCheckpointCard(state, action);
        default:
            return state;
    }
};

export default reducer;