import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';
import ConnectionConstants from '../../constants/ConnectionConstants'

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
    vehicles: [], 
    connectionStatus: -1,
    codeError: 0,
    connectionStatusUpdate: -1,
    codeErrorUpdate: 0,
    connectionStatusAddNew: -1,
    codeErrorAddNew: 0,
};


const getVehicleStart = (state, action) => {
    let updateInfo = {};
    updateInfo['vehicles'] = [];
    updateInfo['codeError'] = 0;
    updateInfo['connectionStatus'] = ConnectionConstants.LOADING;
    
    return updateObject(state, updateInfo);
}

const getVehicleFail = (state, action) => {
    let updateInfo = {};
    updateInfo['vehicles'] = [];
    updateInfo['codeError'] = action.codeError;
    updateInfo['connectionStatus'] = ConnectionConstants.FAIL;
    
    return updateObject(state, updateInfo);
}

const getVehicleSuccess = (state, action) => {
    let updateInfo = {};
    updateInfo['vehicles'] = action.vehicles;
    updateInfo['codeError'] = 1;
    updateInfo['connectionStatus'] = ConnectionConstants.SUCCESS;

    return updateObject(state, updateInfo);
}

const updateVehicleStart = (state, action) => {
    let updateInfo = {};
    updateInfo['codeErrorUpdate'] = 0;
    updateInfo['connectionStatusUpdate'] = ConnectionConstants.LOADING;

    return updateObject(state, updateInfo);
}

const updateVehicleFail = (state, action) => {
    let updateInfo = {};
    updateInfo['codeErrorUpdate'] = action.errorCode;
    updateInfo['connectionStatusUpdate'] = ConnectionConstants.FAIL;

    return updateObject(state, updateInfo);
}

const updateVehicleSuccess = (state, action) => {
    let updateInfo = {};
    updateInfo['codeErrorUpdate'] = 1;
    updateInfo['connectionStatusUpdate'] = ConnectionConstants.SUCCESS;    
    return updateObject(state, updateInfo);
}

const addNewVehicleStart = (state, action) => {
    let updateInfo = {};
    updateInfo['codeErrorAddNew'] = 0;
    updateInfo['connectionStatusAddNew'] = ConnectionConstants.LOADING;

    return updateObject(state, updateInfo);
}

const addNewVehicleFail = (state, action) => {
    let updateInfo = {};
    updateInfo['codeErrorAddNew'] = action.errorCode;
    updateInfo['connectionStatusAddNew'] = ConnectionConstants.FAIL;

    return updateObject(state, updateInfo);
}

const addNewVehicleSuccess = (state, action) => {
    let updateInfo = {};
    updateInfo['codeErrorAddNew'] = 1;
    updateInfo['connectionStatusAddNew'] = ConnectionConstants.SUCCESS;
    return updateObject(state, updateInfo);
}


/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_VEHICLE_START: return getVehicleStart(state, action);
        case actionTypes.GET_VEHICLE_SUCCESS: return getVehicleSuccess(state, action);
        case actionTypes.GET_VEHICLE_FAIL: return getVehicleFail(state, action);
        case actionTypes.UPDATE_VEHICLE_START: return updateVehicleStart(state, action);
        case actionTypes.UPDATE_VEHICLE_SUCCESS: return updateVehicleSuccess(state, action);
        case actionTypes.UPDATE_VEHICLE_FAIL: return updateVehicleFail(state, action);
        case actionTypes.ADD_NEW_VEHICLE_START: return addNewVehicleStart(state, action);
        case actionTypes.ADD_NEW_VEHICLE_SUCCESS: return addNewVehicleSuccess(state, action);
        case actionTypes.ADD_NEW_VEHICLE_FAIL: return addNewVehicleFail(state, action);
        default:
            return state;
    }
};

export default reducer;