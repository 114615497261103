import React from 'react';

import {
    Row,
    Col,
    Container,
    Card,
    CardImg,
    CardBody,
    InputGroupText,
    Button,
    Alert,    
    FormGroup,
} from 'reactstrap';
import Spinner from '../../componentsUI/Layout/Spinner/Spinner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import renderIf from 'render-if';

import { AvForm, AvFeedback, AvInput, AvGroup } from 'availity-reactstrap-validation';

//Strings
import Strings from '../../strings/Strings';

import { } from './LoginView.css'
import ChangeLanguageController from '../../components/ChangeLanguage/ChangeLanguageController';
import CookieConsent from "react-cookie-consent";


const LoginView = (props) => {

    let button = "";

    if(props.showLoginButton){
        button = <Button className="btnLogin" outline color="info">{Strings.send}</Button>;
    }

    let btnChangeLanguage = (<div className="linkChangeButton" onClick={props.openChangeLanguage}
                                        >{Strings.changePass.title}</div>);
    let btnForgetPassword = (<div className="linkChangeButton" onClick={props.forgetPassword}
                                        >{Strings.changePass.forgetPassword}</div>);
    let btnResendCode = (
      <div className="linkChangeButton" onClick={props.resendMFACode}>
        {Strings.login.login_mfa_text_resend_button}
      </div>
    );                                        
    let btnCancelCode = (
      <div className="linkChangeButton" onClick={props.onCancelMFAButton}>
        {Strings.login.btnCancelChangePassword}
      </div>
    );                                        
    if(props.loading){
        button= (
            <Spinner color="#fff" />
        )
        btnChangeLanguage = null;
        btnForgetPassword = null;
        btnResendCode = null;
        btnCancelCode = null;
    }

    if (!props.showMFAScreen) {
      btnResendCode = null;
      btnCancelCode = null;
    } else {
      btnChangeLanguage = null;
      btnForgetPassword = null;
    }

    let error = null;
    if(props.error !== ""){
        error = ( 
            <Alert color="danger">
                {props.error}
            </Alert> 
        );
    }

    if(props.emptyEmail){
        error = ( 
            <Alert color="danger">
                {Strings.changePass.emptyEmail}
            </Alert> 
        );
    }

    if(props.forgetPasswordSuccess){
        error = ( 
            <Alert color="success">
                {Strings.changePass.forgetPasswordSuccess}
            </Alert> 
        );
    }

    let locationMessage = null;
    if(props.locationMessage !== ""){
        locationMessage = <Alert color="light">
            {props.locationMessage}
        </Alert> ;
    }

    let loginMFAText = Strings.login.login_mfa_text;
    if (!props.sent_via_sms) {
      loginMFAText = loginMFAText.replace(
        "SMS",
        Strings.login.login_mfa_text_email
      );
    }


    let image = "/img/logo/school_guardian_azul_vertical.png";
    return (
      <Container fluid className="continerLogin">
        <CookieConsent
          location="top"
          buttonText={Strings.cookies.btn}
          cookieName="cookiesAccept"
          style={{
            background: "#f5f5f5",
            zIndex: "9999",
            fontSize: "12px",
            color: "#000",
          }}
          buttonStyle={{
            background: "#1fae16",
            fontSize: "12px",
            color: "#fff",
          }}
          expires={150}
          overlay="true"
          onAccept={() => {
            props.onChangeShowLoginButton(true);
          }}>
          {Strings.cookies.text}{" "}
          <a
            href={Strings.cookies.manifestLink}
            target="_blank"
            style={{ color: "#9B1A1A" }}>
            {Strings.cookies.manifest}
          </a>
        </CookieConsent>
        <Row className="align-items-center" style={{ height: "100%" }}>
          <Col
            xl={{ size: 4, offset: 4 }}
            lg={{ size: 6, offset: 3 }}
            md={{ size: 6, offset: 3 }}
            sm={{ size: 6, offset: 3 }}
            className="align-self-center vcenter">
            <Card className="border-0 box_login">
              <CardImg
                top
                src={image}
                style={{ width: "50%", margin: "20px auto" }}
              />
              <CardBody>
                <AvForm className="hidden" onValidSubmit={props.onValidSubmit}>
                  {renderIf(!props.showMFAScreen)(
                    <>
                      <AvGroup className="input-group">
                        <InputGroupText addonType="prepend">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroupText>
                        <AvInput
                          name="email"
                          id="email"
                          minLength={3}
                          placeholder={Strings.login.email}
                          required
                        />
                        <AvFeedback>{Strings.login.emailError}</AvFeedback>
                      </AvGroup>
                      <AvGroup className="input-group">
                        <InputGroupText addonType="prepend">
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroupText>
                        <AvInput
                          type="password"
                          name="password"
                          minLength={4}
                          placeholder={Strings.login.password}
                          required
                        />
                        <AvFeedback>{Strings.login.passwordError}</AvFeedback>
                      </AvGroup>
                    </>
                  )}
                  {renderIf(props.showMFAScreen)(
                    <>
                      <div className="mfaInfoText">
                        {loginMFAText}
                      </div>
                      <AvGroup className="input-group">
                        <InputGroupText addonType="prepend">
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroupText>
                        <AvInput
                          type="text"
                          name="code"
                          minLength={4}
                          placeholder={Strings.login.login_mfa_text_code_title}
                          required
                        />
                        <AvFeedback>
                          {Strings.login.login_mfa_code_fill_error}
                        </AvFeedback>
                      </AvGroup>
                    </>
                  )}
                  <FormGroup style={{ textAlign: "center" }}>
                    {button}
                  </FormGroup>
                </AvForm>
                {error}
                {locationMessage}
                {btnResendCode}
                {btnCancelCode}
                {btnChangeLanguage}
                {btnForgetPassword}
                <ChangeLanguageController
                  isOpen={props.showChangeLanguage}
                  onChooseLanguage={props.onChooseLanguage}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    );
}


export default LoginView;