import React from 'react';

import StudentInfoDetailController from '../../components/StudentInfo/StudentInfoDetail/StudentInfoDetailController';

class DetailQueueController extends React.Component {


    render() {
        return (
            <StudentInfoDetailController/>
        )
    }

};

export default DetailQueueController;
