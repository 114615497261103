import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import StudentInfoDetailView from './StudentInfoDetailView.js'
import Strings from '../../../strings/Strings'

class StudentInfoDetailController extends React.Component {

    state = {
        showSendMessageBox: false,
        sendMessageText: '',
        sendErrorMessage: ''
    };

    componentDidUpdate = (prevProps, prevState) =>{        
        if (this.props.codeReturn !== prevProps.codeReturn && 
            this.props.messageReturn !== prevProps.messageReturn){
                this.setState({
                    sendErrorMessage: this.props.messageReturn,
                })
        }

        if(this.props.codeReturn !== prevProps.codeReturn && this.props.codeReturn === 1){
            setTimeout(() => {
                this.setState({
                    showSendMessageBox:false,
                    sendErrorMessage: '',
                });
            }, 1000);
        }

        if (this.props.showDetailStudent.id !== prevProps.showDetailStudent.id){
            this.setState({
                showSendMessageBox: false,
                sendErrorMessage: ''
            })
            this.props.cleanPreviousSend();
        }
    }
    /**
     * Handle Open/Close Sidebar with Student Info
     */
    handleSidebarControlButton = event => {
        event.preventDefault();
        event.stopPropagation();

        document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    };

    /**
     * On Valid Submit in Form
     */
    onShowSendMessageBox = () => {
        //Send Auth to Redux
        this.setState({
            showSendMessageBox: true,
        })
    }

    onSendMessageChangeValue = (event) =>{
        this.setState({ sendMessageText: event.target.value });
    }

    onSendNewMessage = () => {
        if (this.state.sendMessageText === ""){
            this.props.cleanPreviousSend();
            setTimeout(() => {
                this.setState({
                    sendErrorMessage: Strings.sendMessage.messageEmptyError
                });
            }, 500);
                       
        } else {
            this.props.sendMessage(this.props.schoolName, this.props.token,
                this.props.showDetailStudent, this.state.sendMessageText);
            this.setState({
                sendErrorMessage: '',
                sendMessageText: ''
            });
        }
        
    }

    render() {
        return (
            <StudentInfoDetailView student={this.props.showDetailStudent}
                onHandleSidebarControlButton={this.handleSidebarControlButton}
                currentDetailInfo={this.props.currentDetailInfo}
                showSendMessageBox={this.state.showSendMessageBox}

                onShowSendMessageBox={this.onShowSendMessageBox}
                onSendNewMessage={this.onSendNewMessage}
                onSendMessageChangeValue={this.onSendMessageChangeValue}
                sendMessageText={this.state.sendMessageText}
                sendErrorMessage={this.state.sendErrorMessage}
                isSending={this.props.isSending}
                messageReturn={this.props.messageReturn}
                codeReturn={this.props.codeReturn}/>
        )
    }

};

const mapStateToProps = state => {
    return {
        schoolName: state.auth.schoolName,
        token: state.auth.token,
        showDetailStudent: state.screenDetailChange.showDetailStudent,
        currentDetailInfo: state.screenDetailChange.currentDetailInfo,
        isSending: state.sendMessage.isSending,
        messageReturn: state.sendMessage.messageReturn,
        codeReturn: state.sendMessage.codeReturn,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendMessage: (schoolName, token, student, message) => dispatch(actions.sendMessage(schoolName, token, student, message)),
        cleanPreviousSend: () => dispatch(actions.cleanPreviousSend())
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(StudentInfoDetailController);
