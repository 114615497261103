import React from 'react';

// import { } from './ShowTempAuthView.css'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  InputGroupText,
  FormGroup,
  FormFeedback,
  Spinner,
} from "reactstrap";
import Strings from '../../../strings/Strings'

import renderIf from "render-if";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';

const ForceChangePasswordView = (props) => {
    let notMatchError = "";
    if(props.connectionMsg !== ""){
      notMatchError = (
        <div
          style={{
            color: props.isConnectionError?"red":"green",
            fontSize: "14px",
            marginBottom: "10px",
            marginTop: "-10px",
          }}>
          {props.connectionMsg}
        </div>
      );      
    }
    

    let isOpen = parseInt(props.isOpenForceChange);
    let btnCancel = "";

    if(!isOpen && props.openModalChangePassword){
        isOpen = parseInt(props.openModalChangePassword);
        btnCancel = <Button color="info" className="buttonChangePassword" onClick={props.onCancel} style={{marginLeft:"5px"}}>{Strings.login.btnCancelChangePassword}</Button>;
    }

    return (
      <Modal isOpen={isOpen === 1}>
        <ModalHeader>{Strings.login.changePasswordTitle}</ModalHeader>
        <ModalBody className="modalBodyChangeLanguage">
          <AvForm className="hidden" onValidSubmit={props.onValidSubmit}>
            <AvGroup className="input-group">
              <InputGroupText addonType="prepend">
                <FontAwesomeIcon icon={faUnlockAlt} />
              </InputGroupText>
              <AvInput
                type="password"
                name="previousPassword"
                errorMessage="Invalid name"
                placeholder={Strings.login.previousPassword}
                onChange={(event, values) =>
                  props.onCheckInfo(props.inputField.PreviousPassword, values)
                }
                invalid={props.previousPasswordMsgError !== ""}
              />
              <FormFeedback>{props.previousPasswordMsgError}</FormFeedback>
            </AvGroup>
            <AvGroup className="input-group">
              <InputGroupText addonType="prepend">
                <FontAwesomeIcon icon={faUnlockAlt} />
              </InputGroupText>
              <AvInput
                type="password"
                name="password"
                placeholder={Strings.login.password}
                onChange={(event, values) =>
                  props.onCheckInfo(props.inputField.NewPassword, values)
                }
                invalid={props.newPasswordMsgError !== ""}
              />
              <FormFeedback>{props.newPasswordMsgError}</FormFeedback>
            </AvGroup>
            <AvGroup className="input-group">
              <InputGroupText addonType="prepend">
                <FontAwesomeIcon icon={faUnlockAlt} />
              </InputGroupText>
              <AvInput
                type="password"
                name="confirmPassword"
                placeholder={Strings.login.confirmPassword}
                onChange={(event, values) =>
                  props.onCheckInfo(props.inputField.ConfirmPassword, values)
                }
                invalid={props.confirmPasswordMsgError !== ""}
              />
              <FormFeedback>{props.confirmPasswordMsgError}</FormFeedback>
            </AvGroup>
            {notMatchError}
            {renderIf(!props.isLoading)(
              <FormGroup style={{ textAlign: "center" }}>
                <Button color="info" className="buttonChangePassword">
                  {Strings.login.btnChangePassword}
                </Button>
                {btnCancel}
              </FormGroup>
            )}
            {renderIf(props.isLoading)(
              <div style={{ textAlign: "center" }}>
                <Spinner color="primary"> </Spinner>
              </div>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    );
}

export default ForceChangePasswordView;
