import React from 'react';
import { connect } from 'react-redux';

import {
    Label
} from 'reactstrap';

import { isLink } from '../../utils/isLink'
import Strings from '../../strings/Strings'
import { } from './EmergencyNotification.css'

import Aux from '../../hoc/ReactAux/ReactAux'
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

class EmergencyNotification extends React.Component {

    componentDidUpdate= () => {

        this.props.studentsInEmergencyToNotify.map( student => {
            let image = 'img/silhouetteStudent.png'
            if (isLink(student.image)) {
                image = student.image;
            }
            let title = <img
                src={image}
                width="auto"
                height="auto"
                className="imgEmergencyDialog"
                alt=""
            />

            const guardianCaller = student.guardians.filter((guardian) => {
                //for each student in removedStudents array
                if (guardian.id === student.guardianCallID) {
                    return true;
                }
                return false;
            });

            let guardianName = "";
            if(guardianCaller.length > 0){
                guardianName = guardianCaller[0].name;
            }

            const body = (
                <Aux className="emergencyDialog">
                    <Label className="emergencyDialogTitle">
                                {Strings.emergencyPopup.title}</Label>
                    <Label className="emergencyDialogText">
                                {student.name}</Label>
                    <Label className="emergencyDialogText">
                                {Strings.emergencyPopup.grade + ": " +student.grade}</Label>
                    <Label className="emergencyDialogText">
                                {Strings.emergencyPopup.requirer + ": " + guardianName}</Label>
                </Aux>
            )
            setTimeout(() => {
                this.notificationSystem.addNotification({
                    title: title,
                    message: body,
                    level: 'error',
                    autoDismiss: 10
                });
            }, 500);

            return true;
        });
        
    }
    render () {

        return (
            <NotificationSystem
                dismissible={false}
                ref={notificationSystem =>
                    (this.notificationSystem = notificationSystem)
                }
                style={NOTIFICATION_SYSTEM_STYLE}
            />
        )
    }

}


const mapStateToProps = state => {
    return {
        studentsInEmergencyToNotify: state.queue.studentsInEmergencyToNotify,
    };
};




export default connect(mapStateToProps)(EmergencyNotification);