import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';
import Strings from '../../strings/Strings'
import ConnectionConstants from '../../constants/ConnectionConstants'
import BaseURL from '../../utils/BaseURL'

// Load the full build.
var _ = require('lodash');

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
  students: null,
  studentsFiltered: null,
  authTemps: null,
  lastUpdate: "",
  lastEmailID: 0,
  lastRemovedID: -1,
  cardStudentRemoving: [],
  waitStudentToRemove: [],
  hasNewStudent: false,
  studentsInEmergencyToNotify: [],
  failMessageInfo: "",
  statusQueueConnection: ConnectionConstants.SUCCESS,
  cardAuthRemoving: [],
  userTokenExpire: "",
  amountAuthsNoRead: 0,
  studentIDsLastRead: [],
  studentTextSearch: "",
  emergencyButtonSchool: [],
  emptyAuthTemps: false,
  changeSignalRemoveStudent: 0
};


const getQueueStart = (state, action) => {
    let updateInfo = {};
    updateInfo['statusQueueConnection'] = ConnectionConstants.LOADING;
    updateInfo['userTokenExpire'] = '';
    return updateObject(state, updateInfo);
}

const getQueueFail = (state, action) => {
    let updateInfo = {};
    updateInfo['statusQueueConnection'] = ConnectionConstants.FAIL;
    if (parseInt(action.errorCode,10) === -97){
        updateInfo['userTokenExpire'] = Strings.messageErrors.userTokenExpire + 
                                                            "{|}" + (new Date());
    } else if (parseInt(action.errorCode,10) === -2){
        updateInfo['userTokenExpire'] = Strings.login.newVersionAvailable + 
                                                            "{|}" + (new Date());
    }
    
    return updateObject(state, updateInfo);
}

const setOnline = (state, action) => {
    let updateInfo = {};
    updateInfo['statusQueueConnection'] = ConnectionConstants.SUCCESS;    
    return updateObject(state, updateInfo);
}

const getQueue = (state, action) => {

    let updateInfo = {};
    let isInspetorTV = new BaseURL().isTVInspector();
    let needToCheckUniqBy = false;
    updateInfo['hasNewStudent'] = false;

    //Set update Time from server
    // updateInfo['lastUpdate'] = "2018-07-22 16:51:19.775437";//action.info.updateDate.date;
    if (state.lastUpdate !== action.info.updateDate.date) {
        updateInfo['studentIDsLastRead'] = [];
    } else {
        updateInfo['studentIDsLastRead'] = [...state.studentIDsLastRead];
    }
    updateInfo['lastUpdate'] = action.info.updateDate.date;
    let fixDate = updateInfo['lastUpdate'].slice(0,19);

    let needToRebuildFilter = false;
    //Get AMount of students in Array and check if has more than one
    const studentsCount = action.info.students.length - 1;
    if (studentsCount >= 0) {

        needToRebuildFilter = true;
        let covidFiltered = false;
        if(document.querySelector(".activeCovidFilter") !== null){
            covidFiltered = true;
        }
        
        let newStudents = action.info.students;
        //Check if it if TV Inpector
        if (isInspetorTV){
            newStudents = action.info.students.filter(student => {
                if (student.emergency === 1) {
                    return false;
                }
                return true;
            })
        }

        if (state.students === null){

            // newStudents = action.info.students;

            if(covidFiltered){
                newStudents = newStudents.filter((student) => {
                  if (student.hasCovid == 0) {
                    student.hiddenCovid = true;
                  }
                  student.hiddenCovid = false;
                  return true;
                });
            }
                
            if(state.waitStudentToRemove.length > 0){
                newStudents = newStudents.filter((student) => {
                  if (state.waitStudentToRemove.includes(student.queueID)) {
                    return false;
                  }
                  return true;
                });                
            }
            //If there is not students in the previous array, insert them
            updateInfo['students'] = newStudents;
        } else {

            // newStudents = action.info.students;
            if(covidFiltered){
                newStudents = newStudents.filter((student) => {
                  if (student.hasCovid == 0) {
                    student.hiddenCovid = true;
                    return true;
                  }
                  student.hiddenCovid = false;
                  return true;
                });
            }

            if (state.waitStudentToRemove.length > 0) {
                newStudents = newStudents.filter((student) => {
                  if (state.waitStudentToRemove.includes(student.queueID)) {
                    return false;
                  }
                  return true;
                });
            }            
            //If there is student in the previous array, concat the new information
            updateInfo['students'] = [...state.students, ...newStudents];

            needToCheckUniqBy = true;
        }
        updateInfo['hasNewStudent'] = true;

        console.log("testing search in ipad");

        //Verify if newStudents object is not empty
        if(newStudents.length > 0){
            console.log("newStudents Length = "+newStudents.length);
            //For each new Student
            newStudents.forEach(student => {
                //If student has the same update date that the main last update date
                if (student.updateDate === fixDate) {
                    updateInfo['studentIDsLastRead'].push(student.id);
                }
            });
        }

        //Check if it is not inspector TV
        if (!isInspetorTV) {
            //Filiter to notify new Students in Emergency
            updateInfo['studentsInEmergencyToNotify'] = newStudents.filter(student => {
                if (student.emergency === 1){
                    return true;
                }
                return false;
            })
        }
    }

    if(action.info.studentsUpdated){

        needToRebuildFilter = true;
        //Get AMount of updated students in Array and check if has more than one
        const studentsUpdatedCount = action.info.studentsUpdated.length - 1;
        if (studentsUpdatedCount >= 0) {

            let studentsUpdated = action.info.studentsUpdated;
            //Check if it if TV Inpector
            if (isInspetorTV) {
                studentsUpdated = action.info.studentsUpdated.filter(student => {
                    if (student.emergency === 1) {
                        return false;
                    }
                    return true;
                })
            }

            let arrayStudent = null;
            //If there is a new Student in this request, check the new array
            if (updateInfo['students'] && updateInfo['students'].length > 0) {
                arrayStudent = updateInfo['students'];
            } else {
                //else, check the previous student array
                arrayStudent = [...state.students];
            }

            //Updating Students
            arrayStudent = arrayStudent.map(student => {
                for(let ind =0; ind < studentsUpdated.length; ind++){
                    if(studentsUpdated[ind].id === student.id){
                        student.vehicle = studentsUpdated[ind].vehicle;
                        student.plate = studentsUpdated[ind].plate;
                        student.isInsideSchool = studentsUpdated[ind].isInsideSchool;
                        student.barrier_id = studentsUpdated[ind].barrier_id;
                        student.barrier_identifier = studentsUpdated[ind].barrier_identifier;
                        student.barrier_card_color = studentsUpdated[ind].barrier_card_color;
                        student.barrier_card_border_color = studentsUpdated[ind].barrier_card_border_color;
                        student.card_color_qr_code_call = studentsUpdated[ind].card_color_qr_code_call;
                        student.card_border_color_qr_code_call = studentsUpdated[ind].card_border_color_qr_code_call;
                        student.callType = studentsUpdated[ind].callType;
                        student.inspectorQrCode = studentsUpdated[ind].inspectorQrCode;
                        student.updateDate = studentsUpdated[ind].updateDate;
                        student.vehicle = studentsUpdated[ind].vehicle;
                        student.vehicle_color = studentsUpdated[ind].vehicle_color;
                        student.plate = studentsUpdated[ind].plate;
                        student.vehicle_id = studentsUpdated[ind].vehicle_id;
                        student.checkpoints = studentsUpdated[ind].checkpoints;
                        student.checkpointName = studentsUpdated[ind].checkpointName;
                        student.prism_info = studentsUpdated[ind].prism_info;
                        return student;
                    }
                }
                return student;
            });

            //For each new Student
            action.info.studentsUpdated.forEach(student => {
                //If student has the same update date that the main last update date
                if (student.updateDate === fixDate) {
                    updateInfo['studentIDsLastRead'].push(student.id);
                }
            });

            updateInfo['students'] = arrayStudent;
            updateInfo['hasNewStudent'] = true;
        }
    }

    //Check if it is not inspector TV
    //if (!isInspetorTV) {
        //Get AMount of tempAuth in Array and check if has more than one
        const tempAuthCount = action.info.authTemps.length - 1;
        if (tempAuthCount >= 0) {

            //Add amount Auth No Read
            updateInfo['amountAuthsNoRead'] = state.amountAuthsNoRead + (tempAuthCount + 1);

            //Get the Email ID of last element
            updateInfo['lastEmailID'] = action.info.authTemps[tempAuthCount].id;

            if (state.authTemps === null) {
                //If there is not students in the previous array, insert them
                updateInfo['authTemps'] = action.info.authTemps;
            } else {
                //If there is student in the previous array, concat the new information
                updateInfo['authTemps'] = [...state.authTemps, ...action.info.authTemps];
            }

            updateInfo["authTemps"] = _.uniqBy(updateInfo["authTemps"], "id");
        }
    //}

    if (action.info.infoRemoved && action.info.infoRemoved.lastRemovedID) {
        //Get The last Queue ID removed
        updateInfo['lastRemovedID'] = action.info.infoRemoved.lastRemovedID;
    }

    if (action.info.emergency_button_info &&
      action.info.emergency_button_info.length > 0) {

        let newEmergencyButton = [... state.emergencyButtonSchool];
      //Get The last Emergency Button
      action.info.emergency_button_info.forEach((buttonInfoEmergency) => {
        // console.log(buttonInfoEmergency);
        newEmergencyButton.push(buttonInfoEmergency);
      });
      updateInfo["emergencyButtonSchool"] = newEmergencyButton;
    }

    //Get Amount of students removed in this request and check if has more than one
    const removeStudentsCount = action.info.removedStudents.length - 1;
    if (removeStudentsCount >= 0) {

        needToRebuildFilter = true;

        //If Current student state is null, you dont need to remove no one student
        if (state.students != null){

            let arrayStudent = null;
            //If there is a new Student in this request, check the new array
            if (updateInfo['students'] && updateInfo['students'].length > 0) {
                arrayStudent = updateInfo['students'];
            } else {
                //else, check the previous student array
                arrayStudent = state.students;
            }

            //Filter the current array to remove students with queueID in removedStudents array
            updateInfo['students'] = arrayStudent.filter((student) => {
                //for each student in removedStudents array
                for (let ind = 0; ind <= removeStudentsCount; ind++) {
                    //If equal, filter from array
                    if (student.queueID === action.info.removedStudents[ind].queueIDOriginal) {
                        return false;
                    }
                }
                return true;
            });
        }
    }

    //If has Student
    if(updateInfo['students']){

        if (needToCheckUniqBy){
            //Filter To avoid Student duplicated
            //updateInfo['students'] = _.uniqWith(updateInfo['students'], _.isEqual);
            updateInfo['students'] = _.uniqBy(updateInfo['students'], 'id');            
        }

        //Sorting to bring to front when the parent is in the school
        // updateInfo['students'] = updateInfo['students'].sort( function (a, b){
        //     if (a.isInsideSchool > b.isInsideSchool ){ return -1}
        //     if (a.isInsideSchool < b.isInsideSchool) { return 1 }
        //     return 0;
        // });

        updateInfo['students'] = _.orderBy(updateInfo['students'], ['isInsideSchool', 'updateDate', 'queueID'], ['desc', 'asc','asc']);
        // console.log("Update Info = ");
        // console.log(updateInfo['students']);
    }

    if (needToRebuildFilter){
        let filteredStudents = applyFilterStudents(state.studentTextSearch, updateInfo['students']);
        updateInfo['studentsFiltered'] = filteredStudents;
    }
    
    // console.log(updateInfo['students']);
    updateInfo['statusQueueConnection'] = ConnectionConstants.SUCCESS;
    return updateObject(state, updateInfo);
}


const removeStudentStart = (state, action) => {

    let updateInfo = {};
    updateInfo['cardStudentRemoving'] = [...state.cardStudentRemoving, action.student.queueID];
    updateInfo['hasNewStudent'] = false;
    return updateObject(state, updateInfo);
}

const removeStudentSuccess = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['students'] = state.students.filter((student) => {
        if (student.queueID !== action.student.queueID) {
            return true;
        }
        return false;
    });

    if (!action.allow_remove_students_instantly) {
        updateInfo["waitStudentToRemove"] = state.waitStudentToRemove;
      updateInfo["waitStudentToRemove"].push(action.student.queueID);
    }

    let filteredStudents = applyFilterStudents(state.studentTextSearch, updateInfo['students']);
    updateInfo['studentsFiltered'] = filteredStudents;

    //Filter the current array to remove student with queueID
    updateInfo['cardStudentRemoving'] = state.students.filter((student) => {
        if (student.queueID !== action.student.queueID) {
            return true;
        }
        return false;
    });
    return updateObject(state, updateInfo);
}

const removeWaitingStudentSuccess = (state, action) => {

    let updateInfo = {};
    updateInfo["waitStudentToRemove"] = state.waitStudentToRemove.filter((queueID) => {
        if (action.students.includes(queueID)) {
          return false;
        }
        return true;
      }
    );   
    return updateObject(state, updateInfo); 
}

const removeStudentFail = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['cardStudentRemoving'] = state.students.filter((student) => {
        if (student.queueID !== action.student.queueID) {
            return true;
        }
        return false;
    });

    updateInfo['failMessageInfo'] = 
            Strings.formatString(Strings.messageErrors.removeStudentError,action.student.name)+"{|}"+(new Date());
    return updateObject(state, updateInfo);
}

const removeAuthStart = (state, action) => {

    let updateInfo = {};
    updateInfo['cardAuthRemoving'] = [...state.cardAuthRemoving, action.tempAuth.id];
    return updateObject(state, updateInfo);
}

const emptyAuthTemps = (state, action) => {

    let updateInfo = {};
    updateInfo['authTemps'] = null;
    return updateObject(state, updateInfo);
}

const removeAuthSuccess = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['authTemps'] = state.authTemps.filter((tempAuth) => {
        if (tempAuth.id !== action.tempAuth.id) {
            return true;
        }
        return false;
    });
    //Filter the current array to remove student with queueID
    updateInfo['cardAuthRemoving'] = state.cardAuthRemoving.filter((tempAuthID) => {
        if (tempAuthID !== action.tempAuth.id) {
            return true;
        }
        return false;
    });
    return updateObject(state, updateInfo);
}

const removeAuthFail = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['cardAuthRemoving'] = state.cardAuthRemoving.filter((tempAuthID) => {
        if (tempAuthID !== action.tempAuth.id) {
            return true;
        }
        return false;
    });

    updateInfo['failMessageInfo'] =
        Strings.formatString(Strings.messageErrors.removeAuthError, action.tempAuth.title) + "{|}" + (new Date());
    return updateObject(state, updateInfo);
}

const cleanAuthNoRead = (state, action )=> {
    let updateInfo = {};

    updateInfo['amountAuthsNoRead'] = 0;
    return updateObject(state, updateInfo);
}

const cleanStudentsAuthLogout = (state, action )=> {

    return updateObject(state, { 
        students: [], 
        lastUpdate: "",
        lastEmailID: 0,
        lastRemovedID: -1,
        studentIDsLastRead: [],
        authTemps: null
    });
}

const setFilterQueue = (state, action )=> {

    let filteredStudents = applyFilterStudents(action.search, state.students);
    return updateObject(state, { 
        studentTextSearch: action.search,
        studentsFiltered: filteredStudents, 
        hasNewStudent: false,
    });
}

const removeEmergencyButton = (state, action) => {

   let newEmergencyButton = state.emergencyButtonSchool.filter(emergencyButton => {
        if(action.emergencyButton.id === emergencyButton.id){
            return false;
        }
        return true;
    });
    return updateObject(state, {
      emergencyButtonSchool: newEmergencyButton,
    });
};

const applyFilterStudents = (search, students) => {
    if (search === ""){
        return students;
    }
    let auxSearch = search.toLowerCase();
    console.log ("Before Apply Filters" );
    console.log(students);
    let newStudents = students.filter(student => {
        console.log(student);
        console.log(auxSearch);
        if (
          student.name.toLowerCase().indexOf(auxSearch) > -1 ||
          student.grade.toLowerCase().indexOf(auxSearch) > -1 ||
          student.plate.toLowerCase().indexOf(auxSearch) > -1 ||
          student.gate_name.toLowerCase().indexOf(auxSearch) > -1 ||
          student.guardianCallName.toLowerCase().indexOf(auxSearch) > -1 ||
          (student.checkpointName && student.checkpointName.toLowerCase().indexOf(auxSearch) > -1)
        ) {
          return true;
        }
        return false;
    });
    return newStudents;
}

const changeSignalRemoveStudent = (state, action) => {
  let updateInfo = {};
  updateInfo["changeSignalRemoveStudent"] = action.signalRemoveStudent;
  return updateObject(state, updateInfo);
};

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_QUEUE_START: return getQueueStart(state, action);
        case actionTypes.GET_QUEUE_SUCCESS: return getQueue(state, action);
        case actionTypes.GET_QUEUE_FAIL: return getQueueFail(state, action);
        case actionTypes.REMOVE_STUDENT_START: return removeStudentStart(state, action);
        case actionTypes.REMOVE_STUDENT_SUCCESS: return removeStudentSuccess(state, action);
        case actionTypes.REMOVE_WAITING_STUDENT_SUCCESS: return removeWaitingStudentSuccess(state, action);
        case actionTypes.REMOVE_STUDENT_FAIL: return removeStudentFail(state, action);
        case actionTypes.REMOVE_AUTH_START: return removeAuthStart(state, action);
        case actionTypes.REMOVE_AUTH_SUCCESS: return removeAuthSuccess(state, action);
        case actionTypes.REMOVE_AUTH_FAIL: return removeAuthFail(state, action);
        case actionTypes.CLEAN_AUTH_NO_READ: return cleanAuthNoRead(state, action);
        case actionTypes.SET_ONLINE: return setOnline(state, action);
        case actionTypes.FILTER_QUEUE: return setFilterQueue(state, action);
        case actionTypes.CLEAN_STUDENTS_AUTH_LOGOUT: return cleanStudentsAuthLogout(state, action);
        case actionTypes.REMOVE_EMERGENCY_BUTTON: return removeEmergencyButton(state, action);
        case actionTypes.EMPTY_AUTH_TEMPS: return emptyAuthTemps(state, action);
        case actionTypes.CHANGE_SIGNAL_REMOVE_STUDENT: return changeSignalRemoveStudent(state, action);
        default:
            return state;
    }
};

export default reducer;