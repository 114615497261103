import React, { Component } from 'react'

import { Form } from 'reactstrap';
import { MdSearch } from 'react-icons/lib/md';
import BaseURL from '../utils/BaseURL'
import Strings from '../strings/Strings'

class TempAuthSearch extends Component {
  state = {
    query: '',
  }

  handleInputChange = () => {
    this.setState({
      query: this.search.value
    })
    
      let cardsTitle = document.querySelectorAll(".studentInfoTempAuth");
      let cardsGrade = document.querySelectorAll(".gradeInfoTempAuth");
      let cardsGuardian = document.querySelectorAll(".guardianInfoTempAuth");

      console.log(cardsGrade);

      let cardsGradeText = [];
      if(cardsGrade.length > 0){
        [...cardsGrade].forEach(function (cardGrade) {
          console.log(cardGrade.innerHTML);
          if(cardGrade.innerHTML.indexOf("<svg") === -1){
            cardsGradeText.push(cardGrade);
          }
        });
      }

      let cardsGuardianText = [];
      if(cardsGuardian.length > 0){
        [...cardsGuardian].forEach(function (cardGuardian) {
          console.log(cardGuardian.innerHTML);
          cardsGuardianText.push(cardGuardian);
        });
      }

      let countCards = 0;

      if(cardsTitle.length > 0){
        //cardsTitle.forEach((cardTitle) => {
        [...cardsTitle].forEach((cardTitle) => {
          let grade = cardsGradeText[countCards].innerHTML;
          let guardian = cardsGuardianText[countCards].innerHTML;
          console.log(grade);
          console.log(cardTitle.innerHTML.toLowerCase());

          let normalizeCardTitle = cardTitle.innerHTML.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          let normalizeGrade = grade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          let normalizeGuardian = guardian.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          let normalizeSearch = this.search.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

          if(normalizeCardTitle.indexOf(normalizeSearch) === -1
            && normalizeGrade.indexOf(normalizeSearch) === -1
            && normalizeGuardian.indexOf(normalizeSearch) === -1){
            let card = cardTitle.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
            console.log(card);
            let classCard = card.getAttribute("class");
            classCard = classCard.replace("showCard", "hiddenCard");

            card.setAttribute("class", classCard);
          }else{
            let card = cardTitle.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
            let classCard = card.getAttribute("class");
            classCard = classCard.replace("hiddenCard", "showCard");

            card.setAttribute("class", classCard);
          }

          countCards++;
        });
      }
  }
 
  render() {
    return (
      <Form inline className="cr-search-form" onSubmit={e => e.preventDefault()} style={{"width":"100%"}}>
      <MdSearch
        size="20"
        className="cr-search-form__icon-search text-secondary"
      />
      <input
        type="search"
        className="cr-search-form__input round-input"
        placeholder={Strings.placeholder.searchTempAuth}
        ref={input => this.search = input}
        onChange={this.handleInputChange}
        style={{"width":"100%"}}
      />
    </Form>
    )
  }
}

export default TempAuthSearch