
import React from 'react';

import {
    Row,
    Col,
    Label
} from 'reactstrap';
import Spinner from '../../componentsUI/Layout/Spinner/Spinner'

const DetailQueueView = (props) => {

    // let button = null
    let textArea = null;

    let color = "red";
    if (props.codeReturn === 1){
        color = "green";
    }

    let label = <Label style={{ fontSize: '12px', color: color, fontWeight: '500'}}>{props.sendErrorMessage}</Label>;

    if (props.isSending){
        label = <Spinner className="loaderButtonCard" color="#47B24A" />
    } else {
        textArea = <textarea className="borderBlue" style={{ width: '100%', "paddingLeft": 0 }} maxLength="100"
            value={props.sendMessageText} onChange={props.onSendMessageChangeValue} />
        // button = <Button color="success" onClick={props.onSendNewMessage}>{Strings.sendMessage.send}</Button>
    }

    return (
        <div>
        <Row className="rowResp " >
            <Col md={12} style={{ "paddingLeft": 0 }}>
                {textArea}
                {label}
            </Col>
        </Row>
        </div>
    )
}


export default DetailQueueView;