import React from 'react';
import Sound from 'react-sound';
import BaseURL from '../../utils/BaseURL'
import PingSound from '../../assets/ping.mp3'
import is from 'is_js';


import {
    Row,
    Container,
} from 'reactstrap';

import { } from './QueueView.css'
import QueueItem from '../../components/QueueItem/QueueItem'
import QueueItemTV from '../../components/QueueItemTV/QueueItemTV'
import EmergencyNotification from '../../components/EmergencyNotification/EmergencyNotification'
import FailSendNotification from '../../components/FailSendNotification/FailSendNotification'
import UserTokenExpireNotification from '../../components/UserTokenExpireNotification/UserTokenExpireNotification'
import ChangeLanguageController from '../../components/ChangeLanguage/ChangeLanguageController'
import EmergencyAlert from '../../components/EmergencyAlert/EmergencyAlert'
import imageTemplate from '../../assets/silhouetteStudent.png'
import { faUser, faDungeon, faCar, faWalking, faCarSide, faSchool, faCheck, faPlus, faEnvelope, faSearch,
     faSignal, faSignalStream, faExclamationTriangle, faVirus, faQrcode, faBullseyePointer, faClipboardCheck, 
     faPen, faBus, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import AgreeTermsController from '../../components/Modals/AgreeTerms/AgreeTermsController';

const QueueView = (props) => {

    let queueItems = null;

    let hasSound = null;
    if (props.hasNewStudent){
        if (!is.safari()){
            console.log("PLAY SOUND");
            hasSound = <Sound
                url={PingSound}
                playStatus={Sound.status.PLAYING}
            />; 
        }
       
        if (parseInt(props.autoRool,10) === 1){
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 500);
            
        }
    }

    let emergencyButtonInfoPopup = null
    
    if (props.emergencyButtonSchool && props.emergencyButtonSchool.length > 0) {
        console.log("AQUI DENTRO  " + props.emergencyButtonSchool.length);
      emergencyButtonInfoPopup = (
        <EmergencyAlert
          emergencyButtonSchool={props.emergencyButtonSchool}
          onCloseEmergencyButton={props.onCloseEmergencyButton}
        />
      );
    }
    
    let isTVInspector = new BaseURL().isTVInspector();
    if(props.students){
        queueItems = props.students.map(student => {
            if (isTVInspector) {
                return (
                  <QueueItemTV
                    key={student.queueID}
                    student={student}
                    image_default={imageTemplate}
                    showOnlyNames={props.showOnlyNames}
                    hidden={student.hidden}
                    faDungeon={faDungeon}
                    faWalking={faWalking}
                    faCar={faCar}
                    faUser={faUser}
                    faCarSide={faCarSide}
                    faSchool={faSchool}
                    faCheck={faCheck}
                    faBus={faBus}
                    faPlus={faPlus}
                    faEnvelope={faEnvelope}
                    faSearch={faSearch}
                    faSignal={faSignal}
                    faExclamationTriangle={faExclamationTriangle}
                    faSignalStream={faSignalStream}
                    faVirus={faVirus}
                    faQrcode={faQrcode}
                    faInfoCircle={faInfoCircle}
                    card_color_walking={props.card_color_walking}
                    card_border_color_walking={props.card_border_color_walking}
                    card_color_driving={props.card_color_driving}
                    card_border_color_driving={props.card_border_color_driving}
                    isInsideSchool={student.isInsideSchool}
                  />
                );
            } else {
              console.log(student.queueID);
                return <QueueItem key={student.queueID} student={student} 
                    image_default={imageTemplate} faUser={faUser} faDungeon={faDungeon}
                    faWalking={faWalking} faCar={faCar} faBus={faBus}
                    faCarSide={faCarSide} faSchool={faSchool} faCheck={faCheck} 
                    faBullseyePointer={faBullseyePointer} faClipboardCheck={faClipboardCheck}
                    faPlus={faPlus} faEnvelope={faEnvelope} faSearch={faSearch} faSignal={faSignal}
                    faExclamationTriangle={faExclamationTriangle} faSignalStream={faSignalStream}
                    faVirus={faVirus} faQrcode={faQrcode} faPen={faPen} faInfoCircle={faInfoCircle}
                    card_color_walking={props.card_color_walking}
                    card_border_color_walking={props.card_border_color_walking}
                    card_color_driving={props.card_color_driving}
                    card_border_color_driving={props.card_border_color_driving}
                    isRemoving={props.cardStudentRemoving.indexOf(student.queueID) !== -1}
                    onHandleClickMoreInfoButton={(e) => { props.onHandleClickMoreInfoButton(e, student) }}
                    onHandleClickOKButton={props.onHandleClickOKButton.bind(this, student)} hidden={student.hidden}
                    onHandleClickCheckPointButton={props.onHandleClickCheckPointButton.bind(this, student)}
                    onHandleClickCheckListButton={(e) => { props.onHandleClickCheckListButton(e, student)}}
                    typeInspector={props.typeInspector}
                    allow_checkpoint={props.allow_checkpoint}
                    onHandleClickDetailVehicleButton={(e) => { props.onHandleClickDetailVehicleButton(e, student) }}
                    allow_add_car_inspector={props.allow_add_car_inspector}
                    loadingCheckpoint={props.loadingCheckpoint} />;
            }
        });
    }
    
    return (
      <Container fluid className="">
        {hasSound}
        <ChangeLanguageController />
        <EmergencyNotification />
        <FailSendNotification />
        <UserTokenExpireNotification />
        <Row className="marginLogin">{queueItems}</Row>
        <AgreeTermsController
          isOpen={props.showAgreeTerms}
          onAgreeTerms={props.onAgreeTerms}
        />
        {emergencyButtonInfoPopup}
      </Container>
    );
}


export default QueueView;