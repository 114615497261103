import React from 'react'
import { } from './CallAllStudentsButton.css'

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Strings from '../../strings/Strings'
import Spinner from '../../componentsUI/Layout/Spinner/Spinner'

class AuthItem extends React.Component {
//const AuthItem = (props) => {

    constructor(props) {
        super(props);

        this.state = { loadingCallAllStudents: props.isLoadingCallAllStudents };
    }

    onHandleCallAllStudentsButton = () => {
        alert(Strings.alerts.infoCallAllStudents);
        this.props.onHandleCallAllStudentsButton();

        this.setState({
            loadingCallAllStudents: true,
        })
    }

    render() {
        let classNameHidden = "showCard";
        let classNameCallStudent = "";
        let buttonCallStudent = "";
        let spinnerColor = "#ffffff";
        let callAllStudentsButton = "";

        if(this.state.loadingCallAllStudents == true && this.props.isLoadingCallAllStudents != false){
            callAllStudentsButton = <Button className="btnPlus"
                            color="default"
                            onClick={this.onHandleCallAllStudentsButton}><Spinner className="loaderButtonCard" color={spinnerColor} /></Button>;
        }else{
            callAllStudentsButton = <Button className="btnPlus"
                            color="default"
                            onClick={this.onHandleCallAllStudentsButton}>{Strings.titles.callAllStudents}</Button>;

            if(this.state.loadingCallAllStudents == true){
                this.setState({
                    loadingCallAllStudents: false,
                })
            }
        }

        return (
            <div>
                { callAllStudentsButton }
            </div>
        );
    }
}

export default AuthItem;