import React from 'react';
class RedirectMainPage extends React.Component {

    componentDidMount() {

        var url = window.location.pathname;
        url = url.replace("/", "");
        let urlArray = url.split("/");
        let schoolName = urlArray[0];

        let finalURL = "https://www.schoolguardian.app";
        if(schoolName !== "" && schoolName !== "undefined"){
            finalURL = window.location.origin+"/"+schoolName;
        }

        window.location = finalURL;        
    }
    render() {

        return (
            <p></p>
         );
     }
}

export default RedirectMainPage;
