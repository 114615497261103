import React from 'react';

import { } from './ShowGuardianCallStudentsView.css'
import {
    Row,
    Col,
    Button
} from 'reactstrap';

import CallAllStudentsButton from '../../components/CallAllStudentsButton/CallAllStudentsButton'
import CallStudentItem from '../../components/CallStudentItem/CallStudentItem'
import CallStudentGuardianItem from '../../components/CallStudentGuardianItem/CallStudentGuardianItem'
import { faPlus, faArrowLeft, faBullhorn } from '@fortawesome/pro-solid-svg-icons'
import Strings from '../../strings/Strings'

const ShowGuardianCallStudentsView = (props) => {

    let students = [];
    let insertedStudents = [];
    let titleBar = "";
    let subtitleBar = "";
    let isLoading;
    let guardian = null;
    let hiddenButton = true;
    let callAllStudentsButton = "";
    let spinnerColor = "#45b649";

    if(props.guardian){
        guardian = <CallStudentGuardianItem key={props.guardian.id} guardian={props.guardian} 
                    isLoading={isLoading} faPlus={faPlus} faArrowLeft={faArrowLeft}
                    onHandleClickMoreInfoButton={(e) => { props.onHandleClickMoreInfoButton(e, props.guardian) }}
                    hiddenButton={hiddenButton}
                    />;
    }
    if(props.guardian.studentList){
        console.log(props.guardian.studentList);

        for (const [key, student] of Object.entries(props.guardian.studentList)) {
            console.log(student);
            isLoading = true;
            if(!insertedStudents.includes(student.id)){
                insertedStudents.push(student.id);
                
                students.push(<CallStudentItem key={student.id} student={student} 
                    isCallAllStudents={props.isCallAllStudents} classNameCallStudent={props.classNameCallStudent} lastCalledStudentID={props.lastCalledStudentID} faBullhorn={faBullhorn} faPlus={faPlus} faArrowLeft={faArrowLeft}
                    onHandleCallStudentButton={(e) => { props.onHandleCallStudentButton(e, student) }}
                    covidFormLevelToWarning={props.covidFormLevelToWarning}
                    allowCallRestriction={props.allowCallRestriction}
                    allowCovidForm={props.allowCovidForm}
                    allowCovidFormWarning={props.allowCovidFormWarning}
                    allowCallRestrictionIfNotReportInDay={props.allowCallRestrictionIfNotReportInDay}
                    allowStudentsRotation={props.allowStudentsRotation}
                    />);
            }else{
                console.log('Student already inserted: '+student.id);
            }
        }

        isLoading = false;

        titleBar = <div className="cr-sidebar-title-bar">
                        { Strings.titles.guardian }
                    </div>;

        subtitleBar = <div className="cr-sidebar-title-bar">
                        { Strings.titles.students }
                        </div>;


        callAllStudentsButton = <CallAllStudentsButton 
                                isLoadingCallAllStudents={props.isLoadingCallAllStudents} faBullhorn={faBullhorn} faPlus={faPlus} faArrowLeft={faArrowLeft}
                                onHandleCallAllStudentsButton={() => { props.onHandleCallAllStudentsButton() }}
                                />
    }
    
    return (
        <div>
            <Row className="" >
                <Col className="no-padding">
                 { titleBar }
                </Col> 
                <Col md={12}>
                 { guardian }
                </Col> 
                <Col md={12} className="no-padding">
                 { subtitleBar }
                </Col>
                <Col md={12} className="no-padding">
                 { callAllStudentsButton }
                </Col> 
                <Col md={12}>
                  { students }   
                </Col>
            </Row>
        </div>
    )
}


export default ShowGuardianCallStudentsView;