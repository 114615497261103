
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';

/**
 * This object manage the redux flow of information
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */
export const checkpointSuccess = (datainfo) => {
    console.log("Checkpoint sucess: ", datainfo)
    return {
        type: actionTypes.CHECKPOINT_SUCCESS,
        datainfo: datainfo,
    };
};
export const checkpointFail = (datainfo) => {
    console.log("Checkpoint fail: ", datainfo)
    return {
        type: actionTypes.CHECKPOINT_FAIL,
        datainfo: datainfo,
    };
};

export const studentCheckpoint = (schoolName, token, student) => {

    console.log("schoolName:", schoolName);
    console.log("token: ", token);
    console.log("studentID: ", student.id);
    console.log("queueID: ", student.queueID);

    return dispatch => {

        var form = new FormData();
        form.append("studentID", student.id);
        form.append("queueID", student.queueID);


        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/addCheckpoint', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {
                if (data.infoReturn.returnID === "0") {
                    dispatch(checkpointSuccess(data));
                } else {
                    dispatch(checkpointFail(data));
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(checkpointFail(err));
            });

    };
};
