import React from 'react';

import { } from './StudentInfoDetailView.css'
import {
    Row,
    Col,
    Label
} from 'reactstrap';

import RespCard from '../../RespCard/RespCard'
import Strings from '../../../strings/Strings'
import StudentDetailInfoMessage from '../StudentInfoDetailMessage/StudentInfoDetailMessage'

const StudentInfoDetailView = (props) => {

    let guardians = [];
    if (props.student){
        guardians = props.student.guardians.map(guardian => {
            let makeCall = false;
            if (guardian.id === props.student.guardianCallID){
                makeCall = true;
            }
            return <RespCard key={guardian.id} isMakeCall={makeCall} guardian={guardian} isEmergency={props.student.emergency} />;
        });
    }
    
    return (
        <div>
            <StudentDetailInfoMessage
                student={props.student}
                showSendMessageBox={props.showSendMessageBox}
                onSendNewMessage={props.onSendNewMessage}
                onSendMessageChangeValue={props.onSendMessageChangeValue}
                onShowSendMessageBox={props.onShowSendMessageBox}
                sendMessageText={props.sendMessageText}
                sendErrorMessage={props.sendErrorMessage}
                isSending={props.isSending}
                codeReturn={props.codeReturn}

             />
            <Row className="rowInfo text-center permanentAuthBar" >
                <Col md={12} >
                    <Label className="titleResp">{Strings.detailStudent.permanentAuth}</Label>
                </Col>
            </Row>
            <Row className="rowInfo text-center" >
                {guardians}   
            </Row>  
        
        </div>
    )
}


export default StudentInfoDetailView;