import React from 'react';

import { } from './StudentInfoDetailMessage.css'
import {
    Row,
    Col,
    Label,
    Button
} from 'reactstrap';

import imageTemplate from '../../../assets/silhouetteStudent.png'
import { isLink } from '../../../utils/isLink'
import SendMessage from '../../SendMessage/SendMessage'
import Strings from '../../../strings/Strings'

const StudentDetailInfoMessage = (props) => {

    let image = imageTemplate
    let name = '';
    let grade = '';
    if (props.student) {
        if (isLink(props.student.image)) {
            image = props.student.image;
        }
        name = props.student.name;
        grade = props.student.grade;
    }

    let messageBox = null;
    let buttonMessage = null;
    let buttonMessageMini = null;
    if (props.showSendMessageBox) {
        messageBox = <SendMessage onSendNewMessage={props.onSendNewMessage}
            onSendMessageChangeValue={props.onSendMessageChangeValue}
            sendMessageText={props.sendMessageText}
            sendErrorMessage={props.sendErrorMessage}
            isSending={props.isSending}
            codeReturn={props.codeReturn} />
        buttonMessageMini = <Button className="btnSendMessage mTop5" onClick={props.onSendNewMessage}
        >{Strings.sendMessage.send}</Button>
    } else {
        buttonMessage = <Button className="btnSendMessage" onClick={props.onShowSendMessageBox}
        >{Strings.detailStudent.message}</Button>
    }

    return (
        <div>
            <Row className="rowInfo">

                <Col xs={4} sm={4} md={4} className="cardInfo text-center">
                    <img
                        src={image}
                        width="80%"
                        height="auto"
                        className="pr-2 imgStudentInfo"
                        alt=""
                    />
                    <div>
                        {buttonMessageMini}
                    </div>
                </Col>
                <Col xs={8} sm={8} md={8} className="cardInfo">
                    <Label className="lblStudentInfo">{name}</Label>
                    <Label className="lblGradeInfo">{grade} </Label>
                    {buttonMessage}
                    {messageBox}
                </Col>
            </Row>
        </div>
    )
}


export default StudentDetailInfoMessage;