import Strings from '../strings/Strings'

class ManageLanguage {


    setInitialLanguage() {
        const language = localStorage.getItem('language');
        if (language) {
            Strings.setLanguage(language);
        } else {
            if (window.location.host.includes("filhosemfila")) {
                Strings.setLanguage('pt_br');
            } else {
                Strings.setLanguage('en');
            }
        }
    }

    changeLanguage(language) {
        if (parseInt(language, 10) === 0) {
            language = "pt_br";
        } else if (parseInt(language, 10) === 1) {
            language = "en";
        } else if (parseInt(language, 10) === 2) {
            language = "es";
        }       
        Strings.setLanguage(language);
        localStorage.setItem('language', language);
    }
}

export default ManageLanguage;