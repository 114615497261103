import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import ShowTempAuthView from './ShowTempAuthView.js'
import TempAuthDetailItem from '../../components/TempAuth/TempAuthDetailItem/TempAuthDetailItem'
import ConfirmAction from '../../components/ConfirmAction/ConfirmAction'
import Aux from '../../hoc/ReactAux/ReactAux'
import Strings from '../../strings/Strings'

class ShowAuthController extends React.Component {

    state = {
        showDetail: false,
        authTempDetail: null,
        showModal: false,
        removeAuth: null
    }

    handleSidebarControlButton = event => {
        event.preventDefault();
        event.stopPropagation();

        document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    };

    onShowDetailTempAuth = (authTemp) => {
        this.setState({
            showDetail: true,
            authTempDetail: authTemp
        })
    };

    onCloseDetailTemp = () => {
        this.setState({
            showDetail: false,
            authTempDetail: null
        })
    }

    onRemoveTempAuth = (authTemp) => {
        this.setState({
            showModal: true,
            removeAuth:authTemp
        })        
    }

    onConfirmAction = () => {
        this.props.removeAuthQueue(this.props.schoolName,
            this.props.token, this.state.removeAuth);

        this.setState({
            showModal: false,
            removeAuth: null
        });
    }

    onCloseAction = () => {
        this.setState({
            showModal: false,
            removeAuth: null
        })
    }

    render() {
        let renderComp = (<ShowTempAuthView authTemps={this.props.authTemps}
                onHandleSidebarControlButton={this.handleSidebarControlButton}
                onShowDetailTempAuth={this.onShowDetailTempAuth}
                onRemoveTempAuth={this.onRemoveTempAuth}
                cardAuthRemoving={this.props.cardAuthRemoving}
                emptyTempAuths={this.props.emptyTempAuths}
                onEmptyAuthTemps={this.props.emptyAuthTemps}
                lastUpdate={this.props.lastUpdate}
             /> )
        if(this.state.showDetail){
            renderComp = <TempAuthDetailItem 
                            authTemp={this.state.authTempDetail}
                            onCloseDetailTemp={this.onCloseDetailTemp} />;
        }
        return (
            <Aux>
                <ConfirmAction showModal={this.state.showModal}
                    title={Strings.showTempAuth.sureRemoveItem}
                    onConfirm={this.onConfirmAction}
                    onClose={this.onCloseAction}/>
            { renderComp }       
            </Aux>
        )        
    }

};

const mapStateToProps = state => {
    return {
        schoolName: state.auth.schoolName,
        token: state.auth.token,
        authTemps: state.queue.authTemps,
        cardAuthRemoving: state.queue.cardAuthRemoving,
        emptyTempAuths: state.queue.emptyAuthTemps,
        lastUpdate: state.queue.lastUpdate       
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeAuthQueue: (schoolName, token, tempAuth) => dispatch(actions.removeAuthQueue(schoolName, token, tempAuth)),
        emptyAuthTemps: () => dispatch(actions.emptyAuthTemps()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ShowAuthController);
