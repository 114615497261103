import React from 'react';

import bn from '../../utils/bemnames';
import { connect } from 'react-redux';
import DetailPage from '../../constants/DetailPage'
import Strings from '../../strings/Strings'
import * as actions from '../../store/actions/index';


import {
  Button,
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import DetailQueueController from '../../containers/DetailQueue/DetailQueueController'
import ShowTempAuthController from '../../containers/ShowTempAuth/ShowTempAuthController'
import ShowSearchStudentController from '../../containers/ShowSearchStudents/ShowSearchStudentsController'
import ShowCallStudentController from '../../containers/ShowCallStudents/ShowCallStudentsController'
import ShowGuardianCallStudentController from '../../containers/ShowGuardianCallStudents/ShowGuardianCallStudentsController'
import TempAuthSearch from '../../componentsUI/TempAuthSearch';
import SearchStudent from '../../componentsUI/SearchStudent';
import SearchCallStudent from '../../componentsUI/SearchCallStudent';
import CheckPointDetailController from '../../components/CheckPoint/CheckPointDetailInfo/CheckPointDetailController'
import VehicleInfoDetailController from '../../components/Vehicles/VehicleInfoDetail/VehicleInfoDetailController'

const bem = bn.create('sidebar');


class Sidebar extends React.Component {
  
  state = {
    isOpenComponents: true,
    isOpenContents: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');

    if (this.props.currentDetailInfo === DetailPage.GUARDIAN_CALL_STUDENT || this.props.currentDetailInfo === DetailPage.CALL_STUDENT){
      this.props.cleanCalledStudents();
      this.props.emptySearchStudent();
    }

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  render() {

    let renderComp = <DetailQueueController />
    let title = Strings.detailSidebar.title_studentDetail;
    let searchBar = "";

    if (this.props.currentDetailInfo === DetailPage.GUARDIAN_CALL_STUDENT){
      renderComp = <ShowGuardianCallStudentController />;
      title = Strings.detailSidebar.title_call_student;
      searchBar = null;
    }else if (this.props.currentDetailInfo === DetailPage.CALL_STUDENT){
      renderComp = <ShowCallStudentController />;
      title = Strings.detailSidebar.title_call_student;
      searchBar = <Row style={{ height: '100%' }} className="bgSidebarBlue tempAuthSearch" >
                    <Col md={12}>
                      <div className={"vcenter mRightSearch height100"} style={{"width":"100%"}}>
                        <SearchCallStudent style={{"width":"100%"}}/>
                      </div>
                    </Col>
                  </Row>;
    }else if (this.props.currentDetailInfo === DetailPage.SEARCH_STUDENT){
      renderComp = <ShowSearchStudentController />;
      title = Strings.detailSidebar.title_search_student;
      searchBar = <Row style={{ height: '100%' }} className="bgSidebarBlue tempAuthSearch" >
                    <Col md={12}>
                      <div className={"vcenter mRightSearch height100"} style={{"width":"100%"}}>
                        <SearchStudent style={{"width":"100%"}}/>
                      </div>
                    </Col>
                  </Row>;
    } else if (this.props.currentDetailInfo === DetailPage.DETAIL_CHECKPOINT) {
      renderComp = <CheckPointDetailController />;
      title = Strings.detailSidebar.title_auth;
      searchBar = null;;
    } else if (this.props.currentDetailInfo === DetailPage.DETAIL_VEHICLE) {
      renderComp = <VehicleInfoDetailController />;
      title = Strings.vehicles.titleTop;
      searchBar = null;
    }else if (this.props.currentDetailInfo !== DetailPage.DETAIL_STUDENT ){
      renderComp = <ShowTempAuthController />;
      title = Strings.detailSidebar.title_auth;
      searchBar = <Row style={{ height: '100%' }} className="bgSidebarBlue tempAuthSearch" >
                    <Col md={12}>
                      <div className={"vcenter mRightSearch height100"} style={{"width":"100%"}}>
                        <TempAuthSearch style={{"width":"100%"}}/>
                      </div>
                    </Col>
                  </Row>;
    }


    return (
      <aside className={bem.b()} >
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>
          {/* <Container fluid> */}
            <Card className='border-2 boxSidebar align-center' >
              <CardHeader>
                <Col md={12}>
                  <Row style={{ height: '100%' }} className="" >
                  <Col xs={9} sm={9} md={9} className="vcenter" >
                      <Label className="vcenter lblTitleHeader noMargin">{title}</Label>
                  </Col>
                  <Col xs={3} sm={3} md={3} className="align-middle text-right">
                      <Button outline  color="default" onClick={this.handleSidebarControlButton} className="align-middle btnClose">
                        <FontAwesomeIcon icon={faTimes} className="iconClose" />
                      </Button>
                    </Col>

                  </Row>
                </Col>
              </CardHeader>
              {searchBar}
              <CardBody className={"noPadding"}>
                {renderComp}
              </CardBody>
            </Card>
            
          {/* </Container> */}

        </div>
      </aside>
    );
  }
}

const mapStateToProps = state => {
  return {
    showDetailStudent: state.screenDetailChange.showDetailStudent,
    currentDetailInfo: state.screenDetailChange.currentDetailInfo,
  };
};

const mapDispatchToProps = dispatch => {
   return {
      cleanCalledStudents: () => dispatch(actions.cleanCalledStudents()),
      emptySearchStudent: () => dispatch(actions.emptySearchStudent()),
   };
};


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
