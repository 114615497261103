import React from 'react'
import { } from './SearchStudentItem.css'

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AuthItem extends React.Component {
//const AuthItem = (props) => {

    constructor(props) {
        super(props);

        let image = "/static/media/silhouetteStudent.d636d251.png";
        if(this.props.student.caminhoFoto !== ""){
            image = this.props.student.caminhoFoto;
        }
        let cardContainer = (
            <Row >
                <Col xs={4} md={4} sm={4} className="cardInfo">
                    <img
                        src={image}
                        width="auto"
                        height="auto"
                        className="cardInfoImg"
                        alt="" />
                </Col>
                <Col xs={8} md={8} sm={8} className={"cardInfo"}>
                    <div mode="multi" 
                        className="lblTopStudent studentInfoTempAuth" max={14}>{this.props.student.nome}</div>
                    <Col xs={12} md={12} sm={12} className="cardInfo gradeInfoTempAuth">                            
                        {this.props.student.grade.nome}
                    </Col>
                </Col>
            </Row>
        );

        this.state = { image: image, cardContainer: cardContainer, buttonPlus: this.props.faPlus, showDetails: false};
    }

    render() {
        let classNameHidden = "showCard";

        /*const onShowDetailTempAuth = () => {
            let changeCardContainer = (<div><Row><Col xs={12} md={12} sm={12} xs={12} className="cardTitle">
                    {this.props.authTemp.title}
                </Col></Row>
                <Row className="authTempContainer">
                    <Col md={12} sm={12} xs={12} className="authTempMessage">{this.props.authTemp.message}</Col>
                </Row></div>);

            if(this.state.showDetails == false){
                this.setState({showDetails: true});
                this.setState({buttonPlus: this.props.faArrowLeft});
            }else{
                this.setState({showDetails: false});
                changeCardContainer = (
                    <Row >
                        <Col xs={4} md={4} sm={4} xs={3} className="cardInfo">
                            <img
                                src={this.state.image}
                                width="auto"
                                height="auto"
                                className="cardInfoImg"
                                alt="" />
                        </Col>
                        <Col xs={8} md={8} sm={8} xs={7} className={"cardInfo"}>
                            <Col xs={12} md={12} sm={12} xs={12} className="cardTitle">
                                {this.props.authTemp.title}
                            </Col>
                            <div mode="multi" 
                                className="lblTopStudent studentInfoTempAuth" max={14}>{this.props.authTemp.name}</div>
                            <Col xs={12} md={12} sm={12} className="cardInfo gradeInfoTempAuth">                            
                                {this.props.authTemp.grade}
                            </Col>
                            <Col xs={12} md={12} sm={12} className="cardInfo fromTempAuth">                            
                                <span>{Strings.showTempAuth.from}: </span><font className="guardianInfoTempAuth">{this.props.authTemp.sender}</font>
                            </Col>
                        </Col>
                    </Row>
                );
                this.setState({buttonPlus: this.props.faPlus});
            }

            this.setState({cardContainer: changeCardContainer});
        }*/

        const onHandleClickMoreInfoButton = (event, student) => {
            event.preventDefault();
            event.stopPropagation();
    
            //Remove Student from Queue
            this.props.changeDetailStudent(student);
    
            document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
            
            //Force Dispatch Resize to recalculate Size in Queue Item
            setTimeout(()=> {
                window.dispatchEvent(new Event('resize'));
            }, 500);
        }
        
        return (
            <div>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={'cardBody ' + classNameHidden}>
                    <Card className={'border-2  align-center boxStudent'}>
                        <CardBody>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"noPadding"}>
                                    {this.state.cardContainer}
                                    <Row className="rowCardButton" xs={2}>
                                        <Col xl={12} md={12} sm={12} xs={12} className="cardInfoButton">
                                            <Button className="btnPlus" 
                                                color="default"
                                                onClick={onHandleClickMoreInfoButton}><FontAwesomeIcon icon={this.state.buttonPlus}/></Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        );
    }
}

export default AuthItem;