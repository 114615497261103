import React from 'react';
import {connect} from 'react-redux'

import AgreeTermsView  from './AgreeTermsView.js'

import * as actions from '../../../store/actions/index';

class AgreeTermsController extends React.Component {

    state = { showNotCheckedError: false };

    /**
     * On Valid Submit in Form
     */
    onValidSubmit = (event, values) => {
        if(document.getElementById("agreeTerms").checked == false){
            this.setState({
                showNotCheckedError: true
            })
        }else{
            this.props.onAgreeTerms(this.props.schoolName, this.props.token);
        }
    }

    render(){
        return <AgreeTermsView onValidSubmit={this.onValidSubmit}
                    error={this.props.error}
                    showAgreeTerms={this.props.showAgreeTerms}
                    showNotCheckedError={this.state.showNotCheckedError}
                    /> 
    }
}

const mapStateToProps = state => {
    return {
        schoolName: state.auth.schoolName,
        token: state.auth.token,
        error: state.auth.error,
        showAgreeTerms: state.auth.showAgreeTerms,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAgreeTerms: (schoolName, token) => dispatch(actions.agreeTerms(schoolName, token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreeTermsController);