import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';
import DetailPage from '../../constants/DetailPage'

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
    showDetailStudent: null,
    currentDetailInfo: DetailPage.NONE,
};


const changeDetailStudent = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['showDetailStudent'] = action.student;
    updateInfo['currentDetailInfo'] = DetailPage.DETAIL_STUDENT;
    return updateObject(state, updateInfo);
}

const changeDetailGuardian = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['showDetailGuardian'] = action.guardian;
    updateInfo['currentDetailInfo'] = DetailPage.GUARDIAN_CALL_STUDENT;
    return updateObject(state, updateInfo);
}

const changeDetailCheckPoint = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['showDetailStudent'] = action.student;
    updateInfo['currentDetailInfo'] = DetailPage.DETAIL_CHECKPOINT;
    return updateObject(state, updateInfo);
}

const changeDetailVehicle = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['showDetailStudent'] = action.student;
    updateInfo['currentDetailInfo'] = DetailPage.DETAIL_VEHICLE;
    return updateObject(state, updateInfo);
}


const changeDetailEmail = (state, action) => {

    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['currentDetailInfo'] = DetailPage.TEMP_AUTH;
    return updateObject(state, updateInfo);
}

const changeDetailSearchStudent = (state, action) => {
    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['currentDetailInfo'] = DetailPage.SEARCH_STUDENT;
    return updateObject(state, updateInfo);
}

const changeDetailCallStudent = (state, action) => {
    let updateInfo = {};
    //Filter the current array to remove student with queueID
    updateInfo['currentDetailInfo'] = DetailPage.CALL_STUDENT;
    return updateObject(state, updateInfo);
}

const changeVehicleDetailUser = (state, action) => {
    let updateInfo = {};
    //Filter the current array to remove student with queueID
    let auxDetailUser = { ...state.showDetailStudent};
    auxDetailUser.vehicle_id = action.vehicleParentID;
    updateInfo['showDetailStudent'] = auxDetailUser;
    return updateObject(state, updateInfo);
}

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_DETAIL_STUDENT: return changeDetailStudent(state, action);
        case actionTypes.CHANGE_GUARDIAN_CALL_STUDENT: return changeDetailGuardian(state, action);
        case actionTypes.CHANGE_SHOW_TEMP_AUTH: return changeDetailEmail(state, action);
        case actionTypes.CHANGE_SHOW_SEARCH_STUDENT: return changeDetailSearchStudent(state, action);
        case actionTypes.CHANGE_DETAIL_CHECKPOINT: return changeDetailCheckPoint(state, action);
        case actionTypes.CHANGE_DETAIL_VEHICLES: return changeDetailVehicle(state, action);
        case actionTypes.CHANGE_VEHICLE_DETAIL_USER: return changeVehicleDetailUser(state, action);
        case actionTypes.CHANGE_SHOW_CALL_STUDENT: return changeDetailCallStudent(state, action);
        default:
            return state;
    }
};

export default reducer;