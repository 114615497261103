
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';

/**
 * This object manage the redux flow of information
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */
export const showFoundStudents = (students) => {
    console.log(students);
    return {
        type: actionTypes.SHOW_FOUND_STUDENTS,
        students: students,
    };
};
export const showNotFoundStudents = (student) => {
    return {
        type: actionTypes.SHOW_NOT_FOUND_STUDENTS,
    };
};

export const searchStudent = (schoolName, token, string) => {

    console.log(schoolName);
    console.log(token);
    console.log(string);

    return dispatch => {

        var form = new FormData();
        form.append("query", string);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/searchStudent', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {
                if (data.infoReturn.returnID === "1") {
                    dispatch(showFoundStudents(data.info.students));
                } else {
                    dispatch(showNotFoundStudents());
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(showNotFoundStudents());
            });

    };
};
