import React from 'react';

// import { } from './ShowTempAuthView.css'
import {
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import Strings from '../../strings/Strings'
import ChangeLanguageItem from './ChangeLanguageItem'

const ChangeLanguageView = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}>
            <ModalHeader>{Strings.changePass.title}</ModalHeader>
            <ModalBody className="modalBodyChangeLanguage">
                <ChangeLanguageItem lang="pt-br" isFirst={true} 
                                isChecked={props.currentLanguage==="pt_br"} onChangeLanguage={props.onChangeLanguage}/>
                <ChangeLanguageItem lang="en" isFirst={false} 
                                isChecked={props.currentLanguage === "en"} onChangeLanguage={props.onChangeLanguage}/>
                <ChangeLanguageItem lang="es" isFirst={false} 
                                isChecked={props.currentLanguage === "es"} onChangeLanguage={props.onChangeLanguage}/>
            </ModalBody>
        </Modal>
    )
}

export default ChangeLanguageView;
