import React from 'react';

import { } from './VehicleInfoAddView.css'
import {
    Row,
    Col,
    Label,
    Input,
    InputGroup,
    InputGroupText,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner
} from 'reactstrap';

import Strings from '../../../strings/Strings'
import { Formik } from 'formik'
import InputMask from "react-input-mask";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import ConnectionConstants from '../../../constants/ConnectionConstants';


const VehicleInfoAddView = (props) => {

    let isElevaRemoveMask = false;
    if (
      window.location.href.includes("Rubens") ||
      window.location.href.includes("eleva")
    ) {
      isElevaRemoveMask = true;
    }
    let arrayVehicleColor = [];
    arrayVehicleColor.push((
        <DropdownItem key={0} header>
            {Strings.addVehicles.placeholderCarColor}
        </DropdownItem>
    ));

    let mainBorderInCar = "";
    if (props.choseCarColor.code === "#FFFFFF" || props.choseCarColor.code === "#FFF" || props.choseCarColor.code === "#ffffff" ||
        props.choseCarColor.code === "#fff" || props.choseCarColor.code.toLowerCase() === "white") {
        mainBorderInCar = "borderInCar";
    }

    let finalMask = "";
    if(props.plate_mask){
        finalMask = props.plate_mask.replaceAll("A","a");
        finalMask = finalMask.replaceAll("_","*");
    } else {
        finalMask = "***********";
    }

    let messageError = null;
    if (props.returnMessage !== ''){
        messageError = <Label className="errorMessage">{props.returnMessage}</Label>
    }

    arrayVehicleColor = props.vehicle_color.map(vehicleColor => {

        let color = Strings['addVehicles'][vehicleColor.name];
        let borderInCar = "";
        let car_color = vehicleColor.code;

        if (car_color=== "#FFFFFF" || car_color === "#FFF" || car_color === "#ffffff" || car_color === "#fff" ||
            car_color.toLowerCase() === "white") {
            borderInCar = "borderInCar";
        }
        return <DropdownItem key={vehicleColor.code} onClick={() => { props.chooseCarColor(vehicleColor) }}>
            <Row className="align-items-center">
                <Col md={3} xs={3}><FontAwesomeIcon icon={faCar} size="sm" color={vehicleColor.code}
                    className={"marginRightInternalIconUser widthIcon " + borderInCar} /> </Col>
                <Col md={9} xs={9}><Label >{color}</Label> </Col>
            </Row>
        </DropdownItem>
    })
    return (
        <>
            <Row className="rowInfo text-center permanentAuthBar" >
                <Col md={9} xs={9} >
                    <Label className="titleResp">{Strings.addVehicles.titleTop}</Label>
                </Col>
                <Col md={3} xs={3} className="topTitleRespIcon" onClick={() => { props.showAddCar() }}>
                    <Label className="titleRespIcon"> x </Label>
                </Col>
            </Row>             
            <Row className="rowInfo text-center" >
                <Col md={12} xs={12} >
                    <Formik
                        validationSchema={props.addVehicleValidationSchema}
                        initialValues={{ model: '', plate: '' }}
                        onSubmit={values => props.sendNewVehicle(values)}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                            errors,
                            isValid,
                        }) => {
                            let buttonInfo;

                            let infoPlate = (
                            <InputMask
                                className="form-control"
                                mask={finalMask}
                                name="plate"
                                onChange={handleChange("plate")}
                                onBlur={handleBlur("plate")}
                                placeholder={Strings.addVehicles.placeholderPlate}
                            />
                            );  
                            
                            if (isElevaRemoveMask){
                                infoPlate = (
                                <Input
                                    className="form-control"
                                    name="plate"
                                    placeholder={Strings.addVehicles.placeholderPlate}
                                    onChange={handleChange("plate")}
                                    onBlur={handleBlur("plate")}
                                />
                                );  
                            }
                              
                            if (props.connectionStatusAddNew === ConnectionConstants.LOADING) {
                                // buttonInfo = (
                                // );
                                buttonInfo = <Spinner > </Spinner>
                            } else {
                                buttonInfo = (
                                    <Button
                                        outline
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={!isValid}>
                                        {Strings.send}
                                    </Button>
                                )
                            }
                            return (
                              <>
                                <Col md={12} xs={12} className="lineForm">
                                  <InputGroup>
                                    <InputGroupText>
                                      <FontAwesomeIcon icon={faCar} />
                                    </InputGroupText>
                                    <Input
                                      name="model"
                                      placeholder={
                                        Strings.addVehicles.placeholderModel
                                      }
                                      onChange={handleChange("model")}
                                      onBlur={handleBlur("model")}
                                    />
                                  </InputGroup>
                                  {errors.model && (
                                    <Label className="errorMessage">
                                      {errors.model}
                                    </Label>
                                  )}
                                </Col>
                                <Col md={12} xs={12} className="lineForm">
                                  <InputGroup>
                                    <InputGroupText>
                                      <FontAwesomeIcon icon={faCar} />
                                    </InputGroupText>
                                    {infoPlate}
                                  </InputGroup>
                                  {errors.plate && (
                                    <Label className="errorMessage">
                                      {errors.plate}
                                    </Label>
                                  )}
                                </Col>
                                <Col md={12} xs={12} className="lineForm">
                                  <Row className="align-items-center">
                                    <Col
                                      md={8}
                                      xs={8}
                                      className="chooseColorMenu">
                                      <Dropdown
                                        toggle={function noRefCheck() {
                                          props.setOpenGetColorChoose(
                                            !props.openGetColorChoose
                                          );
                                        }}
                                        isOpen={props.openGetColorChoose}
                                        name="car_color">
                                        <DropdownToggle
                                          color="primary"
                                          outline
                                          caret>
                                          {
                                            Strings.addVehicles
                                              .placeholderCarColor
                                          }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {arrayVehicleColor}
                                        </DropdownMenu>
                                      </Dropdown>
                                    </Col>
                                    <Col md={4} xs={4}>
                                      <FontAwesomeIcon
                                        icon={faCar}
                                        size="2x"
                                        className={
                                          "marginRightInternalIconUser widthIcon " +
                                          mainBorderInCar
                                        }
                                        color={props.choseCarColor.code}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  md={12}
                                  xs={12}
                                  className="lineForm align-items-center checkboxDiv"
                                  onClick={() => {
                                    props.changeAllGuardians();
                                  }}>
                                  <Input
                                    type="checkbox"
                                    className="checkboxInput"
                                    name="allGuardians"
                                    checked={props.choseAllGuardians}
                                    readOnly={true}
                                  />
                                  <Label>
                                    {Strings.addVehicles.titleAllGuardians}
                                  </Label>
                                </Col>
                                <Col md={12} xs={12} className="lineForm">
                                  {messageError}
                                  {buttonInfo}
                                </Col>
                              </>
                            );
                        }}
                    </Formik>
                </Col>
            </Row>        
        </>
    )
}


export default VehicleInfoAddView;