import React from 'react';
import { connect } from 'react-redux';
import { geolocated } from 'react-geolocated';
import { Base64 } from 'js-base64';

import * as actions from '../../store/actions/index';

import LoginView from './LoginView'
import LoginModel from './LoginModel'
import Aux from '../../hoc/ReactAux/ReactAux'
import Strings from '../../strings/Strings'

class LoginPage extends React.Component {
  tempEmail = "";
  state = {
    showChangeLanguage: false,
    locationMessage: "",
    startCheckPosition: false,
    showLoginButton: false,
    emptyEmail: false,
  };

  componentDidMount = () => {
    this.props.setSchoolName(this.props.match.params.schoolName);

    console.log(this.props.match.params);
    if (
      this.props.match.params.token !== null &&
      typeof this.props.match.params.token !== "undefined"
    ) {
      if(this.props.match.params.signOnType !== null && 
        typeof this.props.match.params.signOnType !== "undefined" &&
        this.props.match.params.signOnType === "oAuth"){
          console.log("Aqui Dentro Ze");
            this.props.authToken(
              this.props.match.params.token,
              this.props.match.params.schoolName
            );
            this.setState({ startCheckPosition: false });          
      } else {
          const loginModel = new LoginModel();
          const info = loginModel.getTokenInfo(this.props.match.params.token);
          if (info[0] !== "") {
            this.props.onAuth(info[0], info[1], this.props.match.params.schoolName);
            this.setState({ startCheckPosition: false });
          }
      }      
    }

    if (this.checkCookie()) {
      this.setState({
        showLoginButton: true,
      });
    }
  };

  componentDidUpdate = () => {
    if (
      this.props.loading &&
      !this.props.isAuthenticated &&
      this.props.requireLocation &&
      this.props.checkLocationStatus === 0
    ) {
      if (
        !this.props.isGeolocationAvailable ||
        !this.props.isGeolocationEnabled
      ) {
        if (this.state.locationMessage !== Strings.getLocation.notAvaiable) {
          this.setState({ locationMessage: Strings.getLocation.notAvaiable });
        }
        // alert('setError Not Avaiable');
      } else if (this.props.positionError) {
        if (this.state.locationMessage !== Strings.getLocation.error) {
          this.setState({ locationMessage: Strings.getLocation.error });
        }
      } else {
        if (this.props.coords) {
          // alert('start Check Location');
          if (
            this.props.checkLocationStatus === 0 &&
            !this.state.startCheckPosition
          ) {
            this.props.checkNearSchool(
              this.props.coords.latitude,
              this.props.coords.longitude,
              this.props.match.params.schoolName
            );
            this.setState({ locationMessage: "", startCheckPosition: true });
          }
        } else {
          if (this.state.locationMessage !== Strings.getLocation.waiting) {
            this.setState({ locationMessage: Strings.getLocation.waiting });
          }
          // alert('Send Error message');
        }
      }
    }
  };
  /**
   * On Valid Submit in Form
   */
  onValidSubmit = (event, values) => {
    //Send Auth to Redux
    if (this.props.logged_user_id > 0) {
      this.props.authMFA(
        this.tempEmail,
        values.code,
        this.props.logged_user_id,
        this.props.sent_via_sms,
        this.props.match.params.schoolName
      );
    } else {
      this.tempEmail = values.email;
      this.props.onAuth(
        values.email,
        values.password,
        this.props.match.params.schoolName
      );
      console.log(
        "Console Token:",
        Base64.encode(values.email + "/" + values.password)
      );
      this.setState({ startCheckPosition: false });
    }
  };

  openChangeLanguage = () => {
    // this.props.logout();
    this.setState({
      showChangeLanguage: true,
    });
  };
  onChooseLanguage = () => {
    this.setState({
      showChangeLanguage: false,
    });
  };

  onChangeShowLoginButton = (show) => {
    this.setState({
      showLoginButton: show,
    });
  };

  checkCookie = () => {
    // Get cookie using our custom function
    var cookiesAccept = this.getCookie("cookiesAccept");

    if (cookiesAccept !== "" && cookiesAccept !== null) {
      return true;
    } else {
      return false;
    }
  };

  getCookie = (name) => {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
      if (name === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  };

  /**
   * On Valid Submit in Form
   */
  onForgetPassword = () => {
    let email = document.getElementById("email").value;

    if (email !== "") {
      this.setState({
        emptyEmail: false,
      });
      //Send Auth to Redux
      this.props.onForgetPassword(this.props.match.params.schoolName, email);
    } else {
      this.setState({
        emptyEmail: true,
      });
    }
  };

  resendMFACode = () => {
    this.props.resendMFACode(
      this.tempEmail,
      this.props.logged_user_id,
      this.props.match.params.schoolName
    );
  }

  onCancelMFAButton = () => {
    this.props.setShowMFAScreen(false, 0, false);
  };

  render() {
    //Hide Login View when is checking authenticating to avoid AVForm Error
    let showLogin = null;
    if (this.props.checkAuthStatus) {
      showLogin = (
        <LoginView
          onValidSubmit={this.onValidSubmit}
          loading={this.props.loading}
          error={this.props.error}
          showChangeLanguage={this.state.showChangeLanguage}
          openChangeLanguage={this.openChangeLanguage}
          onChooseLanguage={this.onChooseLanguage}
          locationMessage={this.state.locationMessage}
          showLoginButton={this.state.showLoginButton}
          onChangeShowLoginButton={this.onChangeShowLoginButton}
          forgetPassword={this.onForgetPassword}
          emptyEmail={this.state.emptyEmail}
          forgetPasswordSuccess={this.props.forgetPasswordSuccess}
          showAgreeTerms={this.props.showAgreeTerms}
          showMFAScreen={this.props.showMFAScreen}
          onCancelMFAButton={this.onCancelMFAButton}
          resendMFACode={this.resendMFACode}
          sent_via_sms={this.props.sent_via_sms}
        />
      );
    }

    return <Aux>{showLogin}</Aux>;
  }
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    checkAuthStatus: state.auth.checkAuthStatus,
    requireLocation: state.auth.requireLocation,
    checkLocationStatus: state.auth.checkLocationStatus,
    forgetPasswordSuccess: state.auth.forgetPasswordSuccess,
    showAgreeTerms: state.auth.showAgreeTerms,
    showMFAScreen: state.auth.showMFAScreen,
    logged_user_id: state.auth.logged_user_id,
    sent_via_sms: state.auth.sent_via_sms,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password, schoolName) =>
      dispatch(actions.auth(email, password, schoolName)),
    authToken: (token, schoolName) =>
      dispatch(actions.authToken(token, schoolName)),
    authMFA: (user, code, logged_user_id, sent_via_sms, schoolName) =>
      dispatch(
        actions.authMFA(user, code, logged_user_id, sent_via_sms, schoolName)
      ),
    resendMFACode: (user, logged_user_id, schoolName) =>
      dispatch(actions.resendMFACode(user, logged_user_id, schoolName)),
    onForgetPassword: (schoolName, email) =>
      dispatch(actions.forgetPassword(schoolName, email)),
    setSchoolName: (schoolName) => dispatch(actions.setSchoolName(schoolName)),
    checkNearSchool: (latitude, longitude, schoolName) =>
      dispatch(actions.checkNearSchool(latitude, longitude, schoolName)),
    setShowMFAScreen: (showMFA) => dispatch(actions.setShowMFAScreen(showMFA)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps) (geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: null,
})(LoginPage));
