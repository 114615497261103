import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
    guardians: null,
};

const showFoundGuardiansCallStudents = (state, action) => {
    console.log(action);
    let updateInfo = {};
    updateInfo['guardians'] = action.guardians;
    updateInfo['covidFormLevelToWarning'] = action.covidFormLevelToWarning;
    updateInfo['allowCallRestriction'] = action.allowCallRestriction;
    updateInfo['allowCovidForm'] = action.allowCovidForm;
    updateInfo['allowCovidFormWarning'] = action.allowCovidFormWarning;
    updateInfo['allowCallRestrictionIfNotReportInDay'] = action.allowCallRestrictionIfNotReportInDay;
    updateInfo['allowStudentsRotation'] = action.allowStudentsRotation;
    updateInfo['notFoundGuardians'] = false;
    return updateObject(state, updateInfo);
}

const showNotFoundGuardiansCallStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['showLoading'] = action.showLoading;
    updateInfo['notFoundGuardians'] = true;
    return updateObject(state, updateInfo);
}

const emptyGuardiansCallStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['showLoading'] = action.showLoading;
    return updateObject(state, updateInfo);
}

const emptySearchStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['showLoading'] = false;
    updateInfo['cleanSearch'] = true;
    return updateObject(state, updateInfo);
}

const setCleanSearch = (state, action) => {

    let updateInfo = {};
    updateInfo['cleanSearch'] = false;
    return updateObject(state, updateInfo);
}

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SHOW_FOUND_GUARDIANS_CALL_STUDENTS: return showFoundGuardiansCallStudents(state, action);
        case actionTypes.SHOW_NOT_FOUND_GUARDIANS_CALL_STUDENTS: return showNotFoundGuardiansCallStudents(state, action);
        case actionTypes.EMPTY_GUARDIANS_CALL_STUDENTS: return emptyGuardiansCallStudents(state, action);
        case actionTypes.EMPTY_SEARCH_STUDENTS: return emptySearchStudents(state, action);
        case actionTypes.CLEAN_SEARCH_STUDENT_INPUT: return setCleanSearch(state, action);
        default:
            return state;
    }
};

export default reducer;