import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ConnectionConstants from '../../constants/ConnectionConstants'
import BaseURL from '../../utils/BaseURL'
import Strings from '../../strings/Strings'
import bn from '../../utils/bemnames';

import {
  Navbar,
  //NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  Col,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faSignal, faCircle, faSearch,  faVirus, faUnlockAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { faExpandAlt, faGlobe, faSignOutAlt } from '@fortawesome/pro-solid-svg-icons'

//import logoFSFImage from '../../assets/logo/filhosemfila_logo.png';
//import logoQPAImage from '../../assets/logo/qpa_logo.png';
import logoSchoolGuardian from '../../assets/logo/school_guardian_azul.png';
import DetailPage from '../../constants/DetailPage';
import ChangeLanguageController from '../../components/ChangeLanguage/ChangeLanguageController';
import TextSearch from '../../componentsUI/TextSearch';
import ForceChangePasswordController from '../../components/Modals/ForceChangePassword/ForceChangePasswordController';

const bem = bn.create('header');

class Header extends React.Component {


  constructor(props) {
    super(props);

    const isInFullScreen = this.checkFullScreen();
    let stringFullScreen = Strings.menu.openFullScreen;
    if(isInFullScreen){
      stringFullScreen = Strings.menu.closeFullScreen
    }
    
    this.state = {
      isOpenNotificationPopover: false,
      isOpenUserCardPopover: false,
      isActiveBg: "noBg",
      showChangeLanguage: false,
      textFullScreen: stringFullScreen,
      isCovidFiltered: 0,
      showModalChangePassword: 0,
    };
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });
  };

  toggleUserCardPopover = () => {
    if(this.state.isOpenUserCardPopover === false){
      this.setState({
        isActiveBg: "",
      });
    }else{
      this.setState({
        isActiveBg: "noBg",
      });
    }

    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.currentDetailInfo === DetailPage.TEMP_AUTH) {
      this.props.cleanAuthNoRead();
      document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    } else {
      this.props.changeShowTempAuth();
      this.props.cleanAuthNoRead();
      document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
    }

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  handleSearchControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.currentDetailInfo === DetailPage.TEMP_AUTH || this.props.currentDetailInfo === DetailPage.SEARCH_STUDENT) {
      document.querySelector('.cr-searchbar').classList.toggle('cr-searchbar--open');
      localStorage.setItem('isSearchBarOpen', 0);
    } else {
      if(typeof document.querySelector('.cr-searchbar--open') !== "undefined"){
        document.querySelector('.cr-searchbar').classList.toggle('cr-searchbar--open');
      }else{
        document.querySelector('.cr-searchbar').classList.add('cr-searchbar--open');
      }
      localStorage.setItem('isSearchBarOpen', 1);
    }

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  handleSidebarStudentsControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    if(this.props.currentDetailInfo === DetailPage.SEARCH_STUDENT){
      document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    }else{
      this.props.changeShowSearchStudent();
      document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
    }

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  handleSidebarCallStudentControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    if(this.props.currentDetailInfo === DetailPage.GUARDIAN_CALL_STUDENT || this.props.currentDetailInfo === DetailPage.CALL_STUDENT){
      this.props.cleanCalledStudents();
      this.props.emptySearchStudent();
      this.props.changeShowCallStudent();
      document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    }else{
      this.props.changeShowCallStudent();
      document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
    }

    //Force Dispatch Resize to recalculate Size in Queue Item
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  handleCovidControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    let noCovidContainers = document.querySelectorAll(".noCovid");

    if(noCovidContainers.length > 0){
      //cardsTitle.forEach((cardTitle) => {
      [...noCovidContainers].forEach((noCovidItem) => {
        if (parseInt(this.state.isCovidFiltered,10) === 0){
          noCovidItem.classList.add("hiddenCovid");
          noCovidItem.classList.remove("showCovid");
        }else{
          noCovidItem.classList.add("showCovid");
          noCovidItem.classList.remove("hiddenCovid");
        }
      });
    }

    if(parseInt(this.state.isCovidFiltered,10) === 0){
      this.setState({isCovidFiltered: 1});
      localStorage.setItem('isCovidFiltered', 1);
      console.log("localStorage: "+localStorage.getItem("isCovidFiltered"));
      document.getElementById('btnCovidFilter').classList.add("activeCovidFilter");
    }else{
      this.setState({isCovidFiltered: 0});
      localStorage.setItem('isCovidFiltered', 0);
      console.log("localStorage: "+localStorage.getItem("isCovidFiltered"));
      document.getElementById('btnCovidFilter').classList.remove("activeCovidFilter");
    }
  };

  openChangeLanguage = () => {
    // this.props.logout();
    this.setState({
      showChangeLanguage: true,
    })
    this.toggleUserCardPopover();
  }
  onChooseLanguage = () => {
    this.setState({
      showChangeLanguage: false,
    })

  }

  handleSignOut = () => {
    this.props.cleanStudentsAuthLogout();
    this.props.logout();
  }

  checkFullScreen = () => {
    return  (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

  } 
  handleOpenFullScreen = () => {

    this.toggleUserCardPopover();
    const isInFullScreen = this.checkFullScreen();

    let stringFullScreen = Strings.menu.openFullScreen;

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      stringFullScreen = Strings.menu.closeFullScreen
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
      
    } else {

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({ textFullScreen: stringFullScreen});
  }

  openChangePassword = () => {
    this.setState({
      showModalChangePassword: 1,
    });
    this.toggleUserCardPopover();
  }

  onCloseChangePassword = () => {
    this.setState({
      showModalChangePassword: 0,
    });
  }

  render() {
    let appVersion = new BaseURL().getAppVersion();
    let classSearchBarOpen = "cr-searchbar";
    if(localStorage.getItem('isSearchBarOpen') === "1"){
      classSearchBarOpen = "cr-searchbar cr-searchbar--open";
    }

    let image = logoSchoolGuardian;
    /*if (window.location.host.includes("filhosemfila")) {
      image = logoFSFImage;
    }*/

    let statusConn = "statusIconLoading";
    if (this.props.statusQueueConnection === ConnectionConstants.SUCCESS) {
      statusConn = "statusIconSuccess";
    } else if (this.props.statusQueueConnection === ConnectionConstants.FAIL) {
      statusConn = "statusIconError";
    }

    let menuButton = null
    //if (!new BaseURL().isTVInspector()) {
      menuButton = <Button className="btnNoBorder mRight30" outline onClick={this.handleSidebarControlButton}>
        <FontAwesomeIcon icon={faEnvelope} />
      </Button>
    //}

    let menuSearch = null
    //if (!new BaseURL().isTVInspector()) {
      menuSearch = <Button outline className="btn-no-outline-secondary btnNoBorder mRight30" onClick={this.handleSearchControlButton}>
        <FontAwesomeIcon icon={faSearch} />
      </Button>
    //}

     let menuStudents = null
    // if (!new BaseURL().isTVInspector()) {
    //   menuStudents = <Button outline className="btn-no-outline-secondary btnNoBorder mRight30" onClick={this.handleSidebarStudentsControlButton}>
    //     <FontAwesomeIcon icon={faUsers} />
    //   </Button>
    // }

    let menuCovid = null
    if (!new BaseURL().isTVInspector() && parseInt(this.props.allowInformCovid,10) === 1) {
      menuCovid = <Button id="btnCovidFilter" outline className="btn-no-outline-secondary btnNoBorder mRight30" onClick={this.handleCovidControlButton}>
        <FontAwesomeIcon icon={faVirus} />
      </Button>
    }

    let menuCallStudent = null
      menuCallStudent = <Button id="btnCallStudent" outline className="btn-no-outline-secondary btnNoBorder mRight2" onClick={this.handleSidebarCallStudentControlButton}>
        <FontAwesomeIcon icon={faUserFriends} />
      </Button>
    

    let firstUserLetter = "";
    if (this.props.userName) {
      firstUserLetter = this.props.userName[0].toUpperCase();
    }

    let badge = null;
    if (this.props.amountAuthsNoRead > 0){

      badge = (
        <Badge
          color="danger"
          className="rounded-circle position-absolute"
          style={{ top: -5, right: 60 }}
          key="badgeTempAuth"
        >
          <small>{this.props.amountAuthsNoRead}</small>
        </Badge>
      );
    }

    let iconSignalQueueRemoveStudent = null;
    if(this.props.changeSignalRemoveStudent > 0){
      iconSignalQueueRemoveStudent = (
        <FontAwesomeIcon
          icon={faCircle}
          style={{ color: "blue", fontSize: 5 }}
        />
      );
    }

    return (
      <div>
        <Navbar light expand className={bem.b("bg-white")}>
          <ChangeLanguageController
            isOpen={this.state.showChangeLanguage}
            onChooseLanguage={this.onChooseLanguage}
          />
          <Col xl={2} lg={2} md={2} sm={2} xs={2} className="noPaddingLeft">
            <Nav navbar className="mr-2">
              <NavItem>
                <NavLink>
                  <Button
                    className="IconOpenMenu can-click vcenter btnRoundNoBorder"
                    outline
                    id="Popover2"
                    onClick={this.toggleUserCardPopover}
                    style={{ color: "white", backgroundColor: "#1c375e" }}>
                    {firstUserLetter}
                  </Button>
                  <div
                    className={"navBg " + this.state.isActiveBg}
                    style={{
                      marginLeft: "0.3rem",
                      width: 34,
                      height: 56,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}></div>
                  {/* <Avatar
                  id="Popover2"
                  onClick={this.toggleUserCardPopover}
                  className="can-click"
                /> */}
                </NavLink>
                <Popover
                  placement="bottom-end"
                  isOpen={this.state.isOpenUserCardPopover}
                  toggle={this.toggleUserCardPopover}
                  target="Popover2"
                  className="p-0 borderPopover"
                  style={{ minWidth: 200 }}>
                  <PopoverBody className="p-0 border-light">
                    <ListGroup flush>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light can-click"
                        onClick={this.handleOpenFullScreen}>
                        <FontAwesomeIcon icon={faExpandAlt} />{" "}
                        {this.state.textFullScreen}
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light can-click"
                        onClick={this.openChangePassword}>
                        <FontAwesomeIcon icon={faUnlockAlt} />{" "}
                        {Strings.changePass.changePassword}
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light can-click"
                        onClick={this.openChangeLanguage}>
                        <FontAwesomeIcon icon={faGlobe} />{" "}
                        {Strings.changePass.title}
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light can-click"
                        onClick={this.handleSignOut}>
                        <FontAwesomeIcon icon={faSignOutAlt} />{" "}
                        {Strings.menu.signOut}
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light">
                        <div style={{ textAlign: "center" }}>
                          V {appVersion} - {this.props.userName}
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  </PopoverBody>
                </Popover>
              </NavItem>
              <NavItem>
                <div className={"vcenter statusIcon mLeft20 " + statusConn}>
                  <FontAwesomeIcon icon={faSignal} /> &nbsp;
                  {iconSignalQueueRemoveStudent}
                </div>
              </NavItem>
            </Nav>
          </Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}>
            <Nav navbar className="mr-2">
              <img
                src={image}
                width="auto"
                height="44px"
                className="pr-2 mAuto"
                alt=""
              />
            </Nav>
          </Col>

          <Col xl={2} lg={2} md={2} sm={2} xs={2} className="noPaddingRight">
            <Nav navbar className={bem.e("nav-right")}>
              {menuCovid}

              {menuSearch}

              {menuButton}

              {menuCallStudent}
            </Nav>
            {badge}
          </Col>
        </Navbar>
        <Navbar className={"mr-12 bgSidebarBlue " + classSearchBarOpen}>
          <Nav navbar style={{ width: "100%" }}>
            <NavItem style={{ width: "100%" }}>
              <div
                className={"vcenter mRightSearch height100"}
                style={{ width: "100%" }}>
                <TextSearch style={{ width: "100%" }} />
              </div>
            </NavItem>
          </Nav>
        </Navbar>
        <ForceChangePasswordController
          openModalChangePassword={this.state.showModalChangePassword}
          onCloseChangePassword={this.onCloseChangePassword}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    statusQueueConnection: state.queue.statusQueueConnection,
    currentDetailInfo: state.screenDetailChange.currentDetailInfo,
    userName: state.auth.userName,
    amountAuthsNoRead: state.queue.amountAuthsNoRead,
    allowInformCovid: state.auth.allowInformCovid,
    allowInformCovid: state.auth.allowInformCovid,
    changeSignalRemoveStudent: state.queue.changeSignalRemoveStudent,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    cleanStudentsAuthLogout: () => dispatch(actions.cleanStudentsAuthLogout()),
    logout: () => dispatch(actions.logout()),
    changeShowTempAuth: () => dispatch(actions.changeShowTempAuth()),
    changeShowSearchStudent: () => dispatch(actions.changeShowSearchStudent()),
    cleanAuthNoRead: () => dispatch(actions.cleanAuthNoRead()),
    changeShowCallStudent: () => dispatch(actions.changeShowCallStudent()),
    cleanCalledStudents: () => dispatch(actions.cleanCalledStudents()),
    emptySearchStudent: () => dispatch(actions.emptySearchStudent()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
