import React from 'react';

import { } from './CheckpointCard.css'
import {
    Row,
    Col,
    Label
} from 'reactstrap';

import Strings from '../../../strings/Strings'
import Moment from 'moment';

const CheckpointCard = (props) => {
    let id  = props.checkpoint.checkpoint_area_color;
    let area_color = props.checkpoint.checkpoint_area_color;
    let area_name = props.checkpoint.checkpoint_area_name;
    let date = props.checkpoint.checkpoint_date;
    let resp = props.checkpoint.inspector_user_name;

    if (Strings.language.lang === "en"){
        date = Moment(date).format('hh:mm a');
    }else{
        date = Moment(date).format('HH:mm');
    }
    
    return (
        <Col xs={12} sm={12} md={12} className="boxCheckpoint">
            <Row className="rowCheckpoint " xs={12} sm={12} md={12}>
                <Col className="cardCheckpointInfo">
                    <Label className="lblInfocheckpoint lblArea">{Strings.checkpoint.area}</Label>
                    <div mode="multi" key={id} max={14} style={{"backgroundColor":area_color}} className='colorBox'></div>
                </Col>
                <Col className="cardCheckpointInfo">
                    
                    <Label className="lblInfocheckpoint lblAreaName">{area_name}</Label>
                </Col>
                <Row xs={12} sm={12} md={12} className="cardCheckpointRow">
                    <Col xs={12} sm={12} md={12} className="cardCheckpointInfo">
                        <Row xs={12} sm={12} md={12} className="cardCheckpointRow">
                                <Label className="lblInfocheckpoint lblHour">{Strings.checkpoint.hour}</Label>
                                <Label className="lblInfocheckpoint lblData">{date}</Label>
                        </Row>
                        <Row xs={12} sm={12} md={12} className="cardCheckpointRow">
                            <Label className="lblInfocheckpoint lblResponsable">{Strings.checkpoint.responsable}</Label>
                            <Label className="lblInfocheckpoint lblRespCheckpoint">{resp}</Label>
                        </Row>
                    </Col>
                </Row>
            </Row>
        </Col>
    )
}


export default CheckpointCard;