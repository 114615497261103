import React from 'react';
import { connect } from 'react-redux';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

class FailSendNotification extends React.Component {

    componentDidUpdate= () => {

        const message = this.props.failMessageInfo.split("{|}");
        setTimeout(() => {
            this.notificationSystem.addNotification({
                message: message[0],
                level: 'warning',
                autoDismiss: 5,
                position:'bc'
            });
        }, 500);
        
    }
    render () {

        return (
            <NotificationSystem
                dismissible={false}
                ref={notificationSystem =>
                    (this.notificationSystem = notificationSystem)
                }
                style={NOTIFICATION_SYSTEM_STYLE}
            />
        )
    }

}


const mapStateToProps = state => {
    return {
        failMessageInfo: state.queue.failMessageInfo,
    };
};

export default connect(mapStateToProps)(FailSendNotification);