import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
    guardians: null,
    isLoadingCallAllStudents: null,
    classNameCallStudent: "",
};

const showFoundGuardiansCallStudents = (state, action) => {
    console.log(action);
    let updateInfo = {};
    updateInfo['guardians'] = action.guardians;
    return updateObject(state, updateInfo);
}

const showNotFoundGuardiansCallStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    return updateObject(state, updateInfo);
}

const emptyGuardiansCallStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['showLoading'] = action.showLoading;
    return updateObject(state, updateInfo);
}

const updateSuccessCalledStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['isLoadingCallAllStudents'] = false;
    updateInfo['classNameCallStudent'] = "onCallStudent";
    updateInfo['isCallAllStudents'] = true;
    return updateObject(state, updateInfo);
}

const updateErrorCalledStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['isLoadingCallAllStudents'] = false;
    updateInfo['classNameCallStudent'] = "";
    return updateObject(state, updateInfo);
}

const updateSuccessCalledStudent = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['classNameCallStudent'] = "onCallStudent";
    updateInfo['lastCalledStudentID'] = action.student.id;
    return updateObject(state, updateInfo);
}

const updateErrorCalledStudent = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['classNameCallStudent'] = "";
    updateInfo['lastCalledStudentID'] = -1;
    return updateObject(state, updateInfo);
}

const cleanCalledStudents = (state, action) => {

    let updateInfo = {};
    updateInfo['guardians'] = null;
    updateInfo['isLoadingCallAllStudents'] = "2";
    updateInfo['classNameCallStudent'] = "";
    updateInfo['isCallAllStudents'] = false;
    updateInfo['lastCalledStudentID'] = -1;
    return updateObject(state, updateInfo);
}

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SHOW_FOUND_GUARDIANS_CALL_STUDENTS: return showFoundGuardiansCallStudents(state, action);
        case actionTypes.SHOW_NOT_FOUND_GUARDIANS_CALL_STUDENTS: return showNotFoundGuardiansCallStudents(state, action);
        case actionTypes.EMPTY_GUARDIANS_CALL_STUDENTS: return emptyGuardiansCallStudents(state, action);
        case actionTypes.UPDATE_SUCCESS_CALLED_STUDENTS: return updateSuccessCalledStudents(state, action);
        case actionTypes.UPDATE_ERROR_CALLED_STUDENTS: return updateErrorCalledStudents(state, action);
        case actionTypes.UPDATE_SUCCESS_CALLED_STUDENT: return updateSuccessCalledStudent(state, action);
        case actionTypes.UPDATE_ERROR_CALLED_STUDENT: return updateErrorCalledStudent(state, action);
        case actionTypes.CLEAN_CALLED_STUDENTS: return cleanCalledStudents(state, action);
        default:
            return state;
    }
};

export default reducer;