
import * as actionTypes from './actionTypes';
import BaseURL from '../../utils/BaseURL';

/**
 * This object manage the redux flow of information
 * 
 * The methods above is called in the mapDispatchToProps in Controller or Models
 */
export const sendMessageStart = (student, message) => {
    return {
        type: actionTypes.SEND_MESSAGE_START,
        student: student,
        message: message
    };
};
export const sendMessageSuccess = (student) => {
    return {
        type: actionTypes.SEND_MESSAGE_SUCCESS,
        student: student,
    };
};
export const sendMessageFail = (student) => {
    return {
        type: actionTypes.SEND_MESSAGE_FAIL,
        student: student,
    };
};

export const sendMessage = (schoolName, token, student, message) => {
    return dispatch => {

        dispatch(sendMessageStart(student));
        
        var form = new FormData();
        form.append("studentID", student.id);
        form.append("message", message);

        fetch(new BaseURL().getMainURL() + schoolName + '/v1/inspector/sendMessage', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        })
            .then(results => { /* console.log(results);*/ return results.json(); })
            .then(data => {

                if (data.infoReturn.returnID === "0") {
                    dispatch(sendMessageSuccess(student));
                } else {
                    dispatch(sendMessageFail(student));
                }
            })
            .catch(err => {
                console.log("Error", err);
                dispatch(sendMessageFail(student));
            });

    };
};

export const cleanPreviousSend = () => {
    return {
        type: actionTypes.SEND_MESSAGE_CLEAN
    }
}
