import React from 'react';

import { } from './ShowCallStudentsView.css'
import {
    Row,
    Col
} from 'reactstrap';

import CallStudentGuardianItem from '../../components/CallStudentGuardianItem/CallStudentGuardianItem'
import { faPlus, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import Strings from '../../strings/Strings'
import Spinner from '../../componentsUI/Layout/Spinner/Spinner'

const ShowCallStudentsView = (props) => {

    let insertedGuardians = [];
    let titleBar = "";
    let hiddenButton = false;
    let spinnerColor = "#45b649";
    let guardians = null;
    let spinner = null;
    let isLoading;

    if(props.showLoading){
        spinner = <Spinner className="loaderButtonCard" color={spinnerColor} />;
    }else{
        if(props.notFoundGuardians == true){
            spinner = Strings.search.notFoundGuardians
        }else{
            spinner = null;
        }
    }

    if(props.guardians){
        //if(!props.isCallAllStudents && props.classNameCallStudent != "onCallStudent"){
            guardians = props.guardians.map((guardian) => {
                console.log(guardian);
                isLoading = true;
                
                if(!insertedGuardians.includes(guardian.id)){
                    insertedGuardians.push(guardian.id);

                    return <CallStudentGuardianItem key={guardian.id} guardian={guardian} 
                        isLoading={isLoading} faPlus={faPlus} faArrowLeft={faArrowLeft}
                        onHandleClickMoreInfoButton={(e) => { props.onHandleClickMoreInfoButton(e, guardian) }}
                        hiddenButton={hiddenButton}
                        />
                }else{
                    console.log('Guardian already inserted: '+guardian.id);
                }
            });

            spinner = null;

            titleBar = <div className="cr-sidebar-title-bar">
                        { Strings.titles.guardians }
                    </div>;
        /*}else{
            spinner = null;
        }*/
    }
    
    return (
        <div>
            <Row className="" >
                <Col className="no-padding">
                 { titleBar }
                </Col> 
                <Col md={12}>
                  { spinner }
                  { guardians }   
                </Col>
            </Row>
        </div>
    )
}


export default ShowCallStudentsView;