import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { cleanPreviousSend, sendMessage } from '../../../store/actions/sendMessage';
import CheckPointDetailView from './CheckPointDetailView.js'
import Strings from '../../../strings/Strings'

const CheckPointDetailController = () => {

    const dispatch = useDispatch();
    const schoolName = useSelector(state => state.auth.schoolName);
    const token = useSelector(state => state.auth.token);
    const showDetailStudent = useSelector(state => state.screenDetailChange.showDetailStudent);
    const currentDetailInfo = useSelector(state => state.screenDetailChange.currentDetailInfo);
    const isSending = useSelector(state => state.sendMessage.isSending);
    const messageReturn = useSelector(state => state.sendMessage.messageReturn);
    const codeReturn = useSelector(state => state.sendMessage.codeReturn);

    const [showSendMessageBox, setShowSendMessageBox] = useState(false);
    const [sendMessageText, setSendMessageText] = useState("");
    const [sendErrorMessage, setSendErrorMessage] = useState("");

    /**
     * Handle Open/Close Sidebar with Student Info
     */
    const handleSidebarControlButton = event => {
        event.preventDefault();
        event.stopPropagation();

        document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    };

    /**
     * On Valid Submit in Form
     */
    const onShowSendMessageBox = () => {
        //Send Auth to Redux
        setShowSendMessageBox(true);
    }

    const onSendMessageChangeValue = (event) =>{        
        setSendMessageText(event.target.value);
    }

    const onSendNewMessage = () => {
        if (sendMessageText === ""){
            dispatch(cleanPreviousSend());
            setTimeout(() => {
                setSendErrorMessage(Strings.sendMessage.messageEmptyError);
            }, 500);
                       
        } else {
            dispatch(sendMessage(schoolName, token, showDetailStudent, sendMessageText));
            setSendErrorMessage("");
            setSendMessageText("");
        }        
    }

    
    return (
        <CheckPointDetailView student={showDetailStudent}
            onHandleSidebarControlButton={handleSidebarControlButton}
            currentDetailInfo={currentDetailInfo}
            showSendMessageBox={showSendMessageBox}

            onShowSendMessageBox={onShowSendMessageBox}
            onSendNewMessage={onSendNewMessage}
            onSendMessageChangeValue={onSendMessageChangeValue}
            sendMessageText={sendMessageText}
            sendErrorMessage={sendErrorMessage}
            isSending={isSending}
            messageReturn={messageReturn}
            codeReturn={codeReturn}/>
    )

};


export default CheckPointDetailController;
