import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import VehicleInfoDetailView from './VehicleInfoDetailView.js'
import VehicleInfoAddView from './VehicleInfoAddView.js'
import { getVehicle, updateVehicle, addNewVehicle } from '../../../store/actions/vehicle.js';
import ConnectionConstants from '../../../constants/ConnectionConstants.js';
import Strings from '../../../strings/Strings'
import * as yup from 'yup'
import _ from 'lodash';

const VehicleInfoDetailController = (props) => {

    const dispatch = useDispatch();
    const schoolName = useSelector((state) => state.auth.schoolName);
    const token = useSelector((state) => state.auth.token);
    const vehicle_color = useSelector((state) => state.auth.vehicle_color);
    let plate_mask = useSelector((state) => state.auth.plate_mask);
    const showDetailStudent = useSelector((state) => state.screenDetailChange.showDetailStudent);
    const vehicles = useSelector((state) => state.vehicle.vehicles);
    const codeError = useSelector((state) => state.vehicle.codeError);
    const connectionStatus = useSelector((state) => state.vehicle.connectionStatus);
    const codeErrorUpdate = useSelector((state) => state.vehicle.codeErrorUpdate);
    const connectionStatusUpdate = useSelector((state) => state.vehicle.connectionStatusUpdate);
    const codeErrorAddNew = useSelector((state) => state.vehicle.codeErrorAddNew);
    const connectionStatusAddNew = useSelector((state) => state.vehicle.connectionStatusAddNew);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isShowAddCar, setIsShowAddCar] = useState(false);
    const [openGetColorChoose, setOpenGetColorChoose] = useState(false);
    const [choseAllGuardians, setChoseAllGuardians] = useState(false);
    const [choseCarColor, setChoseCarColor] = useState({ id: 12, code: "#fff"});
    const [returnMessage, setReturnMessage] = useState('');

    useEffect(() => {     
        dispatch(getVehicle(schoolName, token, showDetailStudent.queueID));
    }, [showDetailStudent, schoolName, token, dispatch]);

    useEffect(() => {
        if(connectionStatusUpdate === ConnectionConstants.SUCCESS || 
            connectionStatusUpdate === ConnectionConstants.ERROR){
            setIsAlertOpen(true);
            setTimeout(() => {
                setIsAlertOpen(false);
            }, 4000);
        }
    }, [connectionStatusUpdate]);

    useEffect(() => {
        if (connectionStatusAddNew === ConnectionConstants.SUCCESS) {
            setChoseCarColor({ id: 12, code: "#fff" });
            setIsShowAddCar(false);
            dispatch(getVehicle(schoolName, token, showDetailStudent.queueID));
        } else if (connectionStatusAddNew === ConnectionConstants.FAIL) {

            let message = "";
            if (parseInt(codeErrorAddNew,10) === -2){
                message = Strings.addVehicles.plateAlreadyExists;
            } else {
                message = Strings.formatString(Strings.messageErrors.unknownError,
                    codeErrorAddNew)
            }
            setReturnMessage(message);
        }
    }, [connectionStatusAddNew]);

    const updateVehicleClick = (vehicleParentID) => {
        console.log("Chaning Car")
        dispatch(updateVehicle(schoolName, token, showDetailStudent.queueID, vehicleParentID));
    }

    const showAddCar = () => {
        setIsShowAddCar(!isShowAddCar);
        setReturnMessage("");
    }

    let minCarName = 4;
    if (
      window.location.href.includes("Rubens") ||
      window.location.href.includes("eleva")
    ) {
      plate_mask = "*";
      minCarName = 1;
    }

    const addVehicleValidationSchema = yup.object().shape({
      model: yup
        .string()
        .min(minCarName, Strings.addVehicles.errorModelMinSize)
        .required(Strings.addVehicles.errorModelEmpty),
      plate: yup
        .string()
        .min(
          !_.isNil(plate_mask) ? plate_mask.length : 4,
          Strings.addVehicles.errorPlateMinSize
        )
        .required(Strings.addVehicles.errorPlateEmpty)
        .matches(/^[^_]+$/, {
          message: Strings.addVehicles.errorPlateEmpty,
          excludeEmptyString: true,
        }),
    });

    const chooseCarColor = (vehicleColor) => {
        setChoseCarColor(vehicleColor);
    }

    const changeAllGuardians = () => {
        setChoseAllGuardians(!choseAllGuardians);
    }

    const sendNewVehicle = (vehicleInfo) => {
        console.log(vehicleInfo);
        dispatch(addNewVehicle(schoolName, token, showDetailStudent.queueID, vehicleInfo.model, vehicleInfo.plate,
            choseCarColor.id, choseAllGuardians?1:0));
    }

    if (isShowAddCar){
        return (
            <VehicleInfoAddView
                showDetailStudent={showDetailStudent}
                showAddCar={showAddCar}
                openGetColorChoose={openGetColorChoose}
                setOpenGetColorChoose={setOpenGetColorChoose}
                vehicle_color={vehicle_color}
                choseCarColor={choseCarColor}
                plate_mask={plate_mask}
                chooseCarColor={chooseCarColor}
                addVehicleValidationSchema={addVehicleValidationSchema}
                sendNewVehicle={sendNewVehicle}
                changeAllGuardians={changeAllGuardians}
                choseAllGuardians={choseAllGuardians}
                connectionStatusAddNew={connectionStatusAddNew}
                returnMessage={returnMessage}
            />
        )
    } else {
        return (
            <VehicleInfoDetailView
                showDetailStudent={showDetailStudent}
                vehicles={vehicles}
                codeError={codeError}
                connectionStatus={connectionStatus}
                codeErrorUpdate={codeErrorUpdate}
                connectionStatusUpdate={connectionStatusUpdate}
                isAlertOpen={isAlertOpen}
                updateVehicle={updateVehicleClick}
                showAddCar={showAddCar}
            />
        )
    }    

};


export default VehicleInfoDetailController;
