
import {fetch as fetchPolyfill} from 'whatwg-fetch'

class ConnectionJs {


    doConnection(url, options, result, data, catchFnc) {

        if (window.fetch) {
            console.log("Passando Rubens pelo Connection Fetch");
            fetch(url, options)
            .then(result)
            .then(data)
            .catch(catchFnc); 
        } else {
            // do something with XMLHttpRequest?
            console.log("Passando Rubens pelo Connection fetchPolyfill");
            fetchPolyfill(url, options)
                .then(result)
                .then(data)
                .catch(catchFnc); 
        }
    
    }

}

export default ConnectionJs;