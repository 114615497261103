import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './store/reducers/auth';
import queueReducer from './store/reducers/queue';
import screenDetailChange from './store/reducers/screenDetailChange';
import sendMessage from './store/reducers/sendMessage'
import searchStudent from './store/reducers/searchStudent'
import searchCallStudent from './store/reducers/searchCallStudent'
import callStudent from './store/reducers/callStudent'
import vehicle from './store/reducers/vehicle'
import checkpointReducer from './store/reducers/checkpoint';

import App from './App';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

const rootReducer = combineReducers({
    auth: authReducer,
    queue: queueReducer,
    screenDetailChange: screenDetailChange,
    sendMessage: sendMessage,       
    searchStudent: searchStudent,
    searchCallStudent: searchCallStudent,
    callStudent: callStudent,
    vehicle: vehicle,
    checkpoint: checkpointReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
