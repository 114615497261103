import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/updateObject';

/**
 * This Object manage the Redux state of auth
 * The reducer always is called by Actions
 * 
 * Above the initial state
 */
const initialState = {
  schoolName: "",
  token: null,
  inspectorID: null,
  error: "",
  loading: false,
  checkAuthStatus: false,
  waitingAreaID: 0,
  showOnlyNames: 0,
  autoRool: 0,
  socketPort: 0,
  userName: "",
  requireLocation: true,
  isAuthenticated: false,
  checkLocationStatus: 0,
  isSocketAllowed: 0,
  card_color_walking: 0,
  card_border_color_walking: 0,
  card_color_driving: 0,
  card_border_color_driving: 0,
  show_only_students_with_covid: 0,
  allowInformCovid: 0,
  forceChangePassword: 0,
  showModalForceChangePassword: 0,
  forgetPasswordSuccess: 0,
  showAgreeTerms: 0,
  typeInspector: 0,
  allow_checkpoint: 0,
  vehicle_color: [],
  plate_mask: null,
  allow_add_car_inspector: false,
  allow_remove_students_instantly: false,
  restrict_only_strong_password: 0,
  showMFAScreen: false,
  logged_user_id: 0,
  sent_via_sms: false,
};

/**
 * Update State when auth Start
 * @param {*} state 
 * @param {*} action 
 */
const authStart = (state, action) => {
    return updateObject(state, { error: "", loading: true, checkLocationStatus: -1 });
};

/**
 * Update State when auth has success
 * @param {*} state 
 * @param {*} action 
 */
const authSuccess = (state, action) => {
    return updateObject(state, {
      schoolName: action.schoolName,
      token: action.idToken,
      userId: action.userId,
      waitingAreaID: action.waitingAreaID,
      showOnlyNames: action.showOnlyNames,
      autoRool: action.autoRool,
      socketPort: action.socketPort,
      error: "",
      loading: action.loading,
      userName: action.userName,
      requireLocation: action.requireLocation,
      isAuthenticated: action.isAuthenticated,
      checkLocationStatus: 0,
      showCallerName: action.showCallerName,
      isSocketAllowed: action.isSocketAllowed,
      isSearchBarOpen: action.isSearchBarOpen,
      card_color_walking: action.card_color_walking,
      card_border_color_walking: action.card_border_color_walking,
      card_color_driving: action.card_color_driving,
      card_border_color_driving: action.card_border_color_driving,
      show_only_students_with_covid: action.show_only_students_with_covid,
      allowInformCovid: action.allowInformCovid,
      forceChangePassword: action.forceChangePassword,
      showModalForceChangePassword: action.showModalForceChangePassword,
      showAgreeTerms: action.showAgreeTerms,
      typeInspector: action.typeInspector,
      allow_checkpoint: action.allow_checkpoint,
      vehicle_color: action.vehicle_color,
      plate_mask: action.plate_mask,
      allow_add_car_inspector: action.allow_add_car_inspector,
      allow_remove_students_instantly: parseInt(
        action.allow_remove_students_instantly,
        10
      ),
      restrict_only_strong_password: parseInt(
        action.restrict_only_strong_password,
        10
      ),
      showMFAScreen: false,
      logged_user_id: 0,
      sent_via_sms: false,
    });
};


/**
 * Update State when auth has fail
 * @param {*} state 
 * @param {*} action 
 */
const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const checkAuth = (state, action) => {
    return updateObject( state, {
        checkAuthStatus: true,
    })
}

const authLogout = (state, action) => {
    return updateObject(state, { 
        token: null, 
        userId: null,
        userName: null,
        waitingAreaID: 0,
        showOnlyNames: 0,
        autoRool: 0,
        socketPort: 0,
        isAuthenticated: false,
        checkLocationStatus: 0,
        checkAuthStatus: true,
        typeInspector: 0,
        allow_checkpoint: 0,
        vehicle_color: [],
        plate_mask: null,
        allow_add_car_inspector: false
    });
};

const setSchoolName = (state, action) => {
    return updateObject(state, {
        schoolName: action.schoolName,
    });
};

const checkNearStart = (state, action) => {
    return updateObject(state, {
        isAuthenticated: true,
        checkLocationStatus: 1,
        loading: false,
    });
};
const checkNearSuccess = (state, action) => {
    return updateObject(state, {
        isAuthenticated: true,
        checkLocationStatus: 2,
        loading: false,
    });
};
const checkNearFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        checkLocationStatus: 0,
        loading: false
    });
};

/**
 * Update State when forget password has fail
 * @param {*} state 
 * @param {*} action 
 */
const forgetPasswordError = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

/**
 * Update State when forget password has success
 * @param {*} state 
 * @param {*} action 
 */
const forgetPasswordSuccess = (state, action) => {
    return updateObject(state, {
        forgetPasswordSuccess: 1,
        loading: false
    });
};

/**
 * Update State when change password has fail
 * @param {*} state 
 * @param {*} action 
 */
const agreeTermsError = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

/**
 * Update State when change password has success
 * @param {*} state 
 * @param {*} action 
 */
const agreeTermsSuccess = (state, action) => {
    return updateObject(state, {
        showAgreeTerms: 0,
        showModalForceChangePassword: action.showModalForceChangePassword,
        loading: false
    });
};

/**
 * Update State when force change password
 * @param {*} state 
 * @param {*} action 
 */
const forceChangePasswordDone = (state, action) => {
    localStorage.setItem("showModalForceChangePassword", 0);
    return updateObject(state, {
        showModalForceChangePassword: 0,
    });
};

const showMFAScreen = (state, action) => {
    return updateObject(state, {
      showMFAScreen: action.showMFAScreen,
      logged_user_id: action.logged_user_id,
      sent_via_sms: action.sent_via_sms,
      loading: false,
    }); 
}

/**
 * This method is called by Auth Actions
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHECK_AUTH: return checkAuth(state, action);
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_SCHOOL_NAME: return setSchoolName(state, action);
        case actionTypes.CHECK_NEAR_SCHOOL_START: return checkNearStart(state, action);
        case actionTypes.CHECK_NEAR_SCHOOL_SUCCESS: return checkNearSuccess(state, action);
        case actionTypes.CHECK_NEAR_SCHOOL_FAIL: return checkNearFail(state, action);
        case actionTypes.FORGET_PASSWORD_SUCCESS: return forgetPasswordSuccess(state, action);
        case actionTypes.FORGET_PASSWORD_ERROR: return forgetPasswordError(state, action);
        case actionTypes.AGREE_TERMS_SUCCESS: return agreeTermsSuccess(state, action);
        case actionTypes.AGREE_TERMS_ERROR: return agreeTermsError(state, action);
        case actionTypes.FORCE_CHANGE_PASSWORD_DONE: return forceChangePasswordDone(state, action);
        case actionTypes.SHOW_MFA_SCREEN: return showMFAScreen(state, action);
        default:
            return state;
    }
};

export default reducer;